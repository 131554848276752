import qs from 'qs';
import { buildQueryParams } from './api';
let editor = null;
const constants = {
  USER_TOKEN: 'userToken',
  USER_INFO: 'userInfo'
};
const journeyTile = {
  //Triggers
  trigger_transaction: 'Transaction',
  trigger_schedule: 'Added to Schedule',
  trigger_time_schedule: 'Schedule Time Trigger',
  trigger_time: 'Time Trigger',
  trigger_recurring: 'Recurring Time Trigger',
  trigger_segment: 'Static Segment Trigger',
  trigger_web_form: 'Web Form',
  trigger_fast_sensor_zone: 'FastSensor Zone',
  trigger_fast_sensor_dwell_time: 'FastSensor Dwell Time',
  trigger_abandoned_cart: 'Abandoned Cart',
  trigger_shopify_fulfillment: 'Shopify Fulfillment',
  trigger_sms_incoming: 'Incoming SMS',
  trigger_sms_optin: 'Opt-in SMS',
  //Actions
  action_time_delay: 'Time Delay',
  action_await_sms_reply: 'Await SMS Reply',
  action_rate_limit: 'Rate Limit',
  action_mail: 'Send Mailer',
  action_email: 'Send Email',
  action_mms: 'Send MMS',
  action_notification: 'Send Notification',
  action_webhook: 'Webhook',
  action_ab: 'A/B Testing',
  action_add_segment: 'Add to Static Segment',
  action_remove_segment: 'Remove from Static Segment',
  action_add_facebook: 'Add to Facebook Audience',
  action_remove_facebook: 'Remove from Facebook Audience',
  action_add_adwords: 'Add to Google Ads Audience',
  action_remove_adwords: 'Remove from Google Ads Audience',
  action_add_mailbox_power: 'Add to Mailbox Power',
  action_remove_mailbox_power: 'Remove from Mailbox Power',
  action_give_offer: 'Give Offer',
  action_give_points: 'Action Give Points',
  action_send_survey_sms: 'Send Survey SMS',
  action_send_survey_email: 'Send Survey Email',
  action_eject: 'End Journey',
  action_ban: 'Ban from Journey',
  action_send_to_aircall: 'Send to AirCall',
  action_send_to_calltools: 'Send to CallTools',
  action_contest: 'Contest Winners',
  action_contest_recurring: 'Recurring Contest Winners',
  action_zapier: 'To Zapier',
  action_swell_sms: 'Swell SMS',
  action_swell_email: 'Swell Email',
  action_add_audiohook: 'Add to Audiohook',
  action_remove_audiohook: 'Remove from Audiohook',
  action_add_dripdrop: 'action_add_dripdrop',
  action_reply_review: 'Reply to Review',
  //Conditions
  condition_email: 'Check Email Status',
  condition_transaction: 'Has Transaction',
  condition_repeat: 'Repeat Customer',
  condition_distance: 'Distance from Location',
  condition_check_field: 'Check Data Field',
  condition_advanced_sql: 'Advanced SQL Condition',
  condition_wizard: 'Wizard Node',
  condition_value_segment: 'Value Segment',
  condition_fast_sensor_check: 'FastSensor Zone Check',
  condition_survey_satisfaction: 'Survey Satisfaction',
  condition_in_segment: 'In Static Segment',
  condition_sms_subscription: 'SMS Subscription Status',
  condition_has_offer: 'Has Offer'
};
const utils = {
  // setEditor: e => {
  //   editor = e;
  // },
  // getEditor: () => {
  //   return editor;
  // },
  setUserToken: (rememberMe, token) => {
    if (rememberMe) {
      localStorage.setItem(constants.USER_TOKEN, token);
      utils.setUserInfo(rememberMe);
    } else {
      sessionStorage.setItem(constants.USER_TOKEN, token);
      utils.setUserInfo(rememberMe);
    }
  },
  getUserInfo: () => {
    if (localStorage.getItem(constants.USER_INFO)) {
      return JSON.parse(localStorage.getItem(constants.USER_INFO));
    } else if (sessionStorage.getItem(constants.USER_INFO)) {
      return JSON.parse(sessionStorage.getItem(constants.USER_INFO));
    } else {
      return [];
    }
  },
  setCompany: company => {
    localStorage.setItem(constants.COMPANY_SELECTED, JSON.stringify(company));
  },
  getCompany: () => {
    return localStorage.getItem(constants.COMPANY_SELECTED) ? JSON.parse(localStorage.getItem(constants.COMPANY_SELECTED)) : null;
  },
  setUserInfo: (rememberMe = false) => {
    fetch('https://engine-dev.cinch.io/users/me', {
      method: 'get',
      headers: new Headers({
        Authorization: 'Token ' + utils.getUserToken()
      })
    }).then(response => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error('Something went wrong ...');
      }
    }).then(data => {
      data = JSON.stringify(data);
      if (rememberMe) {
        localStorage.setItem(constans.USER_INFO, data);
      } else {
        sessionStorage.setItem(constants.USER_INFO, data);
      }
    });
  },
  getUserToken: () => {
    if (localStorage.getItem(constants.USER_TOKEN)) {
      return localStorage.getItem(constants.USER_TOKEN);
    }
    return sessionStorage.getItem(constants.USER_TOKEN);
  },
  handleUserInput: (e, context) => {
    const name = e.target.name;
    const value = e.target.value;
    context.setState({
      [name]: value
    }); // Set input value
  },
  handleUserInputCheckbox(e, context) {
    const name = e.target.name;
    const value = e.target.checked;
    context.setState({
      [name]: value
    }); // Set input value
  },
  setRedirect: function (pathname, state) {
    this.setState({
      redirect: {
        active: true,
        to: {
          pathname,
          state
        }
      }
    });
  },
  downloadBlob: (blob, filename) => {
    // TODO @Tony is this the best way to download a file?
    const url = window.URL.createObjectURL(new Blob([blob]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
  },
  convertStringToFilename: (string, extension) => {
    return string.replace(/[^a-z0-9]/gi, '_').toLowerCase() + '.' + extension;
  },
  commaize: x => {
    let parts = x.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return parts.join('.');
  },
  updateHistory(state, filters) {
    let params = {};
    if (state.paginationParams.offset) {
      params.offset = state.paginationParams.offset;
    }
    filters.forEach(key => {
      if (state[key] && state[key] !== '') {
        params[key] = state[key];
      }
    });
    if (Object.keys(params).length) {
      let newurl = window.location.protocol + '//' + window.location.host + window.location.pathname + '?' + qs.stringify(params);
      window.history.pushState({
        path: newurl
      }, '', newurl);
    }
  },
  loadStateFromParams(thisState, filters, showFunction) {
    // Load state from query parameters
    const params = qs.parse(this.props.location.search.slice(1));
    const state = {};
    filters.forEach(key => {
      if (params[key]) {
        state[key] = params[key];
      }
    });
    if (params.offset) {
      thisState.paginationParams = {
        ...thisState.paginationParams,
        offset: params.offset
      };
    }
    if (Object.keys(state).length) {
      this.setState(state, () => {
        showFunction();
      });
    } else {
      showFunction();
    }
  },
  formatPhoneNumber(phoneNumberString) {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      var intlCode = match[1] ? '+1 ' : '';
      return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    }
    return phoneNumberString;
  },
  formatIntlPhone(phoneNumberString) {
    const numbersOnly = (phoneNumberString || '').replace(/\D/g, '');
    if (numbersOnly.length === 10) {
      return `+1${numbersOnly}`;
    }
    return `+${numbersOnly}`;
  },
  formatTwilioStatus(number) {
    // Convert hypens to spaces and uppercase the first letter of each word
    return number.replace(/-/g, ' ').replace(/\b\w/g, function (match) {
      return match.toUpperCase();
    });
  },
  journeyTileLookup(subType) {
    return journeyTile[subType];
  }
};
export default utils;
export function buildFormData(body) {
  const formData = new FormData();
  Object.keys(body).map(key => {
    formData.append(key, body[key]);
  });
  return formData;
}

/**
 * Allows us to pass in a dynamic scope to query params
 * (in this use case we're waiting for permission granted to one,
 * so it must be built using an "old scope")
 **/
export const buildFacebookQueryParams = (redirectUri, scope) => {
  const clientId = '1995172537226503';
  const responseType = 'token';
  const builtRedirectUri = buildQueryParams({
    client_id: clientId,
    redirect_uri: redirectUri,
    response_type: responseType,
    scope: scope
  });
  return builtRedirectUri;
};