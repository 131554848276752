import React, { useContext, useState, useEffect } from 'react';
import { UserStateContext } from 'context/user-state-context';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Paper, Stack, TextField, Tooltip, Typography, Divider, FormControl, Select, MenuItem, InputLabel, Skeleton, Popper, ClickAwayListener } from '@mui/material';
import { AddIcon, CloseIcon, EditIcon, HelpIcon } from 'components/mui';
import { withStyles } from '@mui/styles';
import { isValidPhoneNumber } from 'libphonenumber-js';
import isEmail from 'validator/lib/isEmail';
const CustomTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      borderRadius: `14px`,
      '& fieldset': {}
    }
  }
})(TextField);
export const CompanyDetailsInfoCard = props => {
  const {
    hasPermission
  } = useContext(UserStateContext);
  const [companyInfoModal, setCompanyInfoModal] = useState(null);
  const [companyInfoModalFocus, setCompanyInfoModalFocus] = useState(null);
  const [helpAnchorEl, setHelpAnchorEl] = React.useState(null);
  const [helpData, setHelpData] = React.useState(null);
  const [defaultEmailOrNameHasChanged, setDefaultEmailOrNameHasChanged] = useState(false);
  const [aFieldHasChanged, setAFieldHasChanged] = useState(false);
  const [filteredTimezones, setFilteredTimezones] = useState([{
    value: 'America/New_York',
    label: 'Eastern Standard Time (EST)'
  }, {
    value: 'America/Chicago',
    label: 'Central Standard Time (CST)'
  }, {
    value: 'America/Denver',
    label: 'Mountain Standard Time (MST)'
  }, {
    value: 'America/Los_Angeles',
    label: 'Pacific Standard Time (PST)'
  }, {
    value: 'America/Anchorage',
    label: 'Alaska Standard Time (AKST)'
  }, {
    value: 'Pacific/Honolulu',
    label: 'Hawaii Standard Time (HST)'
  }, {
    value: 'Etc/GMT+4',
    label: 'Atlantic Standard Time (AST)'
  }, {
    value: 'Etc/GMT+7',
    label: 'Arizona'
  }]);
  const getFormatedAddress = () => {
    let address = '';
    if (props.company?.address1) {
      address += props.company.address1;
    }
    if (props.company?.address2) {
      address += ', ' + props.company.address2;
    }
    if (props.company?.city) {
      address += ', ' + props.company.city;
    }
    if (props.company?.state) {
      address += ', ' + props.company.state;
    }
    if (props.company?.zip_code) {
      address += ', ' + props.company.zip_code;
    }
    return address;
  };
  const handleHelpPopupClick = (e, index) => {
    if (index == 'company_info') {
      setHelpData({
        title: <Typography variant="tableHeader"> Company Info</Typography>,
        content: <Stack spacing={'20px'}>
            <Typography component="div">
              <Box fontWeight="700" display="inline" sx={{
              pr: '3px'
            }}>
                Mailer return address:
              </Box>
              Your business will not need to worry about receiving any of the return mail
            </Typography>
          </Stack>
      });
    }
    setHelpAnchorEl(e.currentTarget);
  };
  const handleHelpClose = () => {
    setHelpAnchorEl(null);
    setHelpData(null);
  };
  const hasInputErrors = () => {
    if (companyInfoModal?.phone && companyInfoModal.phone != '' && !isValidPhoneNumber(companyInfoModal.phone, 'US') || companyInfoModal?.default_email && companyInfoModal.default_email != '' && !isEmail(companyInfoModal?.default_email)) {
      return true;
    }
    return false;
  };
  const hasBackendRejectionErrors = () => {
    if (props?.errors?.phone || props?.errors?.default_email || props?.errors?.address1 || props?.errors?.use_mailhouse_return_address || props?.errors?.timezone || props?.errors?.default_from_name || props?.errors?.address2 || props?.errors?.city || props?.errors?.state || props?.errors?.zip_code) {
      return true;
    }
    return false;
  };
  const canEdit = () => {
    return props?.company?.id && hasPermission('organization.change_company');
  };
  return <>
      <Paper sx={{
      width: '65vw',
      maxWidth: '1200px',
      borderRadius: '14px',
      border: hasBackendRejectionErrors() ? '2px solid #EF3C34' : 'none'
    }}>
        <Stack>
          <Stack direction="row" spacing={'10px'}>
            <Box sx={{
            py: '20px',
            pl: '20px',
            display: 'flex',
            alignItems: 'center'
          }}>
              <Typography sx={{
              fontSize: '20px',
              fontWeight: '400',
              lineHeight: '16.8px'
            }}>Company info</Typography>
            </Box>
            <Box sx={{
            display: 'flex',
            alignItems: 'center'
          }}>
              <IconButton onClick={e => handleHelpPopupClick(e, 'company_info')}>
                <HelpIcon fill="#3898D9" size="sm" />
              </IconButton>
            </Box>
          </Stack>

          {/* Address */}

          <Box className="cardrow" sx={{
          py: '10px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          '&:hover': {
            backgroundColor: '#F3F3F4',
            cursor: 'pointer'
          }
        }} onClick={() => {
          setCompanyInfoModalFocus('address1');
          setCompanyInfoModal(props.company);
        }}>
            <Box sx={{
            px: '20px'
          }}>
              <Typography>Address</Typography>
            </Box>

            {(props?.errors?.address1 || props?.errors?.address2 || props?.errors?.city || props?.errors?.state || props?.errors?.zip_code) && <Stack sx={{
            display: 'flex',
            alignItems: 'center'
          }}>
                <Typography sx={{
              color: '#EF3C34'
            }}>{props?.errors?.address1}</Typography>
                <Typography sx={{
              color: '#EF3C34'
            }}>{props?.errors?.address2}</Typography>
                <Typography sx={{
              color: '#EF3C34'
            }}>{props?.errors?.city}</Typography>
                <Typography sx={{
              color: '#EF3C34'
            }}>{props?.errors?.state}</Typography>
                <Typography sx={{
              color: '#EF3C34'
            }}>{props?.errors?.zip_code}</Typography>
              </Stack>}

            {props.loading ? <Box sx={{
            px: '20px',
            width: '30%',
            py: '8px'
          }}>
                <Skeleton variant="rectangular" width="100%" height={20} />
              </Box> : <Stack sx={{
            pr: '6px'
          }} direction={'row'} spacing="4px">
                <Box sx={{
              display: 'flex',
              alignItems: 'center'
            }}>
                  {props.company.address1 ? <Typography>{getFormatedAddress()}</Typography> : <Typography sx={{
                opacity: 0.5
              }}>None</Typography>}
                </Box>
                <Box className="icons__remove">
                  {props.company.address1 ? <Tooltip title={'Edit Address'}>
                      <IconButton>
                        <EditIcon />
                      </IconButton>
                    </Tooltip> : <Tooltip title={'Add Address'}>
                      <IconButton>
                        <AddIcon />
                      </IconButton>
                    </Tooltip>}
                </Box>
              </Stack>}
          </Box>
          <Box sx={{
          px: '20px'
        }}>
            <Divider />
          </Box>

          {/* Phone Number */}

          <Box className="cardrow" sx={{
          py: '10px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          '&:hover': {
            backgroundColor: '#F3F3F4',
            cursor: 'pointer'
          }
        }} onClick={() => {
          setCompanyInfoModalFocus('phone');
          setCompanyInfoModal(props.company);
        }}>
            <Box sx={{
            px: '20px'
          }}>
              <Typography>Phone</Typography>
            </Box>

            {props?.errors?.phone && <Box sx={{
            display: 'flex',
            alignItems: 'center'
          }}>
                <Typography sx={{
              color: '#EF3C34'
            }}>{props?.errors?.phone}</Typography>
              </Box>}

            {props.loading ? <Box sx={{
            px: '20px',
            py: '8px',
            width: '30%'
          }}>
                <Skeleton variant="rectangular" width="100%" height={20} />
              </Box> : <Stack sx={{
            pr: '6px'
          }} direction={'row'} spacing="4px">
                <Box sx={{
              display: 'flex',
              alignItems: 'center'
            }}>
                  {props.company.phone ? <Typography>{props.company.phone}</Typography> : <Typography sx={{
                opacity: 0.5
              }}>None</Typography>}
                </Box>
                <Box className="icons__remove">
                  {props.company.phone ? <Tooltip title={'Edit Phone'}>
                      <IconButton>
                        <EditIcon />
                      </IconButton>
                    </Tooltip> : <Tooltip title={'Add Phone'}>
                      <IconButton>
                        <AddIcon />
                      </IconButton>
                    </Tooltip>}
                </Box>
              </Stack>}
          </Box>
          <Box sx={{
          px: '20px'
        }}>
            <Divider />
          </Box>

          {/*Mail return address */}

          <Box className="cardrow" sx={{
          py: '10px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          '&:hover': {
            backgroundColor: '#F3F3F4',
            cursor: 'pointer'
          }
        }} onClick={() => {
          setCompanyInfoModalFocus('return_address');
          setCompanyInfoModal(props.company);
        }}>
            <Box sx={{
            px: '20px'
          }}>
              <Box sx={{
              display: 'flex',
              alignItems: 'center'
            }}>
                <Typography>Mailer return address</Typography>
              </Box>
            </Box>

            {props?.errors?.use_mailhouse_return_address && <Box sx={{
            display: 'flex',
            alignItems: 'center'
          }}>
                <Typography sx={{
              color: '#EF3C34'
            }}>{props?.errors?.use_mailhouse_return_address}</Typography>
              </Box>}

            {props.loading ? <Box sx={{
            px: '20px',
            py: '8px',
            width: '30%'
          }}>
                <Skeleton variant="rectangular" width="100%" height={20} />
              </Box> : <Stack sx={{
            pr: '6px'
          }} direction={'row'} spacing="4px">
                <Box sx={{
              display: 'flex',
              alignItems: 'center'
            }}>
                  <Typography>{props.company.use_mailhouse_return_address ? 'Mail house address' : 'Company address'}</Typography>
                </Box>
                <Box className="icons__remove">
                  <Tooltip title={'Edit mail return address'}>
                    <IconButton>
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Stack>}
          </Box>
          <Box sx={{
          px: '20px'
        }}>
            <Divider />
          </Box>

          {/* timezone */}

          <Box className="cardrow" sx={{
          py: '10px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          '&:hover': {
            backgroundColor: '#F3F3F4',
            cursor: 'pointer'
          }
        }} onClick={() => {
          setCompanyInfoModalFocus('timezone');
          setCompanyInfoModal(props.company);
        }}>
            <Box sx={{
            px: '20px'
          }}>
              <Typography>Timezone</Typography>
            </Box>

            {props?.errors?.timezone && <Box sx={{
            display: 'flex',
            alignItems: 'center'
          }}>
                <Typography sx={{
              color: '#EF3C34'
            }}>{props?.errors?.timezone}</Typography>
              </Box>}

            {props.loading ? <Box sx={{
            px: '20px',
            py: '8px',
            width: '30%'
          }}>
                <Skeleton variant="rectangular" width="100%" height={20} />
              </Box> : <Stack sx={{
            pr: '6px'
          }} direction={'row'} spacing="4px">
                <Box sx={{
              display: 'flex',
              alignItems: 'center'
            }}>
                  {props.company.timezone ? <Typography>
                      {filteredTimezones.find(timezone => timezone.value === props.company.timezone)?.label || 'Mountain Standard Time (MST)'}
                    </Typography> : <Typography sx={{
                opacity: 0.5
              }}>None</Typography>}
                </Box>
                <Box className="icons__remove">
                  {props.company.timezone ? <Tooltip title={'Edit Timezone'}>
                      <IconButton>
                        <EditIcon />
                      </IconButton>
                    </Tooltip> : <Tooltip title={'Add Timezone'}>
                      <IconButton>
                        <AddIcon />
                      </IconButton>
                    </Tooltip>}
                </Box>
              </Stack>}
          </Box>
          <Box sx={{
          px: '20px'
        }}>
            <Divider />
          </Box>

          {/* default email address*/}

          <Box className="cardrow" sx={{
          py: '10px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          '&:hover': {
            backgroundColor: '#F3F3F4',
            cursor: 'pointer'
          }
        }} onClick={() => {
          setCompanyInfoModalFocus('default_email');
          setCompanyInfoModal(props.company);
        }}>
            <Box sx={{
            px: '20px'
          }}>
              <Typography>Default email address</Typography>
            </Box>

            {props?.errors?.default_email && <Box sx={{
            display: 'flex',
            alignItems: 'center'
          }}>
                <Typography sx={{
              color: '#EF3C34'
            }}>{props?.errors?.default_email}</Typography>
              </Box>}
            {props.loading ? <Box sx={{
            px: '20px',
            py: '8px',
            width: '30%'
          }}>
                <Skeleton variant="rectangular" width="100%" height={20} />
              </Box> : <Stack sx={{
            pr: '6px'
          }} direction={'row'} spacing="4px">
                <Box sx={{
              display: 'flex',
              alignItems: 'center'
            }}>
                  {props.company.default_email ? <Typography>{props.company.default_email}</Typography> : <Typography sx={{
                opacity: 0.5
              }}>None</Typography>}
                </Box>
                <Box className="icons__remove">
                  {props.company.default_email ? <Tooltip title={'Edit default email address'}>
                      <IconButton>
                        <EditIcon />
                      </IconButton>
                    </Tooltip> : <Tooltip title={'Add default email address'}>
                      <IconButton>
                        <AddIcon />
                      </IconButton>
                    </Tooltip>}
                </Box>
              </Stack>}
          </Box>
          <Box sx={{
          px: '20px'
        }}>
            <Divider />
          </Box>

          {/* default from name */}

          <Box className="cardrow" sx={{
          py: '10px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          '&:hover': {
            backgroundColor: '#F3F3F4',
            cursor: 'pointer'
          }
        }} onClick={() => {
          setCompanyInfoModalFocus('default_from_name');
          setCompanyInfoModal(props.company);
        }}>
            <Box sx={{
            px: '20px'
          }}>
              <Typography>Default from Name</Typography>
            </Box>

            {props?.errors?.default_from_name && <Box sx={{
            display: 'flex',
            alignItems: 'center'
          }}>
                <Typography sx={{
              color: '#EF3C34'
            }}>{props?.errors?.default_from_name}</Typography>
              </Box>}

            {props.loading ? <Box sx={{
            px: '20px',
            py: '8px',
            width: '30%'
          }}>
                <Skeleton variant="rectangular" width="100%" height={20} />
              </Box> : <Stack sx={{
            pr: '6px'
          }} direction={'row'} spacing="4px">
                <Box sx={{
              display: 'flex',
              alignItems: 'center'
            }}>
                  {props.company.default_from_name ? <Typography>{props.company.default_from_name}</Typography> : <Typography sx={{
                opacity: 0.5
              }}>None</Typography>}
                </Box>
                <Box className="icons__remove">
                  {props.company.default_from_name ? <Tooltip title={'Edit Default from Name'}>
                      <IconButton>
                        <EditIcon />
                      </IconButton>
                    </Tooltip> : <Tooltip title={'Add Default from Name'}>
                      <IconButton>
                        <AddIcon />
                      </IconButton>
                    </Tooltip>}
                </Box>
              </Stack>}
          </Box>
          <Box sx={{
          px: '20px'
        }}></Box>
        </Stack>
      </Paper>

      <Dialog scroll="paper" PaperProps={{
      style: {
        borderRadius: '28px',
        width: '65%'
        // height: '75%',
      }
    }} open={!!companyInfoModal} onClose={(e, r) => {
      if (r != 'backdropClick') {
        setCompanyInfoModalFocus(null);
        setCompanyInfoModal(null);
      }
    }}>
        <DialogTitle sx={{
        m: 0,
        p: 2,
        fontSize: 'medium'
      }}>
          <Stack spacing={'20px'}>
            <Box display="flex" flexDirection="row" justifyContent="space-between">
              <Stack direction="row" spacing={'10px'}>
                <Box sx={{
                display: 'flex',
                alignItems: 'center'
              }}>
                  <Typography sx={{
                  fontWeight: '300',
                  lineHeight: '30px',
                  fontSize: '24px',
                  display: 'flex',
                  alignItems: 'center'
                }} variant="h4" component="span">
                    Company Info
                  </Typography>
                </Box>
                <Box sx={{
                display: 'flex',
                alignItems: 'center'
              }}>
                  <IconButton onClick={e => handleHelpPopupClick(e, 'company_info')}>
                    <HelpIcon fill="#3898D9" size="sm" />
                  </IconButton>
                </Box>
              </Stack>

              <IconButton onClick={() => {
              setCompanyInfoModalFocus(null);
              setCompanyInfoModal(null);
            }}>
                <CloseIcon />
              </IconButton>
            </Box>
            <Divider />
          </Stack>
        </DialogTitle>

        <DialogContent sx={{
        //overflowY: 'scroll',
        scrollbarWidth: 'thin',
        '&::-webkit-scrollbar': {
          width: '0.4em'
        },
        '&::-webkit-scrollbar-track': {
          background: '#f1f1f1',
          marginTop: '20px',
          marginBottom: '20px'
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: '#888'
        },
        '&::-webkit-scrollbar-thumb:hover': {
          background: '#555'
        }
      }}>
          <Stack sx={{
          pt: 1
        }} spacing={'24px'}>
            <Stack spacing={'24px'} direction={'row'}>
              <CustomTextField disabled={!canEdit()} variant="outlined" fullWidth autoFocus={companyInfoModalFocus === 'address1'} label="Address 1" value={companyInfoModal?.address1 || ''} onChange={e => {
              setAFieldHasChanged(true);
              setCompanyInfoModal({
                ...companyInfoModal,
                address1: e.target.value
              });
            }} />
              <CustomTextField disabled={!canEdit()} variant="outlined" fullWidth label="Address 2" value={companyInfoModal?.address2 || ''} onChange={e => {
              setAFieldHasChanged(true);
              setCompanyInfoModal({
                ...companyInfoModal,
                address2: e.target.value
              });
            }} />
            </Stack>
            <Stack spacing={'24px'} direction={'row'}>
              <CustomTextField disabled={!canEdit()} variant="outlined" fullWidth label="City" value={companyInfoModal?.city || ''} onChange={e => {
              setAFieldHasChanged(true);
              setCompanyInfoModal({
                ...companyInfoModal,
                city: e.target.value
              });
            }} />
              <CustomTextField disabled={!canEdit()} variant="outlined" fullWidth label="State" value={companyInfoModal?.state || ''} onChange={e => {
              setAFieldHasChanged(true);
              setCompanyInfoModal({
                ...companyInfoModal,
                state: e.target.value
              });
            }} />
            </Stack>
            <Stack spacing={'24px'} direction={'row'}>
              <CustomTextField disabled={!canEdit()} variant="outlined" fullWidth label="Zip Code" value={companyInfoModal?.zip_code || ''} onChange={e => {
              setAFieldHasChanged(true);
              setCompanyInfoModal({
                ...companyInfoModal,
                zip_code: e.target.value
              });
            }} />
              <CustomTextField disabled={!canEdit()} variant="outlined" fullWidth helperText={companyInfoModal?.phone && companyInfoModal.phone != '' && !isValidPhoneNumber(companyInfoModal.phone, 'US') ? 'Please enter a valid number' : ''} error={companyInfoModal?.phone && companyInfoModal.phone != '' && !isValidPhoneNumber(companyInfoModal.phone, 'US')} autoFocus={companyInfoModalFocus === 'phone'} label="Phone" value={companyInfoModal?.phone || ''} onChange={e => {
              setAFieldHasChanged(true);
              setCompanyInfoModal({
                ...companyInfoModal,
                phone: e.target.value
              });
            }} />
            </Stack>

            <Stack spacing={'24px'} direction={'row'}>
              <FormControl sx={{
              width: '48%'
            }}>
                <InputLabel id="use_mailhouse_return_address-label">Mail return address</InputLabel>
                <Select disabled={!canEdit()} autoFocus={companyInfoModalFocus === 'return_address'} labelId="use_mailhouse_return_address-label" id="use_mailhouse_return_address" value={companyInfoModal?.use_mailhouse_return_address || false} label="mail return address" onChange={e => {
                setAFieldHasChanged(true);
                setCompanyInfoModal({
                  ...companyInfoModal,
                  use_mailhouse_return_address: e.target.value
                });
              }}>
                  <MenuItem value={true}>Mail house address</MenuItem>
                  <MenuItem value={false}>Company address</MenuItem>
                </Select>
              </FormControl>

              <FormControl sx={{
              width: '48%'
            }}>
                <InputLabel id="timezone-label">Timezone</InputLabel>
                <Select disabled={!canEdit()} labelId="timezone-label" id="timezone" autoFocus={companyInfoModalFocus === 'timezone'} value={companyInfoModal?.timezone || 'America/Denver'} label="Timezone" onChange={e => {
                setAFieldHasChanged(true);
                setCompanyInfoModal({
                  ...companyInfoModal,
                  timezone: e.target.value
                });
              }}>
                  {filteredTimezones.map((timezone, index) => <MenuItem key={index} value={timezone.value}>
                      {timezone.label}
                    </MenuItem>)}
                </Select>
              </FormControl>
            </Stack>

            <Stack spacing={'24px'} direction={'row'}>
              <CustomTextField disabled={!canEdit()} variant="outlined" fullWidth helperText={companyInfoModal?.default_email && companyInfoModal.default_email != '' && !isEmail(companyInfoModal?.default_email) ? 'Please enter a valid email' : ''} error={companyInfoModal?.default_email && companyInfoModal.default_email != '' && !isEmail(companyInfoModal?.default_email)} autoFocus={companyInfoModalFocus === 'default_email'} label="Default email address" value={companyInfoModal?.default_email || ''} onChange={e => {
              setAFieldHasChanged(true);
              setDefaultEmailOrNameHasChanged(true);
              setCompanyInfoModal({
                ...companyInfoModal,
                default_email: e.target.value
              });
            }} />
              <CustomTextField disabled={!canEdit()} variant="outlined" fullWidth autoFocus={companyInfoModalFocus === 'default_from_name'} label="Default from name" value={companyInfoModal?.default_from_name || ''} onChange={e => {
              setAFieldHasChanged(true);
              setDefaultEmailOrNameHasChanged(true);
              setCompanyInfoModal({
                ...companyInfoModal,
                default_from_name: e.target.value
              });
            }} />
            </Stack>
          </Stack>
        </DialogContent>

        <DialogActions sx={{
        pt: 1,
        pb: 2
      }}>
          <Stack sx={{
          pr: '24px'
        }} direction={'row'} spacing={'20px'}>
            <Button onClick={() => {
            setCompanyInfoModalFocus(null);
            setCompanyInfoModal(null);
            setAFieldHasChanged(false);
          }} variant="contained" sx={{
            borderRadius: '20px',
            textTransform: 'none'
          }}>
              Cancel
            </Button>
            <Button disabled={hasInputErrors() || !aFieldHasChanged || !canEdit()} onClick={() => {
            setCompanyInfoModalFocus(null);
            props.updateCompany(companyInfoModal, defaultEmailOrNameHasChanged);
            setCompanyInfoModal(null);
            setAFieldHasChanged(false);
          }} variant="contained" sx={{
            mr: 1,
            borderRadius: '20px',
            textTransform: 'none',
            px: '20px'
          }}>
              Save
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>

      <Popper anchorEl={helpAnchorEl} open={!!helpData} onClose={handleHelpClose} placement="right-start" sx={{
      zIndex: 2100
    }}>
        <ClickAwayListener onClickAway={handleHelpClose}>
          <Paper elevation={0} sx={{
          borderRadius: '16px',
          overflow: 'visible',
          filter: 'drop-shadow(0px 0px 4px rgba(0,0,0,0.25))',
          width: '312px'
        }}>
            <Stack>
              <Box sx={{
              padding: '16px'
            }}>{helpData?.title} </Box>
              <Box sx={{
              px: '16px'
            }}> {helpData?.content}</Box>
              <Box sx={{
              py: '16px'
            }}>
                <Button sx={{
                display: 'flex',
                justifyItems: 'left',
                textTransform: 'none'
              }} onClick={handleHelpClose}>
                  {helpData?.buttonText || ' Got it'}
                </Button>
              </Box>
            </Stack>
          </Paper>
        </ClickAwayListener>
      </Popper>
    </>;
};