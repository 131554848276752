import React, { useContext, useEffect, useState } from 'react';
import { NodeSelection } from './node-selection.component';
import { MODES } from '../node/node-utils';
import { Box, IconButton, InputAdornment, TextField } from '@mui/material';
import { withStyles } from '@mui/styles';
import SearchIcon from '@mui/icons-material/Search';
import { CloseIcon } from 'components/mui';
import { UserStateContext } from 'context/user-state-context';
import { Scoped, k } from 'kremling';
const CustomTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      backgroundColor: 'white',
      borderRadius: `16px`,
      '& fieldset': {
        border: 'none'
      }
    }
  }
})(TextField);
export const NodeLibrary = ({
  nodes,
  mode,
  extraNodes,
  setSearch,
  search,
  setClickedNode,
  setChangedSearchValue
}) => {
  const {
    hasIntegration,
    asCompany,
    flags,
    hasPermission
  } = useContext(UserStateContext);
  const [triggerNodes, setTriggerNodes] = useState([]);
  const [actionNodes, setActionNodes] = useState([]);
  const [conditionNodes, setConditionNodes] = useState([]);
  useEffect(() => {
    const hasField = field => !asCompany?.fields || asCompany?.fields.some(f => f.id === field);
    let triggerNodes = [];
    let actionNodes = [];
    let conditionNodes = [];
    nodes.forEach(node => {
      if (search && search !== '' && (node.name || '').toLowerCase().indexOf(search.toLowerCase()) === -1) {
        // We have a search filter that's filtering out this node.
        return;
      }
      if (node.type === 'trigger') {
        if ((node.subType !== 'trigger_time_schedule' || hasField('schedule')) && (node.subType !== 'trigger_schedule' || hasField('schedule')) && (node.subType !== 'trigger_abandoned_cart' || hasField('cart')) && (node.subType !== 'trigger_shopify_fulfillment' || hasIntegration('shopify')) && (node.subType !== 'trigger_zapier' || hasIntegration('zapier')) && (node.subType !== 'trigger_review' || hasIntegration('reviews')) && (node.subType !== 'trigger_loyalty_transaction' || hasIntegration('loyalty')) && (node.subType !== 'trigger_received_coupon' || hasIntegration('loyalty')) && (node.subType !== 'trigger_joined_loyalty' || hasIntegration('loyalty')) && (node.subType !== 'trigger_redeemed_coupon' || hasIntegration('loyalty')) && (!node.visible || node.visible({
          hasPermission
        }))) {
          triggerNodes.push(node);
        }
      } else if (node.type === 'action') {
        if ((node.subType !== 'action_send_to_aircall' || hasIntegration('aircall')) && (node.subType !== 'action_send_to_calltools' || hasIntegration('calltools')) && (node.subType !== 'action_zapier' || hasIntegration('zapier')) && (node.subType !== 'action_add_audiohook' || hasIntegration('audiohook')) && (node.subType !== 'action_remove_audiohook' || hasIntegration('audiohook')) && (node.subType !== 'action_send_survey_sms' || hasIntegration('surveys')) && (node.subType !== 'action_send_survey_email' || hasIntegration('surveys')) && (node.subType !== 'action_give_offer' || hasIntegration('loyalty')) && (node.subType !== 'action_swell_review' || hasIntegration('swell')) && (node.subType !== 'action_swell_sms' || hasIntegration('swell')) && (node.subType !== 'action_swell_email' || hasIntegration('swell')) && (node.subType !== 'action_reply_review' || hasIntegration('reviews')) && (!node.visible || node.visible({
          hasPermission
        }))) {
          actionNodes.push(node);
        }
      } else if (node.type === 'condition') {
        if (!node.visible || node.visible({
          hasPermission
        })) {
          conditionNodes.push(node);
        }
      }
    });
    setTriggerNodes(triggerNodes);
    setActionNodes(actionNodes);
    setConditionNodes(conditionNodes);
  }, [hasIntegration, asCompany?.fields, search, nodes, extraNodes]);
  const handleClickedNode = node => {
    setClickedNode(node);
    setChangedSearchValue(false);
  };
  return <Scoped css={css}>
      <Box sx={{
      background: '#f3f7fa',
      width: '315px',
      flexGrow: '0',
      flexShrink: '0',
      userSelect: 'none',
      position: 'relative',
      overflowY: 'auto',
      overflowX: 'hidden',
      height: '100%',
      scrollbarWidth: 'thin',
      '&::-webkit-scrollbar': {
        width: '0.4em'
      },
      '&::-webkit-scrollbar-track': {
        background: '#f1f1f1'
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#888'
      },
      '&::-webkit-scrollbar-thumb:hover': {
        background: '#555'
      }
    }}>
        <div className="toolbar">
          <Box sx={{
          pb: '24px'
        }}>
            <CustomTextField sx={{
            width: '273px'
          }} placeholder="Search..." value={search} onChange={e => setSearch(e.target.value)} InputProps={{
            startAdornment: <InputAdornment position="start">
                    <SearchIcon sx={{
                transform: 'scale(1.5)'
              }} />
                  </InputAdornment>,
            endAdornment: <InputAdornment position="end">
                    {search != '' && <IconButton onClick={e => {
                setSearch('');
              }}>
                        <CloseIcon size="sm" />
                      </IconButton>}
                  </InputAdornment>
          }} />
          </Box>

          {triggerNodes.length > 0 && <>
              <div className="toolbar__title">Triggers</div>
              <div className="toolbar-section">
                {triggerNodes.map(node => <NodeSelection key={node.templateId || node.subType} node={node} draggable={mode === MODES.CANVAS} setClicked={node => {
              handleClickedNode(node);
            }} flags={flags} />)}
              </div>
            </>}
          {actionNodes.length > 0 && <>
              <div className="toolbar__title">Actions</div>
              <div className="toolbar-section">
                {actionNodes.map(node => <NodeSelection key={node.templateId || node.subType} node={node} draggable={mode === MODES.CANVAS} setClicked={node => {
              handleClickedNode(node);
            }} flags={flags} />)}
              </div>
            </>}
          {conditionNodes.length > 0 && <>
              <div className="toolbar__title">Conditions</div>
              <div className="toolbar-section">
                {conditionNodes.map(node => <NodeSelection key={node.templateId || node.subType} node={node} draggable={mode === MODES.CANVAS} setClicked={node => {
              handleClickedNode(node);
            }} flags={flags} />)}
              </div>
            </>}
          {/*mode === MODES.EDIT_PARAM && <div className="toolbar__overlay" />*/}
        </div>
      </Box>
    </Scoped>;
};
const css = {
  styles: `[kremling="i41"] body,body[kremling="i41"] {
  --color-primary: #487aae;
  --color-highlight: #e7efff;
  --color-accent: #354052;
  --color-success: #73b56e;
  --color-warning: #f4b707;
  --color-danger: #df5651;
  --color-grey-10: #f9f9f9;
  --color-grey-25: #f3f3f3;
  --color-grey-50: #e9e9e9;
  --color-grey-75: #e3e3e3;
  --color-grey-100: #d3d3d3;
  --color-grey-200: #c3c3c3;
  --color-grey-300: #b3b3b3;
  --color-grey-400: #a3a3a3;
  --color-grey-500: #808080;
  --color-grey-600: #707070;
  --color-grey-700: #606060;
  --color-grey-800: #505050;
  --color-grey-900: #404040;
  --base-font-family: Roboto, Helvetica, Arial, sans-serif;
  --base-font-size: 1.4rem;
  --base-font-color: #404040;
  --base-font-weight: 400;
  --base-line-height: 1.4;
  --box-shadow-1: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.12);
  --box-shadow-2: 0 3px 6px rgba(0, 0, 0, 0.1), 0 3px 6px rgba(0, 0, 0, 0.11);
  --box-shadow-3: 0 10px 20px rgba(0, 0, 0, 0.17), 0 6px 6px rgba(0, 0, 0, 0.11);
  --box-shadow-4: 0 14px 28px rgba(0, 0, 0, 0.13), 0 10px 10px rgba(0, 0, 0, 0.1);
  --box-shadow-5: 0 19px 38px rgba(0, 0, 0, 0.18), 0 15px 12px rgba(0, 0, 0, 0.1);
  --base-border-radius: 0.4rem;
}

[kremling="i41"] .search-icon,[kremling="i41"].search-icon {
  position: absolute;
  top: 8px;
  left: 15px;
  color: #354052;
}

[kremling="i41"] .toolbar .form-control,[kremling="i41"].toolbar .form-control {
  padding-left: 2.5rem;
}

[kremling="i41"] .toolbar,[kremling="i41"].toolbar {
  background-color: #F3F7FA;
  width: 309px;
  padding: 1.6rem 0.8rem;
  flex-grow: 0;
  flex-shrink: 0;
  user-select: none;
  position: relative;
}

[kremling="i41"] .toolbar__title,[kremling="i41"].toolbar__title {
  color: #1D252D;
  font-family: Nexa;
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 16px;
  padding: 0 0.8rem;
}

[kremling="i41"] .toolbar-section,[kremling="i41"].toolbar-section {
  display: flex;
  flex-wrap: wrap;
}

[kremling="i41"] .toolbar__overlay,[kremling="i41"].toolbar__overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 30.9rem;
  background-color: rgba(0, 0, 0, 0.5);
}`,
  id: 'i41',
  namespace: 'kremling'
};