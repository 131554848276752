import React, { useContext, useState, useEffect } from 'react';
import { UserStateContext } from 'context/user-state-context';
import { deleteFacebookToken, deleteFacebookInstall, getFacebookReviewAccounts, getFacebookReviewAccountLocationRefs, postFacebookReviewAccount, syncFacebookReviewAccountPages } from '../integrations.resource';
import { Icon, Loader, PageHeaderMui } from 'components';
import { Box, Button, IconButton, Paper, Stack, Tooltip, Typography, Divider, Popper, ClickAwayListener, DialogTitle, Dialog, DialogActions, DialogContent, CircularProgress } from '@mui/material';
import { AddIcon, CancelIcon, CheckCircleIcon, CloseIcon, EditIcon, LockClockIcon, LockResetIcon, MoreVertIcon, WarningRedIcon } from 'components/mui';
import { snackbarService } from 'components/mui/snackbar/snackbar-service';
import { buildQueryParams } from '../../../shared/api';
import queryString from 'query-string';
import { DateTime } from 'luxon';
import { buildFacebookQueryParams } from 'shared/utils';
const FACEBOOKICON = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACkAAAAoCAYAAABjPNNTAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAALTSURBVHgBzZkxTBRBFIZ/JtdIg4mWGjqwQuzEBBoKm7v2Kkho1JhoZWy4swMLE2MhatQGghUl0GgiBSQaO8RG6VATGyHQcCXsv5M5dvdm92Z2dpb7ks1ddmdv/33z5s177/pOApCDzV1g5w/wPTi2gu9HLeDw+Oz6yBXgYr/8rN0AJoaQmz4bkRTxagNY+BwXZAIF164DjRoweMnqVjORLuJ0TI/Zie0qcnUbuLdUjLgkzUBoo9p9XKbIxyvSej6hr76bybZqqsi7i8DyV5QCBX58lC5U6E6WKZDs7QO3n8tPHR2WnFsH5tdQCFHLJEOUDoarb086z8dELn8JrLgEJ8aH5Oqtjcqwk4Sxtf4m3WoPJ4Fn9fi5ivrCm+bX4USzKkNLFiNXs6+/DBZqdTQe/Ns+yVWc9nZFCTQl6W6hSIpzCTX0vaIEEm65PBShSPqiCw8mUThRa4Y++cEx3EwMp1+jRThLR5GVbeJWvI/jOEsVZjEuvkgYOnTs/JXxLy9r23KWxNYuvDG3CieUX4rNX/DGYQtOKAMK16n2CXcoHuL3AXoabqfCR55YJHv/I9uiCSqlMmXlvrREFK4B2/ygwiTAxpo29Ql/O5lkDPTDGjFwAaXCLMiGwcuByLRA7AsbkZwFzpywLS9dsQl5yoAVFu4LG+Y36h6S9qK6sdwqTWmLVJ0Gk8XDh16b7Tzfeqsff2fxbNfIA7N7Iihw6iZ6Ds6Oyq7CfJJT3mtEk+hQJOuJcYeGUtHQiizmFO0a5/2Mvro7D5KlSFsk1c8a9GV8MzUWtyKJdTBY857ntNNQTU1B19FmYVJQdoAndLW0fpCwGeyLbs/UNqxUSlbGvm7yLJF1M5tHDY+Lif5vYgyBLtCRfz4tdvo5vWycfjJ0K6PMnD9Eoex0sG+Zdz+mxaZvyTBjE5Ot/n1QMNFg4c5++o8gq/n3Qj+u/lqWtdzRwmMYuTgFmxL6hnUmfpQAAAAASUVORK5CYII=';
export const IntegrationsManageFacebookReview = ({
  history,
  location,
  match,
  staticContext
}) => {
  const {
    user,
    asCompany,
    hasPermission,
    hasMultiAccess,
    flags
  } = useContext(UserStateContext);
  const [loading, setLoading] = useState(true);
  const [accounts, setAccounts] = useState([]);
  const [editExistingCredentialMenuAnchorEl, setEditExistingCredentialMenuAnchorEl] = React.useState(null);
  const [editExistingCredentialMenu, setEditExistingCredentialMenu] = useState(null);
  const [showDeleteCredentialModal, setShowDeleteCredentialModal] = useState(null);
  const [openConfirmDeleteInstallModal, setOpenConfirmDeleteInstallModal] = useState(null);
  const [optionsMenu, setOptionsMenu] = useState([]);
  const [editExistingInstallMenuAnchorEl, setEditExistingInstallMenuAnchorEl] = React.useState(null);
  const [editExistingInstallMenu, setEditExistingInstallMenu] = useState(null);
  const [tokenIsRefreshing, setTokenIsRefreshing] = useState(null);

  // While we're waiting for the facebook overlords to approve `pages_read_user_content`
  // We need to make sure it's NOT added to our scope until the feature is turned on via
  // feature flag (once they actually approve us) so we don't break anyones token permission granting flow
  let queryParamsScope = 'ads_management,leads_retrieval,pages_manage_metadata,pages_show_list,pages_manage_ads,pages_read_engagement,business_management,pages_read_user_content';
  const facebookQueryParams = buildFacebookQueryParams(`${window.location.protocol}//${window.location.host}/integrations/manage/facebook_reviews`, queryParamsScope);
  useEffect(() => {
    if (!asCompany.id) {
      return;
    }
    const loadData = async () => {
      setLoading(true);
      await handleRedirect();
      // TODO GET FOR COMPANY NOT EVERYONE!
      await getReviewAccounts();
      setLoading(false);
    };
    loadData();
  }, [asCompany.id]);
  const handleRedirect = async () => {
    const {
      access_token,
      expires_in
    } = queryString.parse(location.hash.slice(1));
    if (!access_token || !expires_in) {
      return;
    }
    const seconds = new Date().getTime() / 1000 + parseInt(expires_in);
    const expires_when = DateTime.fromSeconds(seconds).toUTC().toISO();
    const {
      error
    } = queryString.parse(location.search);
    if (error) {
      snackbarService.popup({
        type: 'error',
        message: 'Unable to connect account from facebook'
      });
      return;
    }
    try {
      const accountData = await postFacebookReviewAccount({
        access_token: access_token,
        expires_when: expires_when,
        company: asCompany.id
      });
      await syncFacebookReviewAccountPages(accountData.id);
      snackbarService.popup({
        type: 'success',
        message: 'Successfully connected facebook account'
      });
    } catch (error) {
      console.error(error, 'error');
      if (error?.response?.data && error.response.data?.non_field_errors) {
        snackbarService.popup({
          type: 'error',
          message: error.response.data.non_field_errors[0]
        });
      }
    } finally {
      // clear the url params so the user don't refresh and attempt
      // to retrigger the whole process
      history.replace({
        pathname: location.pathname,
        search: '',
        hash: ''
      });
    }
  };

  /* Gets accounts, also extends the accounts with any associated location_refs
     for some meta data information */
  const getReviewAccounts = async () => {
    const response = await getFacebookReviewAccounts({
      company: asCompany.id
    });
    const data = response.results;
    const promises = [];
    for (const record of data) {
      promises.push(getFacebookReviewAccountLocationRefs(record.id));
    }
    const locationRefResults = await Promise.all(promises);
    for (const record of data) {
      record.location_refs = [];
      for (const locationRef of locationRefResults) {
        if (locationRef.account_id === record.id && locationRef.location_refs.length > 0) {
          record.location_refs.push(...locationRef.location_refs);
        }
      }
    }
    setAccounts(data);
    // await setOptions(data);
  };

  // TODO get this working eventually
  // const setOptions = async (accountData) => {
  //   const menuItems = [];
  //   if (hasPermission('integration.change_credential')) {
  //     menuItems.push({
  //       label: 'Refresh Permissions',
  //       onClick: () => {
  //         window.open(`https://www.facebook.com/v4.0/dialog/oauth${facebookQueryParams}`);
  //       },
  //       icon: <LockResetIcon />,
  //       key: 'refresh',
  //     });

  //     menuItems.push({
  //       label: 'Edit account',
  //       onClick: () => {
  //         history.push(`/integrations/configure/facebook_reviews/${accountData.id}`);
  //       },
  //       icon: <EditIcon />,
  //       key: 'edit',
  //     });
  //   }
  //   setOptionsMenu(menuItems);
  // };

  const handleCredentialMenuClick = (e, credential) => {
    if (editExistingCredentialMenuAnchorEl && editExistingCredentialMenu === credential) {
      handleEditExistingCredentialMenuClose();
      return;
    }
    handleEditExistingInstallMenuClose();
    setEditExistingCredentialMenu(credential);
    setEditExistingCredentialMenuAnchorEl(e.currentTarget);
  };
  const handleEditExistingCredentialMenuClose = () => {
    setEditExistingCredentialMenu(null);
    setEditExistingCredentialMenuAnchorEl(null);
  };
  const handleInstallMenuClick = (e, install) => {
    if (editExistingInstallMenuAnchorEl && editExistingInstallMenu === install) {
      handleEditExistingInstallMenuClose();
      return;
    }
    handleEditExistingCredentialMenuClose();
    setEditExistingInstallMenu(install);
    setEditExistingInstallMenuAnchorEl(e.currentTarget);
  };
  const handleEditExistingInstallMenuClose = () => {
    setEditExistingInstallMenu(null);
    setEditExistingInstallMenuAnchorEl(null);
  };
  const handleDeleteCredential = token => {
    deleteFacebookToken(token.id).then(() => {
      setShowDeleteCredentialModal(null);
      setRenderIteration(i => i + 1);
      snackbarService.popup({
        type: 'success',
        message: `Credential has been removed successfully!`
      });
    });
  };
  const handleDeleteInstall = install => {
    deleteFacebookInstall(install.id).then(() => {
      setOpenConfirmDeleteInstallModal(null);
      handleEditExistingInstallMenuClose();
      history.push('/integrations', {
        removedIntegration: install.firstname + ' ' + install.lastname + ' Facebook Integration'
      });
    }).catch(error => {
      snackbarService.popup({
        type: 'error',
        message: 'There was an error removing the integration.'
      });
    });
  };
  const isTokenExpiredActions = token => {
    const isExpired = new Date(token).getTime() / 1000 < new Date().getTime() / 1000;
    if (isExpired) {
      return <Stack direction="row" spacing="8px" sx={{
        p: '7px'
      }}>
          <Tooltip
        /*  title="Cinch's connection to your facebook account has expired. Click on 'Refresh Permissions' to get a new token" */>
            <Box sx={{
            display: 'flex',
            alignItems: 'center',
            pr: '4px'
          }}>
              <Typography sx={{
              color: '#EF3C34'
            }}>Expired</Typography>
            </Box>
          </Tooltip>
          <Box>
            <Tooltip title="Refresh Permissions">
              <IconButton onClick={e => {
              e.stopPropagation();
              window.open(`https://www.facebook.com/v4.0/dialog/oauth${facebookQueryParams}`);
            }}>
                <LockResetIcon />
              </IconButton>
            </Tooltip>
          </Box>
        </Stack>;
    } else {
      const seconds = new Date(token).getTime() / 1000 - new Date().getTime() / 1000;
      const days = Math.floor(seconds / (3600 * 24));
      return <Tooltip
      /*     title={
          "Cinch's connection to your Facebook account will expire in " +
          days +
          " days. For uninterupted service you will need to refresh your token by clicking on the menu and selecting 'Refresh Permissions'"
        } */>
          <Stack direction="row" spacing="8px" sx={{
          p: '7px'
        }}>
            <Box sx={{
            display: 'flex',
            alignItems: 'center',
            pr: '4px'
          }}>
              <Typography> {days} days </Typography>
            </Box>
          </Stack>
        </Tooltip>;
    }
  };
  const isTokenExpiredStatus = token => {
    const isExpired = new Date(token).getTime() / 1000 < new Date().getTime() / 1000;
    if (isExpired) {
      return <Tooltip title="Cinch's connection to your facebook account has expired. Click on 'Refresh Permissions' to get a new token">
          <IconButton>
            <WarningRedIcon />
          </IconButton>
        </Tooltip>;
    } else {
      const seconds = new Date(token).getTime() / 1000 - new Date().getTime() / 1000;
      const days = Math.floor(seconds / (3600 * 24));
      return <Tooltip title={"Cinch's connection to your Facebook account will expire in " + days + " days. For uninterupted service you will need to refresh your token by clicking on the menu and selecting 'Refresh Permissions'"}>
          <IconButton>
            <CheckCircleIcon fill="#3EB87B" />
          </IconButton>
        </Tooltip>;
    }
  };
  return <>
      <div className="wrapper" style={{
      height: loading ? '100vh' : 'auto'
    }}>
        <PageHeaderMui type="Integrations and Apps" icon={<Icon name="custom-call_merge" size={34} />} />

        <Box sx={{
        p: '20px',
        display: 'flex',
        justifyContent: 'center'
      }}>
          <Paper sx={{
          borderRadius: '14px',
          maxWidth: '1080px',
          width: '800px',
          minHeight: '60vh'
        }}>
            {loading ? <Box sx={{
            width: '100%',
            height: '100%'
          }}>
                <Box sx={{
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignContent: 'center',
              justifyItems: 'center',
              alignItems: 'center'
            }}>
                  <CircularProgress />
                </Box>
              </Box> : <Stack sx={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between'
          }}>
                <Stack sx={{
              py: '10px'
            }}>
                  <Stack>
                    <Box sx={{
                  px: '20px',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  pb: '10px'
                }}>
                      <Stack direction="row" spacing="20px">
                        <Box>
                          <img src={FACEBOOKICON} height="40" width="40" alt="facebook" />
                        </Box>

                        <Box sx={{
                      display: 'flex',
                      alignItems: 'center'
                    }}>
                          <Typography sx={{
                        fontSize: '20px',
                        fontWeight: '300',
                        lineHeight: '28px'
                      }}>
                            {'Manage Facebook Reviews'}
                          </Typography>
                        </Box>
                      </Stack>

                      <Box>
                        <IconButton onClick={e => {
                      history.push(`/integrations`);
                    }}>
                          <CloseIcon />
                        </IconButton>
                      </Box>
                    </Box>
                    <Divider />
                  </Stack>

                  <Stack sx={{
                pt: '20px'
              }}>
                    <Box>
                      <Stack>
                        <Box sx={{
                      px: '20px'
                    }}>
                          <Typography sx={{
                        fontSize: '18px',
                        fontWeight: '300',
                        lineHeight: '26px'
                      }}>Description</Typography>
                        </Box>

                        <Box sx={{
                      pt: '20px',
                      pb: '30px',
                      px: '20px'
                    }}>
                          <Typography>Connect Cinch to your Facebook account</Typography>
                        </Box>

                        <Divider />
                        {/* This is the meat and potatoes of the logic*/}
                        <>
                          {accounts.map((account, index) => <Stack key={account?.id}>
                              {/* TODO is this needed in this instance super_user vs not? */}
                              {user?.is_superuser && <Box sx={{
                          border: '1px solid #E2E2E2',
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          alignItems: 'center'
                        }}>
                                  {/* <Stack sx={{ px: '20px', py: '17px' }} direction="row" spacing="20px">
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                      <Typography variant="tableheader" sx={{ fontSize: '18px' }}>
                                        {account.name ? account.name : 'No Name Provided'}
                                      </Typography>
                                    </Box>
                                   </Stack> */}
                                  <Box sx={{
                            pr: '20px'
                          }}>
                                    {optionsMenu.length > 0 && <IconButton onClick={e => {
                              e.stopPropagation();
                              handleInstallMenuClick(e, account);
                            }}>
                                        <MoreVertIcon />
                                      </IconButton>}
                                  </Box>
                                </Box>}
                              <Stack sx={{}}>
                                {/** HERE */}
                                {account?.token && <Stack onClick={() => {
                            // TODO set this up, this would be the other form I've worked on....
                            if (hasPermission('integration.view_credential')) {
                              history.push(`/integrations/configure/facebook_reviews/${account.id}`);
                            } else {
                              snackbarService.popup({
                                type: 'error',
                                message: 'You do not have permission to view credentials'
                              });
                            }
                          }} key={account.token.id}>
                                    <Box sx={{
                              pl: user?.is_superuser ? '36px' : '0px',
                              border: '1px solid #E2E2E2',
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                              cursor: 'pointer',
                              '&:hover': {
                                backgroundColor: '#F3F3F4',
                                boxShadow: 'none'
                              }
                            }}>
                                      <Stack sx={{
                                px: '20px',
                                py: '17px'
                              }} direction="row" spacing="20px">
                                        <Box sx={{
                                  display: 'flex',
                                  alignItems: 'center'
                                }}>
                                          <Typography sx={{
                                    fontSize: '18px'
                                  }}>{account.token.name}</Typography>
                                        </Box>
                                        <Tooltip title="You have configured this credential. Click to edit it">
                                          <Stack direction="row" spacing="8px" sx={{
                                    p: '7px',
                                    borderRadius: '8px',
                                    backgroundColor: '#C5EAD7'
                                  }}>
                                            <Box sx={{
                                      display: 'flex',
                                      alignItems: 'center'
                                    }}>
                                              <CheckCircleIcon size="sm" fill="#3EB87B" />
                                            </Box>
                                            <Box sx={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      pr: '4px'
                                    }}>
                                              <Typography sx={{
                                        color: '#3EB87B'
                                      }}>Connected</Typography>
                                            </Box>
                                          </Stack>
                                        </Tooltip>
                                      </Stack>
                                      <Box sx={{
                                pr: '20px'
                              }}>
                                        {optionsMenu.length > 0 && <IconButton onClick={e => {
                                  e.stopPropagation();
                                  handleCredentialMenuClick(e, token);
                                }}>
                                            <MoreVertIcon />
                                          </IconButton>}
                                      </Box>
                                    </Box>
                                    <Box>
                                      <Box sx={{
                                pl: user?.is_superuser ? '72px' : '36px',
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                cursor: 'pointer',
                                '&:hover': {
                                  backgroundColor: '#F3F3F4',
                                  boxShadow: ' 0px 1px 8px 0px rgba(0, 0, 0, 0.15), 0px 1px 8px 0px rgba(0, 0, 0, 0.30)'
                                }
                              }}>
                                        <Stack direction="row" spacing="24px" sx={{
                                  display: 'flex',
                                  alignItems: 'center'
                                }}>
                                          <LockClockIcon />
                                          <Box sx={{
                                    py: '22px'
                                  }}>
                                            <Typography sx={{
                                      fontSize: '14px',
                                      fontWeight: '700'
                                    }}>Permissions expiration</Typography>
                                          </Box>
                                          <Box>{isTokenExpiredStatus(account.token.expires_when)}</Box>
                                        </Stack>

                                        <Box>{isTokenExpiredActions(account.token.expires_when)}</Box>
                                      </Box>
                                    </Box>
                                    <Divider />
                                    <Box>
                                      <Box sx={{
                                pl: user?.is_superuser ? '72px' : '36px',
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                cursor: 'pointer',
                                '&:hover': {
                                  backgroundColor: '#F3F3F4',
                                  boxShadow: ' 0px 1px 8px 0px rgba(0, 0, 0, 0.15), 0px 1px 8px 0px rgba(0, 0, 0, 0.30)'
                                }
                              }}>
                                        <Stack direction="row" spacing="24px" sx={{
                                  display: 'flex',
                                  alignItems: 'center'
                                }}>
                                          <LockClockIcon />
                                          <Box sx={{
                                    py: '22px'
                                  }}>
                                            <Typography sx={{
                                      fontSize: '14px',
                                      fontWeight: '700'
                                    }}>Pages</Typography>
                                          </Box>
                                          {/* {account.location_refs.length == 0 || token.location_refs.some((c) => c?.company.length == 0) ? (
                                            <Box>
                                              <Tooltip title="One of your ad accounts does not have a Cinch company mapped to it. Click to configure your ad accounts">
                                                <IconButton>
                                                  <WarningRedIcon />
                                                </IconButton>
                                              </Tooltip>
                                            </Box>
                                           ) : (
                                            <Box>
                                              <Tooltip title="All of your ad accounts are mapped to Cinch">
                                                <IconButton>
                                                  <CheckCircleIcon fill="#3EB87B" />
                                                </IconButton>
                                              </Tooltip>
                                            </Box>
                                           )} */}
                                        </Stack>

                                        <Stack direction="row">
                                          <Tooltip>
                                            <Box sx={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      pr: '20px'
                                    }}>
                                              <Typography
                                      // sx={{
                                      //   color:
                                      //     account.location_refs.length == 0 ||
                                      //     account.location_refs.some((c) => c?.company.length == 0)
                                      //       ? '#EF3C34'
                                      //       : '#1D252D',
                                      // }}
                                      >
                                                {account.location_refs.length + ' pages(s)'}
                                              </Typography>
                                            </Box>
                                          </Tooltip>
                                          <Tooltip title="Configure pages">
                                            <IconButton>
                                              <EditIcon />
                                            </IconButton>
                                          </Tooltip>
                                        </Stack>
                                      </Box>
                                    </Box>
                                  </Stack>}
                              </Stack>
                            </Stack>)}
                        </>

                        {hasPermission('integration.add_credential') ? <Stack>
                            <Box sx={{
                        border: '1px solid #E2E2E2',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        cursor: 'pointer',
                        '&:hover': {
                          backgroundColor: '#F3F3F4',
                          boxShadow: ' 0px 1px 8px 0px rgba(0, 0, 0, 0.15), 0px 1px 8px 0px rgba(0, 0, 0, 0.30)'
                        }
                      }} onClick={() => {
                        window.open(`https://www.facebook.com/v4.0/dialog/oauth${facebookQueryParams}`);
                      }}>
                              <Stack sx={{
                          px: '20px',
                          py: '25px'
                        }} direction="row" spacing="20px">
                                <Box>
                                  <Typography sx={{
                              fontSize: '18px'
                            }}>Add Account</Typography>
                                </Box>
                              </Stack>
                              <Box sx={{
                          pr: '20px'
                        }}>
                                <Tooltip title="Add new credentials">
                                  <IconButton>
                                    <AddIcon />
                                  </IconButton>
                                </Tooltip>
                              </Box>
                            </Box>
                          </Stack> : <Box sx={{
                      p: 2
                    }}>
                            <Typography> You do not have permission to add new credentials</Typography>
                          </Box>}
                      </Stack>
                    </Box>
                  </Stack>
                </Stack>
                <Stack>
                  <Divider />
                  <Box sx={{
                p: '20px',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}>
                    {hasPermission('integration.delete_credential') && !user.is_superuser ? <Button sx={{
                  backgroundColor: '#F3F3F4',
                  '&:hover': {
                    backgroundColor: '#F3F3F4'
                  }
                }} variant="contained" startIcon={<CancelIcon fill="#3898D9" />} onClick={e => {
                  setOpenConfirmDeleteInstallModal(installs[0]);
                }}>
                        <Box sx={{
                    py: '5px',
                    pr: '12px'
                  }}>
                          <Typography sx={{
                      color: '#3898D9'
                    }}>Uninstall </Typography>
                        </Box>
                      </Button> : <Box></Box>}

                    <Stack direction="row" spacing="16px">
                      <Button sx={{
                    backgroundColor: '#F3F3F4',
                    '&:hover': {
                      backgroundColor: '#F3F3F4'
                    }
                  }} variant="contained" onClick={e => {
                    history.push(`/integrations`);
                  }}>
                        <Box sx={{
                      py: '5px',
                      px: '12px'
                    }}>
                          <Typography sx={{
                        color: '#3898D9'
                      }}>Cancel </Typography>
                        </Box>
                      </Button>
                      <Button sx={{
                    backgroundColor: '#3898D9',
                    '&:hover': {
                      backgroundColor: '#3898D9'
                    }
                  }} variant="contained" onClick={e => {
                    history.push(`/integrations`);
                  }}>
                        <Box sx={{
                      py: '5px',
                      px: '12px'
                    }}>
                          <Typography sx={{
                        color: '#fff'
                      }}> Done</Typography>
                        </Box>
                      </Button>
                    </Stack>
                  </Box>
                </Stack>
              </Stack>}
          </Paper>
        </Box>
      </div>

      <Popper anchorEl={editExistingCredentialMenuAnchorEl} open={!!editExistingCredentialMenu} onClose={handleEditExistingCredentialMenuClose} placement="right-start" sx={{
      zIndex: 200
    }}>
        <ClickAwayListener onClickAway={handleEditExistingCredentialMenuClose}>
          <Paper elevation={0} sx={{
          borderRadius: '16px',
          overflow: 'visible',
          filter: 'drop-shadow(0px 0px 4px rgba(0,0,0,0.25))'
          // width: '312px',
        }}>
            <Stack>
              {optionsMenu.map(item => <Button key={item.key} disabled={tokenIsRefreshing == editExistingCredentialMenu && item.key == 'resync'} sx={{
              backgroundColor: tokenIsRefreshing == editExistingCredentialMenu && item.key == 'resync' ? '#F3F3F4' : 'transparent',
              width: '100%',
              height: '48px',
              borderRadius: '16px',
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              padding: '0 16px',
              ':hover': {
                backgroundColor: '#DAEFFF',
                color: '#53A6D6'
              }
            }} onClick={e => {
              item.onClick(editExistingCredentialMenu);
              handleEditExistingCredentialMenuClose();
            }}>
                  {tokenIsRefreshing == editExistingCredentialMenu && item.key == 'resync' ? <CircularProgress size={'20px'} /> : item.icon}
                  <Typography sx={{
                textTransform: 'none',
                ml: '16px'
              }}>{item.label}</Typography>
                </Button>)}
            </Stack>
          </Paper>
        </ClickAwayListener>
      </Popper>

      <Dialog scroll="paper" PaperProps={{
      style: {
        borderRadius: '28px'
      }
    }} open={!!showDeleteCredentialModal} onClose={(e, r) => {
      setShowDeleteCredentialModal(null);
    }}>
        <DialogTitle sx={{
        m: 0,
        p: 2,
        fontSize: 'medium'
      }}>
          <Stack spacing={'20px'}>
            <Box display="flex" flexDirection="row" justifyContent="space-between">
              <Stack direction="row" spacing={'10px'}>
                <Box sx={{
                display: 'flex',
                alignItems: 'center'
              }}>
                  <Typography sx={{
                  fontWeight: '300',
                  lineHeight: '30px',
                  fontSize: '24px',
                  display: 'flex',
                  alignItems: 'center'
                }} variant="h4" component="span">
                    Confirm Remove Credential
                  </Typography>
                </Box>
              </Stack>

              <IconButton onClick={() => {
              setShowDeleteCredentialModal(null);
            }}>
                <CloseIcon />
              </IconButton>
            </Box>
            <Divider />
          </Stack>
        </DialogTitle>

        <DialogContent sx={{
        scrollbarWidth: 'thin',
        '&::-webkit-scrollbar': {
          width: '0.4em'
        },
        '&::-webkit-scrollbar-track': {
          background: '#f1f1f1',
          marginTop: '20px',
          marginBottom: '20px'
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: '#888'
        },
        '&::-webkit-scrollbar-thumb:hover': {
          background: '#555'
        }
      }}>
          <Stack sx={{
          pt: 1
        }} spacing={'24px'}>
            Are you sure you want to remove this credential?
          </Stack>
        </DialogContent>

        <DialogActions sx={{
        pt: 1,
        pb: 2
      }}>
          <Stack sx={{
          pr: '24px'
        }} direction={'row'} spacing={'20px'}>
            <Button onClick={() => {
            setShowDeleteCredentialModal(null);
          }} variant="contained" sx={{
            backgroundColor: '#F3F3F4',
            '&:hover': {
              backgroundColor: '#F3F3F4'
            }
          }}>
              <Box sx={{
              py: '5px',
              px: '12px'
            }}>
                <Typography sx={{
                color: '#3898D9'
              }}>Cancel </Typography>
              </Box>
            </Button>
            <Button onClick={() => {
            handleDeleteCredential(showDeleteCredentialModal);
          }} variant="contained" sx={{
            backgroundColor: '#3898D9',
            '&:hover': {
              backgroundColor: '#3898D9'
            }
          }}>
              <Box sx={{
              py: '5px',
              px: '12px'
            }}>
                <Typography sx={{
                color: '#fff'
              }}> Remove</Typography>
              </Box>
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>

      <Dialog scroll="paper" PaperProps={{
      style: {
        borderRadius: '28px'
      }
    }} open={!!openConfirmDeleteInstallModal} onClose={(e, r) => {
      setOpenConfirmDeleteInstallModal(null);
    }}>
        <DialogTitle sx={{
        m: 0,
        p: 2,
        fontSize: 'medium'
      }}>
          <Stack spacing={'20px'}>
            <Box display="flex" flexDirection="row" justifyContent="space-between">
              <Stack direction="row" spacing={'10px'}>
                <Box sx={{
                display: 'flex',
                alignItems: 'center'
              }}>
                  <Typography sx={{
                  fontWeight: '300',
                  lineHeight: '30px',
                  fontSize: '24px',
                  display: 'flex',
                  alignItems: 'center'
                }} variant="h4" component="span">
                    {' Confirm Uninstall ' + openConfirmDeleteInstallModal?.firstname + ' ' + openConfirmDeleteInstallModal?.lastname + ' Facebook Integration'}
                  </Typography>
                </Box>
              </Stack>

              <IconButton onClick={() => {
              setOpenConfirmDeleteInstallModal(null);
            }}>
                <CloseIcon />
              </IconButton>
            </Box>
            <Divider />
          </Stack>
        </DialogTitle>

        <DialogContent sx={{
        scrollbarWidth: 'thin',
        '&::-webkit-scrollbar': {
          width: '0.4em'
        },
        '&::-webkit-scrollbar-track': {
          background: '#f1f1f1',
          marginTop: '20px',
          marginBottom: '20px'
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: '#888'
        },
        '&::-webkit-scrollbar-thumb:hover': {
          background: '#555'
        }
      }}>
          <Stack sx={{
          pt: 1
        }} spacing={'24px'}>
            <>
              {openConfirmDeleteInstallModal?.tokens?.length > 0 && <Stack spacing="16px">
                  <Box>
                    This facebook install has existing facebook credentials attached to it. If you uninstall this integration, these tokens
                    will also be deleted
                  </Box>
                  {hasMultiAccess && !user?.is_superuser && <Stack spacing="16px">
                      <Box>
                        Also by uninstalling, your facebook credentials will also be removed from all companies you have access to in Cinch
                      </Box>
                      {user?.companies?.map(company => <Box key={company.id}>{company.name}</Box>)}
                    </Stack>}
                </Stack>}
            </>
          </Stack>
        </DialogContent>

        <DialogActions sx={{
        pt: 1,
        pb: 2
      }}>
          <Stack sx={{
          pr: '24px'
        }} direction={'row'} spacing={'20px'}>
            <Button onClick={() => {
            setOpenConfirmDeleteInstallModal(null);
          }} variant="contained" sx={{
            backgroundColor: '#F3F3F4',
            '&:hover': {
              backgroundColor: '#F3F3F4'
            }
          }}>
              <Box sx={{
              py: '5px',
              px: '12px'
            }}>
                <Typography sx={{
                color: '#3898D9'
              }}>Cancel </Typography>
              </Box>
            </Button>
            <Button onClick={() => {
            handleDeleteInstall(openConfirmDeleteInstallModal);
          }} variant="contained" sx={{
            backgroundColor: '#3898D9',
            '&:hover': {
              backgroundColor: '#3898D9'
            }
          }}>
              <Box sx={{
              py: '5px',
              px: '12px'
            }}>
                <Typography sx={{
                color: '#fff'
              }}> Uninstall</Typography>
              </Box>
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>

      <Popper anchorEl={editExistingInstallMenuAnchorEl} open={!!editExistingInstallMenu} onClose={handleEditExistingInstallMenuClose} placement="right-start" sx={{
      zIndex: 200
    }}>
        <ClickAwayListener onClickAway={handleEditExistingInstallMenuClose}>
          <Paper elevation={0} sx={{
          borderRadius: '16px',
          overflow: 'visible',
          filter: 'drop-shadow(0px 0px 4px rgba(0,0,0,0.25))'
          // width: '312px',
        }}>
            <Stack>
              <Button sx={{
              width: '100%',
              height: '48px',
              borderRadius: '16px',
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              padding: '0 16px',
              ':hover': {
                backgroundColor: '#DAEFFF',
                color: '#53A6D6'
              }
            }} onClick={() => {
              setOpenConfirmDeleteInstallModal(editExistingInstallMenu);
            }}>
                <CancelIcon />
                <Typography sx={{
                textTransform: 'none',
                ml: '16px'
              }}>Uninstall</Typography>
              </Button>
            </Stack>
          </Paper>
        </ClickAwayListener>
      </Popper>
    </>;
};