import React, { useContext, useEffect } from 'react';
import { UserStateContext } from 'context/user-state-context';
import { deleteShopifyShop } from '../integrations.resource';
import { Icon } from '../../../components/icon/icon.component';
import { ModalDialog } from '../../../components/modal/modal-dialog.component';
import { history } from '../../../root.component';
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, InputAdornment, Stack, TextField, Tooltip, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import { CancelIcon, CloseIcon, HelpIcon, OpenInNewWindowIcon } from 'components/mui';
import { snackbarService } from 'components/mui/snackbar/snackbar-service';
export const IntegrationShopifyUpdate = props => {
  const {
    hasPermission
  } = useContext(UserStateContext);
  const [shops, setShops] = React.useState([]);
  const [deleteShop, setDeleteShop] = React.useState({});
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [isGettingStarted, setIsGettingStarted] = React.useState(false);
  const [companySelectArgs, setCompanySelectArgs] = React.useState(false);
  const [shopName, setShopName] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [firstStepsModal, setFirstStepsModal] = React.useState(false);
  const [openConfirmDeleteModal, setOpenConfirmDeleteModal] = React.useState(false);
  useEffect(() => {
    if (history?.location?.state?.urlPayload) {
      setFirstStepsModal(true);
      const newState = {
        ...history.location.state
      };
      delete newState.urlPayload;
      history.replace({
        ...history.location,
        state: newState
      });
    }
  }, []);
  const removeShop = () => {
    deleteShopifyShop(props?.credential?.id).then(() => {
      snackbarService.popup({
        type: 'success',
        message: 'Successfully deleted shop'
      });
    }).then(() => {
      history.push(`/integrations/manage/${props?.install.id}`);
    }).catch(() => {
      snackbarService.popup({
        type: 'error',
        message: 'Unknown error trying delete shop. Please try again.'
      });
    });
  };
  return <>
      <Stack>
        {props?.credential ? <Stack spacing="34px">
            <Box sx={{
          display: 'flex',
          alignItems: 'center'
        }}>
              <Typography sx={{
            fontSize: '18px',
            lineHeight: '26px'
          }}>Shop Info</Typography>
            </Box>
            <Typography>Name:{props?.credential?.name}</Typography>
            <Typography>Location: {props?.credential?.location_friendly_name}</Typography>
            <Typography>Shop Id: {props?.credential?.shop_id}</Typography>
            <Typography>Domain: {props?.credential?.domain}</Typography>

            {props?.credential?.version !== '2020-04' && <Button variant="contained" sx={{
          width: '200px',
          height: '46px'
        }} onClick={e => {
          window.open(`${API_URL}/shopify/shops/request?shop=${props?.credential?.domain}.myshopify.com`, '_blank');
        }}>
                Sync Store
              </Button>}

            <Button variant="contained" sx={{
          width: '200px',
          height: '46px'
        }} endIcon={<OpenInNewWindowIcon fill={'#fff'} />} onClick={() => {
          window.open(`https://apps.shopify.com/cinch-marketing`);
        }}>
              Shopify App Store
            </Button>

            <Stack>
              <Divider />
              <Box sx={{
            pr: '20px',
            pt: '20px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}>
                <Stack direction="row" spacing="16px">
                  {hasPermission('shopify.delete_shop') && <Button variant="contained" sx={{
                backgroundColor: '#F3F3F4',
                '&:hover': {
                  backgroundColor: '#F3F3F4'
                }
              }} startIcon={<CancelIcon fill="#3898D9" />} onClick={e => {
                setOpenConfirmDeleteModal(true);
              }}>
                      <Box sx={{
                  py: '5px',
                  pr: '12px'
                }}>
                        <Typography sx={{
                    color: '#3898D9'
                  }}>Remove </Typography>
                      </Box>
                    </Button>}
                </Stack>
                <Stack direction="row" spacing="16px">
                  <Button variant="contained" sx={{
                backgroundColor: '#F3F3F4',
                '&:hover': {
                  backgroundColor: '#F3F3F4'
                }
              }} onClick={e => {
                history.push(`/integrations/manage/${props?.install?.id}`);
              }}>
                    <Box sx={{
                  py: '5px',
                  px: '12px'
                }}>
                      <Typography sx={{
                    color: '#3898D9'
                  }}>Cancel </Typography>
                    </Box>
                  </Button>
                  <Button sx={{
                backgroundColor: '#3898D9',
                '&:hover': {
                  backgroundColor: '#3898D9'
                }
              }} variant="contained" onClick={e => {
                history.push(`/integrations/manage/${props?.install?.id}`);
              }}>
                    <Box sx={{
                  py: '6px',
                  px: '12px'
                }}>
                      <Typography sx={{
                    color: '#fff'
                  }}>Done</Typography>
                    </Box>
                  </Button>
                </Stack>
              </Box>
            </Stack>
          </Stack> : <Stack spacing="36px">
            <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}>
              <Box sx={{
            width: '60%'
          }}>
                {props?.install?.credentials?.length > 0 ? <Typography>To add another store, please click the button below to launch a new window.</Typography> : <Typography>
                    To install the Cinch App for your Shopify Store, please click the button below to launch a new window. This will take
                    you to the Shopify App Store where you can install the Cinch App.
                  </Typography>}
              </Box>
              <Button variant="contained" sx={{
            width: '200px',
            height: '46px'
          }} endIcon={<OpenInNewWindowIcon fill={'#fff'} />} onClick={() => {
            window.open(`https://apps.shopify.com/cinch-marketing`);
          }}>
                Shopify App Store
              </Button>
            </Box>
            <Divider />
            <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}>
              <Box sx={{
            width: '60%'
          }}>
                <Typography>
                  Once you have finished configuring this integration with Shopify, click the button to go back to the previous page and
                  select the account you just added.
                </Typography>
              </Box>
              <Button sx={{
            width: '250px'
          }} variant="contained" onClick={() => {
            history.push(`/integrations/manage/${props?.install?.id}`);
          }}>
                Go Back to Manage Page
              </Button>
            </Box>
          </Stack>}
      </Stack>

      <Dialog scroll="paper" PaperProps={{
      style: {
        borderRadius: '28px'
      }
    }} open={!!openConfirmDeleteModal} onClose={(e, r) => {
      setOpenConfirmDeleteModal(null);
    }}>
        <DialogTitle sx={{
        m: 0,
        p: 2,
        fontSize: 'medium'
      }}>
          <Stack spacing={'20px'}>
            <Box display="flex" flexDirection="row" justifyContent="space-between">
              <Stack direction="row" spacing={'10px'}>
                <Box sx={{
                display: 'flex',
                alignItems: 'center'
              }}>
                  <Typography sx={{
                  fontWeight: '300',
                  lineHeight: '30px',
                  fontSize: '24px',
                  display: 'flex',
                  alignItems: 'center'
                }} variant="h4" component="span">
                    Confirm Remove Shop
                  </Typography>
                </Box>
              </Stack>

              <IconButton onClick={() => {
              setOpenConfirmDeleteModal(null);
            }}>
                <CloseIcon />
              </IconButton>
            </Box>
            <Divider />
          </Stack>
        </DialogTitle>

        <DialogContent sx={{
        scrollbarWidth: 'thin',
        '&::-webkit-scrollbar': {
          width: '0.4em'
        },
        '&::-webkit-scrollbar-track': {
          background: '#f1f1f1',
          marginTop: '20px',
          marginBottom: '20px'
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: '#888'
        },
        '&::-webkit-scrollbar-thumb:hover': {
          background: '#555'
        }
      }}>
          <Stack sx={{
          pt: 1
        }} spacing={'24px'}>
            <Box>Are you sure you want to remove this credential? </Box>
          </Stack>
        </DialogContent>

        <DialogActions sx={{
        pt: 1,
        pb: 2
      }}>
          <Stack sx={{
          pr: '24px'
        }} direction={'row'} spacing={'20px'}>
            <Button onClick={() => {
            setOpenConfirmDeleteModal(false);
          }} variant="contained" sx={{
            backgroundColor: '#F3F3F4',
            '&:hover': {
              backgroundColor: '#F3F3F4'
            }
          }}>
              <Box sx={{
              py: '5px',
              px: '12px'
            }}>
                <Typography sx={{
                color: '#3898D9'
              }}>Cancel </Typography>
              </Box>
            </Button>
            <Button onClick={() => {
            removeShop();
            setOpenConfirmDeleteModal(null);
          }} variant="contained" sx={{
            backgroundColor: '#3898D9',
            '&:hover': {
              backgroundColor: '#3898D9'
            }
          }}>
              <Box sx={{
              py: '5px',
              px: '12px'
            }}>
                <Typography sx={{
                color: '#fff'
              }}> Remove</Typography>
              </Box>
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>

      <ModalDialog open={firstStepsModal} title="Getting Started" onClose={() => {
      setFirstStepsModal(false);
    }} allowBackdropClick onSubmit={() => {}} submitText="Done" size="lg">
        <p>
          Thank you for installing the Cinch App. Your data is currently syncing. While you wait for your data to finish syncing explore
          your marketing possibilities by:
        </p>

        <div className="d-flex">
          <div className="pr-5 pt-2">
            <Icon name="fa-solid-map-marker-alt" size={26} />
          </div>
          <div>
            <h3 className="text-uppercase">Creating Customer Segments</h3>
            <p>
              <a href="/segments">Customer Segments</a> allow you to target specific groups of customers. By targeting segments of customers
              you can effectively allocate marketing resources and maximize cross- and up-selling opportunities.
            </p>

            <p className="pb-3">
              Cinch allows you to create segments of customers based on various types of information you've collected from the customers.
              Segments can also be based on transaction history.
            </p>
          </div>
        </div>

        <div className="d-flex">
          <div className="pr-5 pt-2">
            <Icon name="fa-solid-code-branch" size={26} />
          </div>
          <div>
            <h3 className="text-uppercase">Creating Customer Journeys</h3>
            <p>
              <a href="/customer-journeys">Customer Journeys</a> allow you to automate your marketing efforts. Create drip campaigns that
              send emails after purchases. Add customers to Facebook Ad Audiences.
            </p>

            <p className="pb-3">Cinch allows you to create campaigns from simple to complex. The possibilities are endless.</p>
          </div>
        </div>

        <div className="d-flex">
          <div className="pr-5 pt-2">
            <Icon name="fa-solid-bullhorn" size={26} />
          </div>
          <div>
            <h3 className="text-uppercase">Creating Mailer And Email Templates</h3>
            <p>
              <a href="/templates">Mailer and Email Templates</a> allow you to not only communicate with your customers digitally but also
              by mail.
            </p>

            <p className="pb-3">
              Cinch allows you to create these templates right in your browser. Using advanced online editors you can create templates with
              dynamic data allowing you to personalize them as they are sent out.
            </p>
          </div>
        </div>
      </ModalDialog>
    </>;
};