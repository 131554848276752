import React, { useState, createRef, useRef, useEffect, useContext } from 'react';
import Form from '@rjsf/mui';
import validator from '@rjsf/validator-ajv8';
import { Box, Button, CircularProgress, Divider, Grid, IconButton, InputAdornment, Stack, TextField, Tooltip, Typography, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem } from '@mui/material';
import { UserStateContext } from 'context/user-state-context';
import { history } from '../../../root.component';
import { snackbarService } from 'components/mui/snackbar/snackbar-service';
import { createCredentials as createCredentialsAPI, updateCredential as updateCredentialsAPI, deleteCredential as deleteCredentialsAPI } from '../integrations.resource';
import { AddIcon, CancelIcon, CloseIcon, DeleteIcon, DuplicateIcon, EditIcon, HelpIcon, VisibilityIcon, VisibilityOffIcon } from 'components/mui';
import { CronMui } from './cron.component';
const TextWidget = props => {
  if (props.schema.format === 'cron-schedule') {
    return <CronMui {...props} />;
  }
  return CustomTextWidget(props);
};
const CustomTextWidget = props => {
  //hack for read only fields
  if (props?.schema?.customField == 'cinch_company_id') {
    return <TextField variant="filled" InputProps={{
      readOnly: true,
      endAdornment: <InputAdornment position="end">
              <Stack direction="row" spacing="5px">
                <Box sx={{
            display: 'flex',
            alignItems: 'center'
          }}>
                  <Tooltip title="Click to copy">
                    <IconButton onClick={e => {
                navigator.clipboard.writeText(props.formContext.asCompany.id);
                snackbarService.popup({
                  type: 'success',
                  message: 'Cinch Company Id copied to clipboard.'
                });
              }}>
                      <DuplicateIcon fill="#1D252D" />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Stack>
            </InputAdornment>
    }} sx={{
      width: '100%'
    }} type="text" value={props.formContext.asCompany.id} label={props.label} helperText={props?.schema?.description} />;
  }
  return <TextField sx={{
    width: '100%'
  }} helperText={props.rawErrors?.length > 0 ? props.rawErrors[0] : props?.schema?.description} type="text" className="custom" error={props.rawErrors?.length > 0} disabled={!props.formContext?.hasPermission} variant={!props?.formContext.hasPermission ? 'filled' : 'outlined'} value={props.value} required={props.required} label={props.label} onChange={event => {
    props.onChange(event.target.value);
  }} InputProps={{
    readOnly: !props.formContext?.hasPermission,
    endAdornment: !!props?.schema?.description_tooltip && <InputAdornment position="end">
            <Stack direction="row" spacing="5px">
              <Box sx={{
          display: 'flex',
          alignItems: 'center'
        }}>
                <Tooltip title={props?.schema?.description_tooltip}>
                  <IconButton>
                    <HelpIcon fill="#1D252D" />
                  </IconButton>
                </Tooltip>
              </Box>
            </Stack>
          </InputAdornment>
  }} />;
};
const CustomPasswordWidget = props => {
  //NEW PROTECTED FIELD
  if (props?.formContext?.isNew) {
    return <Stack direction="row" spacing="16px">
        <TextField onChange={event => {
        props.onChange(event.target.value);
      }} sx={{
        width: '100%'
      }} helperText={props.rawErrors?.length > 0 ? props.rawErrors[0] : props?.schema?.description} className="custom" error={props.rawErrors?.length > 0} value={props.value} required={props.required} label={props.label} disabled={!props?.formContext?.hasPermission} variant={!props?.formContext.hasPermission ? 'filled' : 'outlined'} type={props?.formContext?.showPassword.includes(props.name) ? 'text' : 'password'} autoComplete="new-password" InputProps={{
        sx: {
          borderRadius: '16px',
          border: 'none'
        },
        readOnly: !props?.formContext.hasPermission,
        endAdornment: props?.formContext.hasPermission && <InputAdornment position="end">
                <Stack direction="row" spacing="5px">
                  <Box sx={{
              display: 'flex',
              alignItems: 'center'
            }}>
                    <Tooltip title="Toggle Visibility">
                      <IconButton onClick={e => {
                  props.onChange(props.value, props.id, 'showPassword-' + props.name);
                }}>
                        {props?.formContext?.showPassword.includes(props.name) ? <VisibilityOffIcon /> : <VisibilityIcon />}
                      </IconButton>
                    </Tooltip>
                  </Box>
                </Stack>
              </InputAdornment>
      }} />
      </Stack>;
  }

  //EXISTING CREDENTIALS EDIT

  return <Stack direction="row" spacing="16px">
      <TextField onChange={event => {
      props.onChange(event.target.value);
    }} sx={{
      width: '100%'
    }} helperText={props.rawErrors?.length > 0 ? props.rawErrors[0] : props?.schema?.description} className="custom" error={props.rawErrors?.length > 0} value={props.value} required={props.required} label={props.label} type={props?.formContext?.protectedDataEditing.includes(props.name) && props?.formContext?.showPassword.includes(props.name) ? 'text' : 'password'} variant={!props?.formContext.hasPermission || !props?.formContext?.protectedDataEditing.includes(props.name) ? 'filled' : 'outlined'} autoComplete="new-password" disabled={!props?.formContext?.protectedDataEditing.includes(props.name) || !props?.formContext.hasPermission} InputProps={{
      sx: {
        borderRadius: '16px',
        border: 'none'
      },
      readOnly: !props?.formContext?.protectedDataEditing.includes(props.name) || !props?.formContext.hasPermission,
      endAdornment: props?.formContext.hasPermission && <>
              {!props?.formContext?.protectedDataEditing.includes(props.name) ? <InputAdornment position="end">
                  <Stack direction="row" spacing="5px">
                    <Box sx={{
              display: 'flex',
              alignItems: 'center'
            }}>
                      <Tooltip title="Toggle Edit">
                        <IconButton onClick={e => {
                  props.onChange(props.value, props.id, 'showEditProtectedDataModal-' + props.name);
                }}>
                          <EditIcon fill="#1D252D" />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </Stack>
                </InputAdornment> : <InputAdornment position="end">
                  <Stack direction="row" spacing="5px">
                    <Box sx={{
              display: 'flex',
              alignItems: 'center'
            }}>
                      <Tooltip title="Toggle Visibilty">
                        <IconButton onClick={e => {
                  props.onChange(props.value, props.id, 'showPassword-' + props.name);
                }}>
                          {props?.formContext?.showPassword.includes(props.name) ? <VisibilityOffIcon /> : <VisibilityIcon />}
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </Stack>
                </InputAdornment>}
            </>
    }} />
    </Stack>;
};

/* Had to override this as the default option
   was unfortunately not being full width by default. */
const CustomSelectWidget = props => {
  return <TextField style={{
    width: '100%'
  }} select label={props.label} value={props.value} onChange={event => props.onChange(event.target.value)} helperText={props.rawErrors?.length > 0 ? props.rawErrors[0] : props?.schema?.description}>
      {props.options.enumOptions.map(option => {
      return <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>;
    })}
    </TextField>;
};
const FieldTemplate = props => {
  const {
    id,
    classNames,
    label,
    help,
    required,
    rawDescription,
    children,
    rawErrors,
    schema
  } = props;
  const description = id !== 'root' && !rawErrors && !!rawDescription ? <p>{rawDescription}</p> : null;
  const errors = !!rawErrors ? rawErrors.map((error, index) => <p key={index}>
        <small>{error}</small>
      </p>) : null;
  return <div>
      {schema.type == 'array' && <>
          {errors}
          {children}
        </>}
      {schema.type != 'array' && <>
          {schema.format === 'cron-schedule' && description}
          {schema.format === 'cron-schedule' && errors}
          {children}
        </>}

      {help}
    </div>;
};
function ArrayFieldTemplate(props) {
  return <Stack spacing="16px">
      {props.schema.title && <Typography>{props.schema.title}</Typography>}

      {props.items.map((element, i) => {
      element.hasRemove = true;
      return <Stack sx={{
        display: 'flex',
        alignItems: 'center'
      }} direction="row" spacing="16px" key={element.key}>
            <Box sx={{
          width: '100%'
        }}> {element.children} </Box>
            <Box sx={{
          position: 'relative'
        }}>
              <Box sx={{
            height: '40px',
            width: '40px',
            display: 'flex',
            alignItems: 'center'
          }}>
                <IconButton onClick={element.onDropIndexClick(i)}>
                  <CancelIcon fill="#1D252D" />
                </IconButton>
              </Box>
            </Box>
          </Stack>;
    })}
      {props.schema.description && <Typography>{props.schema.description}</Typography>}
      {props.canAdd && <Button startIcon={<AddIcon fill="#3898D9" />} sx={{
      width: '150px',
      backgroundColor: '#F3F3F4',
      '&:hover': {
        backgroundColor: '#F3F3F4'
      }
    }} variant="contained" onClick={props.onAddClick}>
          <Box sx={{
        py: '5px',
        pr: '12px'
      }}>
            <Typography sx={{
          color: '#3898D9'
        }}>Add new </Typography>
          </Box>
        </Button>}
    </Stack>;
}
function ObjectFieldTemplate(props) {
  const {
    description,
    title,
    properties,
    required,
    disabled,
    readonly,
    uiSchema,
    idSchema,
    schema,
    formData,
    onAddClick,
    registry
  } = props;
  return <>
      {title}
      {description}
      <Grid container={true} spacing={2} style={{
      marginTop: '0px'
    }}>
        {properties.map((element, index) =>
      // Remove the <Grid> if the inner element is hidden as the <Grid>
      // itself would otherwise still take up space.
      element.hidden ? element.content : <Grid item={true} xs={6} key={index} style={{
        marginBottom: '0px'
      }}>
              {element.content}
            </Grid>)}
      </Grid>
    </>;
}
function transformErrors(errors) {
  return errors.map(error => {
    if (error.name === 'required') {
      error.message = 'Field is required';
    }
    return error;
  });
}
const MODAL_PROPS = ['open', 'title', 'onClose', 'allowBackdropClick'];
export const IntegrationSchemaForm = props => {
  const {
    asCompany,
    hasPermission
  } = useContext(UserStateContext);
  const [saving, setSaving] = useState(false);
  const formData = useRef(props.formData || {});
  const [haveMadeChanges, setHaveMadeChanges] = useState(false);
  const [unsavedChangesModalOpen, setUnsavedChangesModalOpen] = useState(false);
  const [openConfirmDeleteModal, setOpenConfirmDeleteModal] = useState(null);
  const formRef = createRef();
  const [showPassword, setShowPassword] = useState([]);
  const [openConfirmProtectedEditModal, setOpenConfirmProtectedEditModal] = useState(null);
  const [protectedDataEditing, setProtectedDataEditing] = useState([]);
  const modalProps = {};
  MODAL_PROPS.forEach(prop => {
    if (props[prop] !== undefined) {
      let val;
      ({
        [prop]: val,
        ...props
      } = props);
      modalProps[prop] = val;
    }
  });

  //props.schema = JSON.parse(JSON.stringify(props.schema));
  if (props.schema.title) {
    delete props.schema.title;
  }
  if (props.schema.description) {
    delete props.schema.description;
  }
  const formSubmit = response => {
    // TODO is it possible to prevent the form from clearing on submit?

    setSaving(true);
    if (props?.credential) {
      //update credential
      const sanitized_entity_data = {
        ...response?.formData
      };
      for (const key in sanitized_entity_data) {
        if (props?.install?.integration?.credential_schema?.properties[key]?.format == 'password' && !protectedDataEditing.includes(key)) {
          delete sanitized_entity_data[key];
        }
      }
      updateCredentialsAPI(props?.credential.id, {
        entity_data: sanitized_entity_data
      }).then(updatedCredential => {
        onSucessfulSubmit(updatedCredential);
      }).catch(err => {
        if (err.response.data.non_field_errors) {
          snackbarService.popup({
            type: 'error',
            message: err.response.data.non_field_errors[0]
          });
        } else {
          snackbarService.popup({
            type: 'error',
            message: 'There was an error trying to update the credential.'
          });
        }
        throw err;
      });
    } else {
      //new credential
      createCredentialsAPI({
        entity_data: response?.formData,
        company: asCompany.id,
        integration: props?.install.integration.key
      }).then(newCredential => {
        onSucessfulSubmit(newCredential);
      }).catch(err => {
        setSaving(false);
        if (err.response.data.non_field_errors) {
          snackbarService.popup({
            type: 'error',
            message: err.response.data.non_field_errors[0]
          });
        } else {
          snackbarService.popup({
            type: 'error',
            message: 'There was an error saving the credential.'
          });
        }
        throw err;
      });
    }
  };
  const onSucessfulSubmit = credential => {
    setSaving(false);
    if (!props?.credential) {
      if (props?.install?.integration?.key == 'autoops') {
        history.push(`/integrations/autoops`, {
          sneakyPayload: {
            credentialId: credential.id,
            installId: props?.install.id
          }
        });
      } else {
        history.push(`/integrations/manage/${props?.install.id}`, {
          sucessfulCredential: 'New Credential sucessfully added. Syncing will begin soon'
        });
      }
    } else {
      history.push(`/integrations/manage/${props?.install.id}`, {
        sucessfulCredential: 'Credential sucessfully edited. Syncing will begin soon with these new credentials'
      });
    }
  };
  const onError = errors => {
    console.log('is error', errors);
  };
  function customValidate(formData, errors, uiSchema) {
    props.schema.required.map(requiredField => {
      if (!formData[requiredField] || formData[requiredField] === '') {
        errors[requiredField].addError('Field is required');
      }
    });
    return errors;
  }
  const handleDeleteCredential = () => {
    deleteCredentialsAPI(props?.credential.id).then(response => {
      history.push(`/integrations/manage/${props?.install?.id}`, {
        removedCredential: props?.install?.integration_name
      });
    }).catch(error => {
      snackbarService.popup({
        type: 'error',
        message: 'There was an error removing the credential.'
      });
    });
  };
  const handleProtectedDataEdit = payload => {
    //clear out the protected fields

    const properties = props.schema.properties;
    for (const key in properties) {
      if (properties[key].format == 'password' && key == payload) {
        formData.current[key] = '';
      }
    }
    formRef.current.setState({
      formData: formData.current
    });
    setHaveMadeChanges(true);
    setProtectedDataEditing([...protectedDataEditing, payload]);
    setOpenConfirmProtectedEditModal(null);
  };
  return <>
      <div>
        <div>
          <div className="modal__body">
            <Stack>
              <Box sx={{
              pb: '16px'
            }}>
                <Typography sx={{
                fontSize: '18px',
                fontWeight: '300',
                lineHeight: '26px'
              }}>Configuration</Typography>
              </Box>
              <Box sx={{
              pb: '16px'
            }}>
                <Form disabled={!hasPermission('integration.change_credential')} validator={validator} ref={formRef} onError={onError} id="schema-form" templates={{
                FieldTemplate: FieldTemplate,
                ArrayFieldTemplate: ArrayFieldTemplate,
                ObjectFieldTemplate: ObjectFieldTemplate
              }} schema={JSON.parse(JSON.stringify(props.schema))} widgets={{
                TextWidget: TextWidget,
                PasswordWidget: CustomPasswordWidget,
                SelectWidget: CustomSelectWidget
              }} transformErrors={transformErrors} children={true} showErrorList={true} customValidate={customValidate} {...props} formData={formData.current} onChange={(event, arg2, arg3) => {
                if (!hasPermission('integration.change_credential')) {
                  return;
                }
                setHaveMadeChanges(true);
                if (arg2?.includes('showEditProtectedDataModal')) {
                  if (protectedDataEditing.length > 0) {
                    handleProtectedDataEdit(arg2.split('-')[1]);
                  } else {
                    setOpenConfirmProtectedEditModal(arg2.split('-')[1]);
                  }
                  return;
                }
                if (arg2?.includes('showPassword')) {
                  if (showPassword.includes(arg2.split('-')[1])) {
                    setShowPassword(showPassword.filter(item => item !== arg2.split('-')[1]));
                  } else {
                    setShowPassword([...showPassword, arg2.split('-')[1]]);
                  }
                  return;
                }
                formData.current = event.formData;
              }} onSubmit={formSubmit} formContext={{
                isNew: !props?.credential,
                showPassword: showPassword,
                asCompany: asCompany,
                protectedDataEditing: protectedDataEditing,
                hasPermission: hasPermission('integration.change_credential')
              }} />
              </Box>
            </Stack>
          </div>

          <Stack>
            <Divider />
            <Box sx={{
            pt: '16px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}>
              <Stack direction="row" spacing="16px">
                {hasPermission('integration.change_credential') && props?.credential && <Button sx={{
                backgroundColor: '#F3F3F4',
                '&:hover': {
                  backgroundColor: '#F3F3F4'
                }
              }} variant="contained" startIcon={<CancelIcon fill="#3898D9" />} onClick={e => {
                setOpenConfirmDeleteModal(true);
              }}>
                    <Box sx={{
                  py: '5px',
                  pr: '12px'
                }}>
                      <Typography sx={{
                    color: '#3898D9'
                  }}>Remove </Typography>
                    </Box>
                  </Button>}
              </Stack>
              <Stack direction="row" spacing="16px">
                <Button sx={{
                backgroundColor: '#F3F3F4',
                '&:hover': {
                  backgroundColor: '#F3F3F4'
                }
              }} onClick={e => {
                if (haveMadeChanges) {
                  setUnsavedChangesModalOpen(true);
                } else {
                  history.push(`/integrations/manage/${props?.install?.id}`);
                }
              }} variant="contained">
                  <Box sx={{
                  py: '5px',
                  px: '12px'
                }}>
                    <Typography sx={{
                    color: '#3898D9'
                  }}>Cancel </Typography>
                  </Box>
                </Button>
                <Button sx={{
                backgroundColor: '#3898D9',
                '&:hover': {
                  backgroundColor: '#3898D9'
                }
              }} disabled={saving || !haveMadeChanges} variant="contained" endIcon={saving && <CircularProgress sx={{
                color: '#FFF'
              }} size={'20px'} />} onClick={e => {
                if (!saving && formRef.current.validateForm()) {
                  formRef.current.submit();
                }
              }}>
                  <Box sx={{
                  py: '5px',
                  px: '12px'
                }}>
                    <Typography sx={{
                    color: '#fff'
                  }}> {saving ? 'Working...' : 'Submit'}</Typography>
                  </Box>
                </Button>
              </Stack>
            </Box>
          </Stack>
        </div>
      </div>

      <Dialog scroll="paper" PaperProps={{
      style: {
        borderRadius: '28px'
      }
    }} open={!!unsavedChangesModalOpen} onClose={(e, r) => {
      setUnsavedChangesModalOpen(null);
    }}>
        <DialogTitle sx={{
        m: 0,
        p: 2,
        fontSize: 'medium'
      }}>
          <Stack spacing={'20px'}>
            <Box display="flex" flexDirection="row" justifyContent="space-between">
              <Stack direction="row" spacing={'10px'}>
                <Box sx={{
                display: 'flex',
                alignItems: 'center'
              }}>
                  <Typography sx={{
                  fontWeight: '300',
                  lineHeight: '30px',
                  fontSize: '24px',
                  display: 'flex',
                  alignItems: 'center'
                }} variant="h4" component="span">
                    Unsaved Changes
                  </Typography>
                </Box>
              </Stack>

              <IconButton onClick={() => {
              setUnsavedChangesModalOpen(null);
            }}>
                <CloseIcon />
              </IconButton>
            </Box>
            <Divider />
          </Stack>
        </DialogTitle>

        <DialogContent sx={{
        scrollbarWidth: 'thin',
        '&::-webkit-scrollbar': {
          width: '0.4em'
        },
        '&::-webkit-scrollbar-track': {
          background: '#f1f1f1',
          marginTop: '20px',
          marginBottom: '20px'
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: '#888'
        },
        '&::-webkit-scrollbar-thumb:hover': {
          background: '#555'
        }
      }}>
          <Stack sx={{
          pt: 1
        }} spacing={'24px'}>
            <Box>You have unsaved changes. Do you want to discard them? </Box>
          </Stack>
        </DialogContent>

        <DialogActions sx={{
        pt: 1,
        pb: 2
      }}>
          <Stack sx={{
          pr: '24px'
        }} direction={'row'} spacing={'20px'}>
            <Button onClick={() => {
            setUnsavedChangesModalOpen(false);
          }} variant="contained" sx={{
            backgroundColor: '#F3F3F4',
            '&:hover': {
              backgroundColor: '#F3F3F4'
            }
          }}>
              <Box sx={{
              py: '5px',
              px: '12px'
            }}>
                <Typography sx={{
                color: '#3898D9'
              }}>Cancel </Typography>
              </Box>
            </Button>
            <Button onClick={() => {
            history.push(`/integrations/manage/${props?.install?.id}`);
          }} variant="contained" sx={{
            backgroundColor: '#3898D9',
            '&:hover': {
              backgroundColor: '#3898D9'
            }
          }}>
              <Box sx={{
              py: '5px',
              px: '12px'
            }}>
                <Typography sx={{
                color: '#fff'
              }}> Discard</Typography>
              </Box>
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>

      <Dialog scroll="paper" PaperProps={{
      style: {
        borderRadius: '28px'
      }
    }} open={!!openConfirmDeleteModal} onClose={(e, r) => {
      setOpenConfirmDeleteModal(null);
    }}>
        <DialogTitle sx={{
        m: 0,
        p: 2,
        fontSize: 'medium'
      }}>
          <Stack spacing={'20px'}>
            <Box display="flex" flexDirection="row" justifyContent="space-between">
              <Stack direction="row" spacing={'10px'}>
                <Box sx={{
                display: 'flex',
                alignItems: 'center'
              }}>
                  <Typography sx={{
                  fontWeight: '300',
                  lineHeight: '30px',
                  fontSize: '24px',
                  display: 'flex',
                  alignItems: 'center'
                }} variant="h4" component="span">
                    Confirm Remove Credential
                  </Typography>
                </Box>
              </Stack>

              <IconButton onClick={() => {
              setOpenConfirmDeleteModal(null);
            }}>
                <CloseIcon />
              </IconButton>
            </Box>
            <Divider />
          </Stack>
        </DialogTitle>

        <DialogContent sx={{
        scrollbarWidth: 'thin',
        '&::-webkit-scrollbar': {
          width: '0.4em'
        },
        '&::-webkit-scrollbar-track': {
          background: '#f1f1f1',
          marginTop: '20px',
          marginBottom: '20px'
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: '#888'
        },
        '&::-webkit-scrollbar-thumb:hover': {
          background: '#555'
        }
      }}>
          <Stack sx={{
          pt: 1
        }} spacing={'24px'}>
            <Box>Are you sure you want to remove this credential? </Box>
          </Stack>
        </DialogContent>

        <DialogActions sx={{
        pt: 1,
        pb: 2
      }}>
          <Stack sx={{
          pr: '24px'
        }} direction={'row'} spacing={'20px'}>
            <Button onClick={() => {
            setOpenConfirmDeleteModal(false);
          }} variant="contained" sx={{
            backgroundColor: '#F3F3F4',
            '&:hover': {
              backgroundColor: '#F3F3F4'
            }
          }}>
              <Box sx={{
              py: '5px',
              px: '12px'
            }}>
                <Typography sx={{
                color: '#3898D9'
              }}>Cancel </Typography>
              </Box>
            </Button>
            <Button onClick={() => {
            handleDeleteCredential();
            setOpenConfirmDeleteModal(null);
          }} variant="contained" sx={{
            backgroundColor: '#3898D9',
            '&:hover': {
              backgroundColor: '#3898D9'
            }
          }}>
              <Box sx={{
              py: '5px',
              px: '12px'
            }}>
                <Typography sx={{
                color: '#fff'
              }}> Remove</Typography>
              </Box>
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>

      <Dialog scroll="paper" PaperProps={{
      style: {
        borderRadius: '28px'
      }
    }} open={!!openConfirmProtectedEditModal} onClose={(e, r) => {
      setOpenConfirmProtectedEditModal(null);
    }}>
        <DialogTitle sx={{
        m: 0,
        p: 2,
        fontSize: 'medium'
      }}>
          <Stack spacing={'20px'}>
            <Box display="flex" flexDirection="row" justifyContent="space-between">
              <Stack direction="row" spacing={'10px'}>
                <Box sx={{
                display: 'flex',
                alignItems: 'center'
              }}>
                  <Typography sx={{
                  fontWeight: '300',
                  lineHeight: '30px',
                  fontSize: '24px',
                  display: 'flex',
                  alignItems: 'center'
                }} variant="h4" component="span">
                    Confirm Protected Data Edit
                  </Typography>
                </Box>
              </Stack>

              <IconButton onClick={() => {
              setOpenConfirmProtectedEditModal(null);
            }}>
                <CloseIcon />
              </IconButton>
            </Box>
            <Divider />
          </Stack>
        </DialogTitle>

        <DialogContent sx={{
        scrollbarWidth: 'thin',
        '&::-webkit-scrollbar': {
          width: '0.4em'
        },
        '&::-webkit-scrollbar-track': {
          background: '#f1f1f1',
          marginTop: '20px',
          marginBottom: '20px'
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: '#888'
        },
        '&::-webkit-scrollbar-thumb:hover': {
          background: '#555'
        }
      }}>
          <Stack sx={{
          pt: 1
        }} spacing={'24px'}>
            <Box>
              Are you sure you want to edit this protected data? This action will clear the field and you will have to fully re-enter the
              data.
            </Box>
          </Stack>
        </DialogContent>

        <DialogActions sx={{
        pt: 1,
        pb: 2
      }}>
          <Stack sx={{
          pr: '24px'
        }} direction={'row'} spacing={'20px'}>
            <Button onClick={() => {
            setOpenConfirmProtectedEditModal(null);
          }} variant="contained" sx={{
            backgroundColor: '#F3F3F4',
            '&:hover': {
              backgroundColor: '#F3F3F4'
            }
          }}>
              <Box sx={{
              py: '5px',
              px: '12px'
            }}>
                <Typography sx={{
                color: '#3898D9'
              }}>Cancel </Typography>
              </Box>
            </Button>
            <Button onClick={() => {
            handleProtectedDataEdit(openConfirmProtectedEditModal);
          }} variant="contained" sx={{
            backgroundColor: '#3898D9',
            '&:hover': {
              backgroundColor: '#3898D9'
            }
          }}>
              <Box sx={{
              py: '5px',
              px: '12px'
            }}>
                <Typography sx={{
                color: '#fff'
              }}> Allow Editing</Typography>
              </Box>
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>
    </>;
};