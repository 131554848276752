import React, { useEffect, useState } from 'react';
import { Box, Button, Divider, IconButton, Modal, Stack, Table, TableBody, TableCell, TableRow, Tooltip, Typography } from '@mui/material';
import { AddCircleIcon, ArrowCooldownIcon, ArrowForwardIcon, Calendar30DaysIcon, CustomerIcon, MapIcon, RemoveCircleIcon, ReviewIcon, ShoppingCartIcon, TransactionIcon, UpwardArrowIcon, VehicleIcon } from 'components/mui';
export const ExportToCSV = props => {
  const {
    handleClose = () => {},
    loading = false,
    showExportModal = false,
    selectedFields: initialSelectedFields = [],
    model = ''
  } = props;
  const [exportModalOpen, setExportModalOpen] = useState(showExportModal);
  const [availableFields, setAvailableFields] = useState(props.availableFields || []);
  //   const [selectedFields, setSelectedFields] = useState(props.selectedFields || []); // prepopulate selected fields
  const [selectedFields, setSelectedFields] = useState([]);
  const makeOverflowTooltip = (content, length, bold) => {
    const contentLength = content?.length || content?.props?.children?.length;
    if (content) {
      return <Tooltip title={contentLength > length ? <> {content} </> : ''}>
          <Typography variant={bold ? 'tableHeader' : ''}>
            {contentLength > length ? <>{('' + (content.props?.children || content)).slice(0, length) + '...'}</> : <>{content}</>}
          </Typography>
        </Tooltip>;
    }
    return <Typography variant="nullData" color={'#BEBEBE'}>
        <span>--</span>{' '}
      </Typography>;
  };
  const handleRemoveFromAvailableFields = field => {
    setAvailableFields(availableFields.filter(f => f.id !== field.id));
    setSelectedFields([...selectedFields, field]);
  };
  const handleRemoveFromSelectedFields = field => {
    setSelectedFields(selectedFields.filter(f => f.id !== field.id));
    setAvailableFields([...availableFields, field]);
  };
  const handleCloseModal = selectedFields => {
    let selectedFieldsLabels = [];
    if (!selectedFields || selectedFields.length === 0) {
      setExportModalOpen(false);
      handleClose();
      return;
    }
    selectedFieldsLabels = selectedFields.map(field => field.id);
    if (!selectedFieldsLabels.includes('id')) {
      selectedFieldsLabels.unshift('id');
    }
    setExportModalOpen(false);
    handleClose(selectedFieldsLabels);
  };

  // const sortFields = (field, orderType) => {
  //   if (orderType === 'asc') {
  //   if (field === 'availableFields') {
  //     const sortedFields = availableFields.sort((a, b) => (a.name || a.label).localeCompare(b.name || b.label));
  //     setSortAvailableFields(!sortAvailableFields);
  //     setAvailableFields([...sortedFields]);
  //   } else {
  //     const sortedFields = selectedFields.sort((a, b) => (a.name || a.label).localeCompare(b.name || b.label));
  //     setSortSelectedFields(!sortSelectedFields);
  //     setSelectedFields([...sortedFields]);
  //   }
  // } else if (orderType === 'desc') {
  //   if (field === 'availableFields') {
  //   setAvailableFields([...availableFields].reverse());
  // } else {
  //   setSelectedFields([...selectedFields].reverse());
  // }
  // } else if (orderType === 'editFields') {
  //   // set the order of the fields based on the edit fields first, then the rest in order afterwards
  // }
  // };

  useEffect(() => {
    setExportModalOpen(showExportModal);
  }, [showExportModal]);
  return <Modal id="export-segment-modal" open={exportModalOpen} onClose={() => {
    handleCloseModal();
  }}>
      <Box sx={{
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      height: '655px',
      width: '660px',
      bgcolor: 'background.paper',
      boxShadow: 24,
      p: '24px',
      borderRadius: '28px',
      borderBottom: '1px solid #E2E2E2'
    }}>
        <Box sx={{
        p: '0px 8px 20px 14px'
      }}>
          <Typography sx={{
          fontSize: '24px',
          fontWeight: '400',
          fontStyle: 'normal'
        }}>
            Export to CSV
          </Typography>
        </Box>

        <Box sx={{
        p: 0,
        marginLeft: '-24px',
        marginRight: '-24px'
      }}>
          <Divider />
        </Box>

        {loading || !availableFields ? <Box sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}>
            <Typography>Loading...</Typography>
          </Box> : <>
            <Stack id="header-container" direction="row" sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          pt: '15px'
        }}>
              <Box sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            // pl: '33px', // if we add sort, then use this for correct padding
            borderTop: '1px solid #E2E2E2',
            borderLeft: '1px solid #E2E2E2',
            borderRight: '1px solid #E2E2E2',
            borderTopLeftRadius: '16px',
            borderTopRightRadius: '16px',
            borderBottom: '1px solid #fff',
            height: '40px',
            width: '255px'
          }}>
                <Typography sx={{
              fontSize: '20px',
              fontWeight: '400',
              fontStyle: 'normal'
            }}>
                  Available Fields
                </Typography>
                <Tooltip title="Select All">
                  <IconButton onClick={() => {
                setAvailableFields([]);
                setSelectedFields([...selectedFields, ...availableFields]);
              }}>
                    <AddCircleIcon />
                  </IconButton>
                </Tooltip>

                {/* <Tooltip title="Sort by name">
                  <IconButton
                    //  onClick sortFields, but if clicked again then reverse
                    onClick={() => {
                      // sortFields('availableFields', asc);
                      setAvailableFields([...availableFields].reverse());
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        transform: `scaleY(${availableFields ? -1 : 1})`,
                        height: '14px',
                        width: '14px',
                      }}
                    >
                      <UpwardArrowIcon />
                    </Box>
                  </IconButton>
                 </Tooltip> */}
              </Box>

              <Box sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            // pl: '15px', // if we add sort, then use this for correct padding
            borderTop: '1px solid #E2E2E2',
            borderLeft: '1px solid #E2E2E2',
            borderRight: '1px solid #E2E2E2',
            borderTopLeftRadius: '16px',
            borderTopRightRadius: '16px',
            borderBottom: '1px solid #fff',
            height: '40px',
            width: '255px'
          }}>
                <Typography sx={{
              fontSize: '20px',
              fontWeight: '400',
              fontStyle: 'normal'
            }}>
                  Selected Fields
                </Typography>
                <Tooltip title="Remove All">
                  <IconButton onClick={() => {
                setAvailableFields([...availableFields, ...selectedFields]);
                setSelectedFields([]);
              }}>
                    <RemoveCircleIcon />
                  </IconButton>
                </Tooltip>

                {/* <Tooltip title="Sort by name">
                  <IconButton
                    onClick={() => {
                      // sortFields('selectedFields', 'asc');
                      setSelectedFields([...selectedFields].reverse());
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        transform: `scaleY(${selectedFields ? -1 : 1})`,
                        height: '14px',
                        width: '14px',
                      }}
                    >
                      <UpwardArrowIcon />
                    </Box>
                  </IconButton>
                 </Tooltip> */}
              </Box>
            </Stack>

            <Stack id="all-fields-container" direction="row" sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          pb: '20px'
        }}>
              <Box id="available-fields-container" sx={{
            borderTop: '1px solid #fff',
            border: '1px solid #E2E2E2',
            borderRadius: '16px',
            borderTopLeftRadius: '0px',
            borderTopRightRadius: '0px',
            height: '445px',
            overflowY: 'scroll'
          }}>
                <Box>
                  <Table id="table-available-fields" sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '239px',
                height: '100%'
              }}>
                    <TableBody>
                      {availableFields.map(field => <TableRow sx={{
                    '& td, & th': {
                      borderBottom: 'none'
                    },
                    '&:hover': {
                      backgroundColor: 'rgba(0, 0, 0, 0.04)'
                    },
                    '&:active': {
                      backgroundColor: 'rgba(0, 0, 0, 0.08)'
                    },
                    cursor: 'pointer'
                  }} key={field.id} onClick={e => {
                    if (selectedFields.includes(field.key)) {
                      return;
                    }
                    handleRemoveFromAvailableFields(field);
                  }}>
                          <TableCell sx={{
                      p: '4px',
                      '&:cursor': {
                        cursor: 'pointer'
                      }
                    }}>
                            <Stack direction="row">
                              <Box sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          pl: '10px',
                          pr: '5px'
                        }}>
                                {model === 'vehicle' ? <VehicleIcon /> : model === 'transaction' ? <TransactionIcon /> : model === 'schedule' ? <Calendar30DaysIcon /> : model === 'cart' ? <ShoppingCartIcon /> : model === 'location' ? <MapIcon /> : model === 'review' ? <ReviewIcon /> : <CustomerIcon />}
                              </Box>
                              <Box sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          p: '8px',
                          '&:cursor': {
                            cursor: 'pointer'
                          }
                        }}>
                                {makeOverflowTooltip(field.name || field.label, 22)}
                              </Box>
                            </Stack>
                          </TableCell>
                        </TableRow>)}
                    </TableBody>
                  </Table>
                </Box>
              </Box>
              <Box sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            px: '15px'
          }}>
                <ArrowCooldownIcon />
              </Box>

              <Box id="selected-fields-container" sx={{
            borderTop: '1px solid #fff',
            border: '1px solid #E2E2E2',
            borderRadius: '16px',
            borderTopLeftRadius: '0px',
            borderTopRightRadius: '0px',
            height: '445px',
            overflowY: 'scroll'
          }}>
                <Box>
                  <Table id="table-selected-fields" sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                width: '239px',
                height: '100%'
              }}>
                    <TableBody>
                      {selectedFields.map(field => <TableRow sx={{
                    '& td, & th': {
                      borderBottom: 'none'
                    },
                    '&:hover': {
                      backgroundColor: 'rgba(0, 0, 0, 0.04)'
                    },
                    '&:active': {
                      backgroundColor: 'rgba(0, 0, 0, 0.08)'
                    },
                    cursor: 'pointer'
                  }} key={field.id} onClick={e => {
                    handleRemoveFromSelectedFields(field);
                  }}>
                          <TableCell sx={{
                      p: '4px',
                      cursor: 'pointer',
                      width: '300px'
                    }}>
                            <Stack direction="row">
                              <Box sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          pl: '10px',
                          pr: '5px'
                        }}>
                                {model === 'possession' ? <VehicleIcon /> : model === 'transaction' ? <TransactionIcon /> : model === 'schedule' ? <Calendar30DaysIcon /> : model === 'cart' ? <ShoppingCartIcon /> : model === 'location' ? <MapIcon /> : model === 'review' ? <ReviewIcon /> : <CustomerIcon />}
                              </Box>
                              <Box sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          p: '8px'
                        }}>
                                {makeOverflowTooltip(field.label || field.name, 22)}
                              </Box>
                            </Stack>
                          </TableCell>
                        </TableRow>)}
                    </TableBody>
                  </Table>
                </Box>
              </Box>
            </Stack>

            <Box sx={{
          p: 0,
          marginLeft: '-24px',
          marginRight: '-24px'
        }}>
              <Divider />
            </Box>

            <Stack sx={{
          pt: '25px'
        }}>
              <Box sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end'
          }}>
                <Button variant="text" sx={{
              textTransform: 'none',
              borderRadius: '20px',
              minWidth: '100px'
            }} onClick={() => {
              handleCloseModal();
            }}>
                  Cancel
                </Button>

                <Button disabled={selectedFields.length === 0} variant="text" sx={{
              textTransform: 'none',
              borderRadius: '20px',
              minWidth: '100px'
            }} onClick={() => {
              handleCloseModal(selectedFields);
            }}>
                  Export
                </Button>
              </Box>
            </Stack>
          </>}
      </Box>
    </Modal>;
};