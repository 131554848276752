import React, { useEffect, useContext, useState } from 'react';
import { UserStateContext } from 'context/user-state-context';
import { history } from '../../../root.component';
import { PageHeaderMui, snackbarService } from 'components';
import validator from 'validator';
import { useLocation } from 'react-router-dom';
import { Box, Button, IconButton, Paper, Stack, TextField, Tooltip, Typography, Divider, InputAdornment, CircularProgress, Dialog, DialogTitle, DialogContent, DialogActions, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { withStyles } from '@mui/styles';
import { Icon } from 'components';
import { Dashboard } from '@uppy/react';
import Uppy from '@uppy/core';
import AwsS3Multipart from '@uppy/aws-s3-multipart';
import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';
import { createMultipartUpload, listParts, prepareUploadPart, completeMultipartUpload, abortMultipartUpload } from 'components/file-viewer/provider';
import Papa from 'papaparse';
import { processPestPacCsv } from 'shared/common.api';
const filenames = ['cinch_billtos.csv', 'cinch_invoice_lines.csv', 'cinch_invoices.csv', 'cinch_locations.csv', 'cinch_service_orders.csv', 'cinch_service_setups.csv'];
export const IntegrationPestpacUpload = props => {
  const {
    user,
    asCompany,
    hasPermission
  } = useContext(UserStateContext);
  const loc = useLocation();
  const urlParams = new URLSearchParams(loc.search);
  const [uppy, setUppy] = useState();
  const [loading, setLoading] = React.useState(false);
  const [uploadIssues, setUploadIssues] = useState([]);
  const [preventUpload, setPreventUpload] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [billTos, setBillTos] = useState(null);
  const [invoiceLines, setInvoiceLines] = useState(null);
  const [invoices, setInvoices] = useState(null);
  const [locations, setLocations] = useState(null);
  const [serviceOrders, setServiceOrders] = useState(null);
  const [serviceSetups, setServiceSetups] = useState(null);
  const [billTosErrors, setBillTosErrors] = useState([]);
  const [invoiceLinesErrors, setInvoiceLinesErrors] = useState([]);
  const [invoicesErrors, setInvoicesErrors] = useState([]);
  const [locationsErrors, setLocationsErrors] = useState([]);
  const [serviceOrdersErrors, setServiceOrdersErrors] = useState([]);
  const [serviceSetupsErrors, setServiceSetupsErrors] = useState([]);
  const [renderIteration, setRenderIteration] = useState(0);
  let hasError = false;
  useEffect(() => {
    setLoading(true);
    if (!user || !asCompany?.id) {
      return;
    }
    if (!validator.isUUID(props.match.params.id)) {
      snackbarService.popup({
        type: 'error',
        message: 'Invalid/No install id'
      });
      history.push('/integrations');
    }
    if (!urlParams.has('credential')) {
      snackbarService.popup({
        type: 'error',
        message: 'No Credential id found'
      });
      history.push('/integrations');
    }
    const installId = props.match.params.id;
    const credential = urlParams.get('credential');
    const uppy = new Uppy({
      restrictions: {
        maxNumberOfFiles: 6,
        minNumberOfFiles: 6,
        maxTotalFileSize: 150000000,
        // Max file size 150mb
        allowedFileTypes: ['.csv']
      },
      autoProceed: true,
      disableThumbnailGenerator: true,
      showRemoveButtonAfterComplete: false,
      fileManagerSelectionType: 'files',
      allowMultipleUploads: false
    }).use(AwsS3Multipart, {
      companionUrl: API_URL + `/company-files/${asCompany.id}/upload`,
      parentFolder: 'pestpac/',
      createMultipartUpload,
      listParts,
      prepareUploadPart,
      completeMultipartUpload,
      abortMultipartUpload
    }).on('file-added', file => {
      handleFile(file.data);
    }).on('file-removed', file => {
      setUploadIssues([]);
      setPreventUpload(false);
      setIsUploading(false);
    }).on('file-added', file => {
      handleFile(file.data);
    }).on('upload-success', file => {
      if (hasError) {
        snackbarService.popup({
          type: 'error',
          duration: 5000,
          message: `Error with CSV file. Please check the file and try again.`
        });
        return;
      }
      if (file.name === 'cinch_billtos.csv') {
        setBillTos({
          filename: file.name,
          company: asCompany.id
        });
      } else if (file.name === 'cinch_invoice_lines.csv') {
        setInvoiceLines({
          filename: file.name,
          company: asCompany.id
        });
      } else if (file.name === 'cinch_invoices.csv') {
        setInvoices({
          filename: file.name,
          company: asCompany.id
        });
      } else if (file.name === 'cinch_locations.csv') {
        setLocations({
          filename: file.name,
          company: asCompany.id
        });
      } else if (file.name === 'cinch_service_orders.csv') {
        setServiceOrders({
          filename: file.name,
          company: asCompany.id
        });
      } else if (file.name === 'cinch_service_setups.csv') {
        setServiceSetups({
          filename: file.name,
          company: asCompany.id
        });
      }
    });
    setUppy(uppy);
    setLoading(false);
  }, [user, asCompany, renderIteration]);
  const handleFile = file => {
    // console.log('file', file);
    const fr = new FileReader();
    fr.onload = function () {
      const errors = [];
      const data = Papa.parse(fr.result);
      const rows = data.data.filter(r => r.join('').trim() !== '');
      const inlineFileName = rows.shift();
      if (inlineFileName[0] + '.csv' !== file.name) {
        hasError = true;
        errors.push({
          file: file.name,
          row: 1,
          cell: 'File',
          change: 'File name in first row does not match the file name. Please check the file and try again.'
        });
      }
      const fields = rows.shift();
      if (!fields) {
        hasError = true;
        errors.push({
          file: file.name,
          row: 2,
          cell: 'File',
          change: 'Unable to parse any headers from the CSV file. Please check the file and try again.'
        });
      }
      if (!Math.max(0, rows.length)) {
        hasError = true;
        errors.push({
          file: file.name,
          row: 1,
          cell: 'File',
          change: 'CSV file does not contain any data. Please check the file and try again'
        });
      }

      //check if any rows are completely empty
      data.data.forEach((row, index) => {
        if (row.join('').trim() === '' && index !== 0 && index != data.data.length - 1) {
          hasError = true;
          errors.push({
            file: file.name,
            row: index + 2,
            cell: 'File',
            change: 'Row is completely empty'
          });
        }
      });
      if (!filenames.includes(file.name)) {
        hasError = true;
        errors.push({
          file: file.name,
          row: 2,
          cell: 'File',
          change: 'Invalid file name. Please check the file and try again.'
        });
      }

      //check for any missing headers
      let requiredFields = [];
      if (file.name === 'cinch_billtos.csv') {
        requiredFields = ['Address Line 1', 'Address Line 2', 'Alt Phone', 'Alt Phone Ext', 'BillToID', 'Branch Name', 'City', 'Collection Stage Change Date', 'Collection Recall Reason', 'Collection Stage Number', 'Company', 'Country', 'Credit Limit', 'Credit Status', 'Division', 'Division Description', 'Email', 'Entered Date', 'Fax Number', 'Fax Extension', 'First Name', 'Last Name', 'Last Statement Date', 'Mobile Number', 'Mobile Extension', 'Phone', 'Prospect', 'Referred By Bill-To ID', 'Salutation', 'Salutation Name', 'Sent To Collection Agency', 'State', 'Terms', 'Title', 'Type', 'URL', 'Zip'];
      } else if (file.name === 'cinch_invoice_lines.csv') {
        requiredFields = ['InvoiceID', 'BillToID', 'BranchID', 'LocationID', 'Service Code', 'Quantity', 'Extended Price', 'GL Code', 'Description', 'Unit Price'];
      } else if (file.name === 'cinch_invoices.csv') {
        requiredFields = ['BillToID', 'BranchID', 'Credit Reason Code', 'Division', 'Duration', 'External WO #', 'GL Code', 'Invoice Date', 'InvoiceID', 'Invoice Number', 'Invoice Type', 'LocationID', 'Not Service Reason', 'Order Date', 'OrderID', 'Order Number', 'Order Type', 'PO Number', 'Service Code', 'Setup Type', 'Source', 'SubTotal', 'Targets', 'Tax', 'Tax Code', 'Tax Code Rate', 'Terms', 'Time Range', 'Total', 'Void', 'Work Date'];
      } else if (file.name === 'cinch_locations.csv') {
        requiredFields = ['LocationID', 'BillToID', 'BranchID', 'Address Line 1', 'Address Line 2', 'City', 'State', 'Zip', 'Country'];
      } else if (file.name === 'cinch_service_orders.csv') {
        requiredFields = ['Add Date', 'Posted Batch Number', 'BillToID', 'Branch', 'BranchID', 'Service Description', 'Division', 'Division Description', 'Drive Duration', 'Duration', 'External WO #', 'GL Code', 'In Progress On Mobile', 'LocationBundleID', 'LocationID', 'Measurement', 'Measurement Type', 'Order Date', 'OrderID', 'Order Number', 'Order Type', 'Posted', 'PO Number', 'Route', 'Service Code', 'Setup Type', 'Source', 'SubTotal', 'Targets', 'Tax', 'Terms', 'Time Range', 'Total', 'Work Date'];
      } else if (file.name === 'cinch_service_setups.csv') {
        requiredFields = ['Active', 'Add Date', 'Branch', 'BranchID', 'Cancel Date', 'Cancel Reason', 'Service Description', 'Division', 'Duration', 'Eligible for Annual Prepay Discount', 'Commission End Date', 'BillToID', 'Expiration Date', 'Schedule Frequency Code', 'Schedule Frequency Description', 'GL Code', 'Has Initial', 'Initial Complete', 'LocationID', 'Locked', 'Measurement', 'Measurement Type', 'PO Number', 'PO Expiration Date', 'Renewal Date', 'Route', 'Schedule Code', 'Service Code', 'SetupID', 'Setup Type', 'Skip Months', 'Source Code', 'Start Date', 'SubTotal', 'Targets', 'Tax', 'Terms', 'Time Range', 'Total', 'Work Time'];
      }
      requiredFields.forEach(field => {
        if (!fields.includes(field)) {
          hasError = true;
          errors.push({
            file: file.name,
            row: 2,
            cell: field,
            change: 'Missing required field'
          });
        }
      });

      //console.log(errors, ' errors');
      if (hasError) {
        setPreventUpload(true);
      }
      if (file.name === 'cinch_billtos.csv') {
        setBillTosErrors(errors);
      } else if (file.name === 'cinch_invoice_lines.csv') {
        setInvoiceLinesErrors(errors);
      } else if (file.name === 'cinch_invoices.csv') {
        setInvoicesErrors(errors);
      } else if (file.name === 'cinch_locations.csv') {
        setLocationsErrors(errors);
      } else if (file.name === 'cinch_service_orders.csv') {
        setServiceOrdersErrors(errors);
      } else if (file.name === 'cinch_service_setups.csv') {
        setServiceSetupsErrors(errors);
      } else {
        setUploadIssues(errors);
      }
    };
    try {
      fr.readAsText(file);
    } catch (e) {
      // For some unknown reason this throws an error but it still works *facepalm*
    }
  };
  const actuallyUpload = () => {
    setIsUploading(true);
    processPestPacCsv({
      company: asCompany.id
    }).then(response => {
      console.log('response', response);
      setIsUploading(false);
      snackbarService.popup({
        type: 'success',
        message: 'Files uploaded successfully'
      });
      history.push('/integrations');
    }).catch(error => {
      setIsUploading(false);
      console.log('error', error);
      snackbarService.popup({
        type: 'error',
        message: 'Error uploading files'
      });
    });
  };
  useEffect(() => {
    if (billTosErrors.length > 0 || invoiceLinesErrors.length > 0 || invoicesErrors.length > 0 || locationsErrors.length > 0 || serviceOrdersErrors.length > 0 || serviceSetupsErrors.length > 0) {
      setUploadIssues([...billTosErrors, ...invoiceLinesErrors, ...invoicesErrors, ...locationsErrors, ...serviceOrdersErrors, ...serviceSetupsErrors]);
      setPreventUpload(true);
    } else {
      setUploadIssues([]);
      setPreventUpload(false);
    }
  }, [billTosErrors, invoiceLinesErrors, invoicesErrors, locationsErrors, serviceOrdersErrors, serviceSetupsErrors]);
  return <>
      <CssBaseline />

      <div className="wrapper">
        <PageHeaderMui type={'Pestpack CSV Upload'} icon={<Icon name="custom-location_on" size={34} />} />

        <div className="mui-wrapper">
          <Box sx={{
          display: 'flex',
          justifyContent: 'center',
          alignContent: 'center'
        }}>
            <Stack sx={{
            p: '20px',
            width: '100%',
            height: '100%'
          }} spacing={'20px'}>
              <Paper sx={{
              borderRadius: '16px',
              width: '100%',
              height: '100%'
            }}>
                {loading ? <Box sx={{
                width: '100%',
                height: '100%'
              }}>
                    <Box sx={{
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignContent: 'center',
                  justifyItems: 'center',
                  alignItems: 'center'
                }}>
                      <CircularProgress />
                    </Box>
                  </Box> : <Stack spacing={2} sx={{
                p: '20px'
              }}>
                    <Stack spacing={1}>
                      <Typography sx={{
                    fontSize: '14px'
                  }}>Upload the following files:</Typography>
                      <Stack sx={{
                    pl: 1
                  }} spacing={1}>
                        <Typography> cinch_billtos.csv </Typography>
                        <Typography> cinch_invoice_lines.csv </Typography>
                        <Typography> cinch_invoices.csv </Typography>
                        <Typography>cinch_locations.csv </Typography>
                        <Typography> cinch_service_orders.csv </Typography>
                        <Typography>cinch_service_setups.csv </Typography>
                      </Stack>
                    </Stack>
                    <Box>
                      {uppy && <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    pt: '15px',
                    pb: '85px'
                  }}>
                          <Dashboard width={370} height={322} uppy={uppy} />
                        </Box>}
                    </Box>
                    {uploadIssues && <Box display="flex" flexDirection="row" justifyContent="space-between">
                        <Box />
                        <Stack spacing={2} sx={{
                    display: 'flex',
                    alignContent: 'center'
                  }}>
                          {uploadIssues.length > 0 && <Stack spacing={1}>
                              {uploadIssues.map((change, index) => <Stack key={index} direction="row" spacing={2}>
                                  <Typography>File: {change.file}</Typography>
                                  <Typography>Row: {change.row}</Typography>
                                  <Typography>Cell: {change.cell}</Typography>
                                  <Stack direction="row" spacing={1}>
                                    <Typography>Issue: </Typography> <Typography sx={{
                            fontWeight: '600'
                          }}> {change.change}</Typography>
                                  </Stack>
                                </Stack>)}
                            </Stack>}
                        </Stack>
                        <Box />
                      </Box>}

                    <Box sx={{
                  pt: 1
                }} display="flex" flexDirection="row" justifyContent="space-between">
                      <Box />
                      <Box>
                        <Button disabled={isUploading || !billTos && billTosErrors.length === 0 && !invoiceLines && invoiceLinesErrors.length === 0 && !invoices && invoicesErrors.length === 0 && !locations && locationsErrors.length === 0 && !serviceOrders && serviceOrdersErrors.length === 0 && !serviceSetups && serviceSetupsErrors.length === 0} variant="contained" endIcon={isUploading && <CircularProgress sx={{
                      color: '#FFF'
                    }} size={'20px'} />} sx={{
                      backgroundColor: '#3898D9',
                      '&:hover': {
                        backgroundColor: '#3898D9'
                      }
                    }} onClick={e => {
                      if (preventUpload || !billTos && billTosErrors.length > 0 || !invoiceLines && invoiceLinesErrors.length > 0 || !invoices && invoicesErrors.length > 0 || !locations && locationsErrors.length > 0 || !serviceOrders && serviceOrdersErrors.length > 0 || !serviceSetups && serviceSetupsErrors.length > 0) {
                        uppy.reset();
                        setUploadIssues([]);
                        setPreventUpload(false);
                        setBillTos(null);
                        setInvoiceLines(null);
                        setInvoices(null);
                        setLocations(null);
                        setServiceOrders(null);
                        setServiceSetups(null);
                        setBillTosErrors([]);
                        setInvoiceLinesErrors([]);
                        setInvoicesErrors([]);
                        setLocationsErrors([]);
                        setServiceOrdersErrors([]);
                        setServiceSetupsErrors([]);
                        setRenderIteration(r => r + 1);
                      } else {
                        actuallyUpload();
                      }
                    }}>
                          {preventUpload ? 'Reset' : ' Submit to Cinch'}
                        </Button>
                      </Box>
                      <Box />
                    </Box>
                  </Stack>}
              </Paper>
            </Stack>
          </Box>
        </div>
      </div>
    </>;
};