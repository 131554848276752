import React, { useState } from 'react';
import { createPortal } from 'react-dom';
import { Scoped } from 'kremling';
import { READ_COMPONENT_MAP } from './components';
import { Button } from 'components/button/button.component';
import { ModalWrapper } from './modal-wrapper.component';
import css from './node-modal.styles.scss';
import { isVisible } from './utils';
export const DynamicModalPublished = props => {
  const {
    node,
    stageX,
    stageY,
    scale,
    clientX,
    clientY,
    asCompany
  } = props;
  const close = () => {
    props.onSubmit();
  };
  if (!node) {
    // Is this necessary?
    return null;
  }
  return createPortal(<Scoped css={css}>
      <ModalWrapper node={node} stageX={stageX} stageY={stageY} scale={scale} clientX={clientX} clientY={clientY}>
        <Button className="node-modal__head-close" actionType="flat" icon="fa-regular-times" small onClick={close} />
        <div className="node-modal__inner">
          {node.description && <div className="row">
              <div className='col-12'>
                <div className="form-group">
                  <label>Description</label><br />
                  {node.description}
                </div>
              </div>
            </div>}
          <div className="row">
            {node.componentParams.map(param => <div className={param.className || 'col-12'} key={param.key}>
                {!!READ_COMPONENT_MAP[param.type] && isVisible(param, {
              companyCanHideOptout: asCompany.sms_hide_optout_message,
              ...node.parameters
            }) && <div className="form-group">
                    <label>{param.label}</label>

                    {React.createElement(READ_COMPONENT_MAP[param.type], {
                param,
                data: node.parameters,
                node
              })}
                  </div>}
              </div>)}
          </div>
        </div>
        <div className="node-modal__footer">
          <div>
          </div>
          <div className="node-modal__footer-inner">
            <Button actionType="primary" onClick={close}>
              Close
            </Button>
          </div>
        </div>
      </ModalWrapper>
    </Scoped>, document.body);
};