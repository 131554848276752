import React from 'react';
import { PageHeaderMui } from 'components';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { getMessaging as getMessagingApi, getLocations, registerDeviceToFirebase, getCustomers, getPhoneNumbers } from '../../shared/common.api';
import { history } from '../../root.component';
import moment from 'moment-timezone';
import EnhancedTable from '../../components/mui/enhanced-table/enhanced-table.component';
import { Avatar, Box, IconButton, Tooltip, TextField, Typography, Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, TableHead, TableRow, TableCell, TableBody, Table, InputAdornment, Input, Paper } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CssBaseline from '@mui/material/CssBaseline';
import ReplyIcon from '@mui/icons-material/Reply';
import { Icon } from 'components/icon/icon.component';
import { NewButton } from 'components/mui/button/new.component';
import { SingleSelectFilter } from 'components/mui/filter/single-select.component';
import { MultiSelectFilter } from 'components/mui/filter/multi-select.component';
import { ToggleFilter } from 'components/mui/filter/toggle.component';
import { LocationTooltip } from 'components/mui/tooltip/location-tooltip.component';
import { CustomWidthTooltip } from '../../components/mui/tooltip/custom-width-tooltip.component';
import utils from 'shared/utils';
import { CustomerDrawerContext } from '../../context/customer-drawer-context';
import { useContext, useState, useEffect } from 'react';
import { UserStateContext } from 'context/user-state-context';
import { getFiltersFromURL } from 'components/mui/enhanced-table/utils';
import { BrowserNotificationsContext } from 'context/browser-notifications';
import { CancelOutlinedIcon, CheckCircleIcon, DoubleDashIcon } from 'components/mui';
import { NewCustomerDialog } from './new-customer-dialog.component';
import CloseIcon from '@mui/icons-material/Close';
import { withStyles } from '@mui/styles';
import CircularProgress from '@mui/material/CircularProgress';
const CustomTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      borderRadius: `14px`,
      '& fieldset': {}
    }
  }
})(TextField);
const renderReplied = (review, hover) => {
  if (!review.reply_when && review.id == hover) {
    return <div style={{
      width: '40px',
      display: 'flex'
    }}>
        <ReplyIcon sx={{
        fontSize: '24px',
        color: '#53A6D6'
      }} />
        <Typography sx={{
        pl: 1
      }} color={'#53A6D6'}>
          Reply
        </Typography>
      </div>;
  }
  if (!!review.reply_when && review.id == hover) {
    return <div style={{
      width: '40px',
      display: 'flex'
    }}>
        <EditOutlinedIcon sx={{
        fontSize: '24px',
        color: '#53A6D6'
      }} />
        <Typography sx={{
        pl: 1
      }} color={'#53A6D6'}>
          Edit Reply
        </Typography>
      </div>;
  }
  if (!review.reply_when) {
    return <CancelOutlinedIcon fill="#FCCD6F" />;
  }
  return <CheckCircleIcon fill="#66CC99" />;
};
export const Messaging = () => {
  const {
    openCustomerDrawer
  } = useContext(CustomerDrawerContext);
  const {
    requestPermissionToNotify
  } = useContext(BrowserNotificationsContext);
  const {
    asCompany,
    isCatalystUser,
    userLocations
  } = useContext(UserStateContext);
  const [hasReplyDisable, setHasReplyDisable] = useState(true);
  const [hasDirectionDisable, setHasDirectionDisable] = useState(true);
  const [search, setSearch] = useState('');
  const [customers, setCustomers] = useState([]);
  const [createNewSMSDialog, setCreateNewSMSDialog] = useState(false);
  const [createNewCustomerDialog, setCreateNewCustomerDialog] = useState(false);
  const [avatarHovered, setAvatarHovered] = React.useState(null);
  const [renderIteration, setRenderIteration] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [filterOptions, setFilterOptions] = useState([]);
  const [filters, setFilters] = useState({});
  const [filtersLoading, setFiltersLoading] = useState(true);
  const openSMSDialog = () => {
    setCreateNewSMSDialog(true);
  };
  useEffect(() => {
    setFiltersLoading(true);
    const promises = [];
    promises.push(getLocations({
      company: asCompany.id,
      limit: 1000,
      user_locations: isCatalystUser,
      ordering: 'name'
    }), getPhoneNumbers({
      company: asCompany.id
    }));
    Promise.all(promises).then(([locs, nums]) => {
      const opts = [{
        component: SingleSelectFilter,
        label: 'Text Direction',
        key: 'direction',
        disabled: hasDirectionDisable,
        options: [{
          label: 'Incoming',
          value: 'incoming'
        }, {
          label: 'Outgoing',
          value: 'outgoing'
        }, {
          label: 'Both',
          value: 'both'
        }]
      }, {
        component: SingleSelectFilter,
        label: 'Replied',
        key: 'has_reply',
        disabled: hasReplyDisable,
        options: [{
          label: 'Show all',
          value: 'all',
          isDefault: true
        }, {
          label: 'Yes',
          value: 'true'
        }, {
          label: 'No',
          value: 'false'
        }]
      }, {
        component: ToggleFilter,
        label: 'Show Start/Stop/Unsubscribe',
        key: 'show_opt',
        defaultVal: 'off'
      }, {
        key: 'date_after',
        hidden: {
          hideInFilterList: true,
          hideChip: true
        }
      }, {
        key: 'date_before',
        hidden: {
          hideInFilterList: true,
          hideChip: true
        }
      }];
      if (locs?.data?.results?.length > 0) {
        opts.push({
          component: MultiSelectFilter,
          label: 'Last Location',
          key: 'last_location_ids',
          options: locs?.data?.results.map(location => ({
            label: location.friendly_name || 'Unnamed Location',
            value: location.id
          }))
        });
      }
      if (nums?.data?.results?.length > 1) {
        opts.push({
          component: MultiSelectFilter,
          label: 'Phone Number',
          key: 'phone_number_ids',
          localStorageKey: 'messagingListPhoneNumberIds',
          options: nums?.data?.results.map(num => ({
            label: num.name || num.phone,
            value: num.id
          }))
        });
      }
      setFilterOptions(opts);
      const fils = getFiltersFromURL(opts);
      if (!fils['date_after']) {
        fils['date_after'] = moment().subtract(7, 'days').startOf('day').toISOString();
      }
      if (!fils['direction']) {
        fils['direction'] = 'incoming';
      }
      const localStorageNums = localStorage.getItem('messagingListPhoneNumberIds') ? JSON.parse(localStorage.getItem('messagingListPhoneNumberIds')) : [];
      if (!fils['phone_number_ids'] && localStorageNums.length > 0) {
        fils['phone_number_ids'] = localStorageNums;
      }
      setFilters(fils);
      setFiltersLoading(false);
    });
  }, [asCompany.id, isCatalystUser]);
  useEffect(() => {
    if (!isCatalystUser) {
      return;
    }
    setRenderIteration(r => r + 1);
  }, [userLocations, isCatalystUser]);
  useEffect(() => {
    setHasReplyDisable(!!filters.direction);
    setHasDirectionDisable(filters.has_reply != undefined);
  }, [filters]);
  const headCells = [{
    key: 'customer',
    align: 'left',
    disablePadding: true,
    label: 'Customer',
    component: 'th',
    scope: 'row',
    padding: 'none',
    lableId: `enhanced-table-checkbox-0`,
    noSort: true,
    displayComponent: ({
      data
    }) => !!data.first_name || !!data.last_name ? `${data.first_name || ''} ${data.last_name || ''}` : utils.formatPhoneNumber(data.customer_phone),
    props: {}
  }, {
    key: 'review_when',
    align: 'left',
    disablePadding: true,
    label: 'Date',
    noSort: true,
    displayComponent: ({
      data
    }) => <>{moment(data.last_message_when).format('MMM D, YYYY')}</>
  }, {
    key: 'last_location',
    align: 'left',
    disablePadding: true,
    label: 'Last Location',
    noSort: true,
    displayComponent: ({
      data
    }) => {
      if (data?.last_location_id) {
        return <LocationTooltip location={{
          address1: data.last_location_address,
          entity_number: data.entity_number,
          entity_ref: data.entity_ref,
          friendly_name: data.last_location_friendly_name,
          city: data.last_location_city,
          state: data.last_location_state,
          zip_code: data.last_location_zip_code
        }} />;
      }
      return <DoubleDashIcon />;
    }
  }, {
    key: 'comment',
    align: 'left',
    disablePadding: true,
    label: 'Message',
    type: 'string',
    noSort: true,
    displayComponent: ({
      data
    }) => {
      const message = `${data.last_message_direction === 'outgoing' ? 'You: ' : ''}${data.last_message}`;
      return <CustomWidthTooltip tip={message} />;
    }
  }, {
    key: 'reply_when',
    align: 'left',
    disablePadding: true,
    label: 'Replied',
    noSort: true,
    displayComponent: ({
      data,
      onHover
    }) => <>{data.last_message_direction === 'incoming' && renderReplied(data, onHover)}</>
  }];
  const handleRowClick = customer => {
    if (isLoading) {
      return;
    }
    handleCreateNewSMSDialogClose();
    openCustomerDrawer(customer.id, 'messaging');
  };

  /*   useEffect(() => {
    requestPermissionToNotify();
    turning this off till we use it
  }, []);
  */
  const getInitals = name => {
    if (name) return name.split(' ').filter((segment, i) => i < 2).map(segment => segment[0]).join('').toUpperCase();
  };
  const handleCreateNewCustomer = () => {
    setCreateNewCustomerDialog(true);
    setCreateNewSMSDialog(false);
  };
  useEffect(() => {
    if (search === undefined || search === null || search === '') {
      setCustomers([]);
      return;
    }
    const delayDebounceFn = setTimeout(() => {
      setIsLoading(true);
      const promises = [];
      if (isPhoneNumber()) {
        promises.push(getCustomers({
          company: asCompany.id,
          ordering: 'first_name',
          phone: search,
          limit: 100
        }));
      } else {
        promises.push(getCustomers({
          company: asCompany.id,
          name: search,
          limit: 100
        }));
      }
      Promise.all(promises).then(([data]) => {
        if (data?.data?.results) {
          setCustomers(data.data);
        }
      }).then(() => {
        setIsLoading(false);
      });
    }, 1000);
    return () => clearTimeout(delayDebounceFn);
  }, [search]);
  const onCreateSMSInputChange = value => {
    if (value === undefined || value === null || value === '') {
      value = '';
    }
    setSearch(value.replace(/[()-]|/g, ''));
  };
  const handleCreateNewSMSDialogClose = () => {
    setCustomers([]);
    setCreateNewSMSDialog(false);
    setSearch('');
    onCreateSMSInputChange('');
  };

  // pass in an id for text highlighting in the tooltip
  const makeOverflowTooltip = (id, content, length, bold) => {
    if (content) {
      return <Tooltip title={content.length > length ? <> {content} </> : ''}>
          <Typography sx={{
          color: avatarHovered === id ? '#53A6D6' : '#1D252D'
        }} variant={bold ? 'tableHeader' : ''}>
            {content.length > length ? <>{('' + content).slice(0, length) + '...'}</> : <>{content}</>}
          </Typography>
        </Tooltip>;
    }
  };
  const isPhoneNumber = () => {
    const regex = /^(?:\+)?[0-9]+$/;
    if (search) {
      return regex.test(search);
    }
    return false;
  };
  const MessagingApiWrapper = () => {
    return params => getMessagingApi({
      last_location_ids: !!isCatalystUser ? userLocations : null,
      ...params
    });
  };
  return <>
      <div className="wrapper">
        <PageHeaderMui type="Text Messages" icon={<Icon name="custom-sms" size={24} />}
      /*objIcon={
      <>
        {!browserNotificationIcon &&
          (Notification.permission == 'denied' ? (
            <Box sx={{ pl: 3 }}>
              <Tooltip title="You have denied browser notifications for text messages. In order to recieve them, you will need to clear the setting in your web browser">
                <IconButton>
                  <WarningTriangleIcon />
                </IconButton>
              </Tooltip>
            </Box>
          ) : (
            <Box sx={{ pl: 3 }}>
              <Tooltip title="You have declined browser notifications for text messages. Click here to enable them">
                <IconButton onClick={(e) => requestPermissionToNotify()}>
                  <WarningTriangleIcon />
                </IconButton>
              </Tooltip>
            </Box>
          ))}
      </>
      }*/ />
        <CssBaseline />
        <div className="mui-wrapper">
          {filters && !filtersLoading ? <EnhancedTable sx={{
          fontFamily: 'Nexa'
        }} headCells={headCells} type="Messages" api={MessagingApiWrapper()} enableAutoRefresh handleRowClick={row => {
          openCustomerDrawer(row.id, 'messaging');
        }} filterOptions={filterOptions} filtersState={[filters, setFilters]} datePicker renderIteration={renderIteration} actions={<NewButton onClick={e => openSMSDialog()} />} /> : <Box sx={{
          p: '20px'
        }}>
              <Paper sx={{
            borderRadius: '16px',
            width: '100%',
            height: '100%'
          }}>
                <Box sx={{
              width: '100%',
              height: '100%'
            }}>
                  <Box sx={{
                py: '200px'
              }}>
                    <Box sx={{
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignContent: 'center',
                  justifyItems: 'center',
                  alignItems: 'center'
                }}>
                      <CircularProgress />
                    </Box>
                  </Box>
                </Box>
              </Paper>
            </Box>}
        </div>
      </div>

      {createNewCustomerDialog && <NewCustomerDialog open={createNewCustomerDialog} phone={isPhoneNumber() ? search : null} firstName={isPhoneNumber() ? null : search} onClose={() => {
      handleCreateNewSMSDialogClose();
      setCreateNewCustomerDialog(false);
    }} />}

      <Dialog PaperProps={{
      style: {
        borderRadius: '28px',
        minHeight: '200px',
        minWidth: '400px',
        maxWidth: '400px',
        overflow: 'hidden'
      }
    }} open={createNewSMSDialog} onClose={() => {
      handleCreateNewSMSDialogClose;
    }}>
        <DialogTitle sx={{
        m: 0,
        pl: 4,
        fontSize: 'medium'
      }}>
          <IconButton aria-label="close" onClick={e => {
          setCreateNewSMSDialog(false);
        }} sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: theme => theme.palette.grey[500]
        }}>
            <CloseIcon />
          </IconButton>

          <Stack alignItems="left" component="span" sx={{
          pb: '0px'
        }}>
            <Typography variant="h4" component="span" fontWeight={'bold'} sx={{
            color: '#1D252D'
          }}>
              Send a Message
            </Typography>
          </Stack>
        </DialogTitle>
        <Box sx={{
        overflowX: 'hidden'
      }}>
          <DialogContent sx={{
          width: '100%',
          height: '100%',
          pb: '15px'
        }}>
            <Stack>
              <CustomTextField id="outlined-basic" label="To: Search by Name or Phone Number" variant="outlined" type="text" width="360px" autoComplete="off" value={search} InputProps={{
              startAdornment: <InputAdornment position="start">
                      <SearchIcon width="16.9px" height="16.9px" />
                    </InputAdornment>
            }} onChange={e => {
              onCreateSMSInputChange(e.target.value);
            }} />
              <Stack sx={{
              pt: '8px',
              pb: 0,
              overflowY: 'auto',
              maxHeight: '42vh',
              minHeight: '4vh',
              scrollbarWidth: 'thin',
              '&::-webkit-scrollbar': {
                width: '0.4em'
              },
              '&::-webkit-scrollbar-track': {
                background: '#f1f1f1',
                marginTop: '20px'
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: '#888'
              },
              '&::-webkit-scrollbar-thumb:hover': {
                background: '#555'
              }
            }}>
                {isLoading ? <Box
              // just have one loading icon
              sx={{
                display: 'flex',
                justifyContent: 'center',
                overflow: 'hidden'
              }}>
                    <CircularProgress />
                  </Box> : null}

                {isLoading == false && customers?.results?.map((customer, index) => <Box key={customer.id} sx={{
                pb: 1
              }}>
                      <Button onMouseEnter={() => {
                  setAvatarHovered(customer.id);
                }} onMouseLeave={() => {
                  setAvatarHovered(null);
                }} onClick={() => handleRowClick(customer)} sx={{
                  textAlign: 'left',
                  justifyContent: 'left',
                  width: '100%',
                  pl: 0,
                  borderBottom: '1px solid #E5E5E5',
                  '&:hover': {
                    backgroundColor: '#fff'
                  }
                }}>
                        <Box sx={{
                    px: '12px',
                    pl: '0px'
                  }}>
                          <Avatar sx={{
                      width: 30,
                      height: 30,
                      backgroundColor: avatarHovered === customer.id ? '#53A6D6' : '#BEBEBE'
                    }} children={getInitals(customer.first_name + ' ' + customer.last_name)} />
                        </Box>
                        <Stack>
                          <Typography sx={{
                      fontSize: '14px',
                      fontWeight: 'bold',
                      pl: 2,
                      textAlign: 'left',
                      textTransform: 'capitalize'
                    }}>
                            {makeOverflowTooltip(customer.id, customer.first_name, 10, true)}{' '}
                            {makeOverflowTooltip(customer.id, customer.last_name, 10, true)}
                          </Typography>
                          <Typography sx={{
                      fontSize: '14px',
                      pl: 2,
                      textAlign: 'left',
                      color: avatarHovered === customer.id ? '#53A6D6' : '#1D252D'
                    }}>
                            {utils.formatIntlPhone(customer.phone, 'US')}
                          </Typography>
                        </Stack>
                      </Button>
                    </Box>)}
              </Stack>
            </Stack>
          </DialogContent>
        </Box>

        <DialogActions align="left" sx={{
        pt: 7
      }}>
          <Box sx={{
          alignItems: 'center',
          width: '100%',
          position: 'absolute',
          bottom: 0,
          pb: 1
        }} display="flex" flexDirection="row" justifyContent="space-between">
            <Stack direction="row" display={'flex'}>
              <Typography sx={{
              fontSize: '14px',
              pl: '40px',
              pb: '16px',
              textAlign: 'left'
            }}>
                {!isLoading ? !customers?.results?.length && search === '' ? null : customers?.results?.length > 1 ? customers?.count > 100 ? `Showing 100 of ${customers.count} results` : `${customers?.results?.length} result${customers?.results?.length !== 1 ? 's' : ''}` : customers?.results?.length === 1 ? `${customers?.results?.length} result` : customers?.results?.length === 0 ? `No results` : '' // empty result string as they are typing
              : null // hide results while loading
              }
              </Typography>
            </Stack>
            <Stack direction="row" spacing={2} sx={{
            pb: '16px',
            pt: '2px',
            pr: '15px'
          }}>
              <Button onClick={() => {
              handleCreateNewSMSDialogClose();
            }} variant="text" sx={{
              width: '75px',
              borderRadius: '28px',
              mr: 1,
              textTransform: 'none'
            }}>
                Cancel
              </Button>
              <Button onClick={() => {
              handleCreateNewCustomer();
            }} variant="text" sx={{
              width: '85px',
              borderRadius: '28px',
              mr: 1,
              textTransform: 'none'
            }}>
                Add New
              </Button>
            </Stack>
          </Box>
        </DialogActions>
      </Dialog>
    </>;
};