import React, { useState, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { Scoped } from 'kremling';
import { EDIT_COMPONENT_MAP } from './components';
import { Button } from 'components/button/button.component';
import { ModalWrapper } from './modal-wrapper.component';
import css from './node-modal.styles.scss';
import { isVisible, errorMessage } from './utils';
export const DynamicModalDraft = props => {
  const {
    node,
    stageX,
    stageY,
    scale,
    clientX,
    clientY,
    asCompany
  } = props;
  const [data, setData] = useState({
    companyCanHideOptout: asCompany.sms_hide_optout_message,
    ...node.parameters
  });
  const [disableButton, setDisableButton] = useState(false);
  const close = () => {
    props.onSubmit();
  };
  const apply = () => {
    node.updateParameters(data);
    close();
  };
  const update = newData => {
    setData(prevData => ({
      ...prevData,
      ...newData
    }));
  };
  const reset = () => {
    setData(prevData => ({
      ...prevData,
      ...node.defaultParameters
    }));
  };
  const handleDisabledState = () => {
    if (node.subType === 'action_mms' && data?.media?.[0]?.Size > 5200880) {
      setDisableButton(true);
      return true;
    }
    setDisableButton(false);
    return false;
  };
  useEffect(() => {
    handleDisabledState();
  }, [node, data]);
  if (!node) {
    // Is this necessary?
    return null;
  }
  return createPortal(<Scoped css={css}>
      <ModalWrapper node={node} stageX={stageX} stageY={stageY} clientX={clientX} clientY={clientY} scale={scale}>
        <Button className="node-modal__head-close" actionType="flat" icon="fa-regular-times" small onClick={close} />

        <>
          <div className="node-modal__inner">
            {node.description && <div className="row">
                <div className="col-12">
                  <div className="form-group">
                    <label>Description</label>
                    <br />
                    {node.description}
                  </div>
                </div>
              </div>}
            <div className="row">
              {node.componentParams.map(param => <div className={param.className || 'col-12'} key={param.key}>
                  {!!EDIT_COMPONENT_MAP[param.type] && isVisible(param, data) && React.createElement(EDIT_COMPONENT_MAP[param.type], {
                param,
                update,
                data,
                node
              })}
                  {errorMessage(param, data) && <div className="node-modal__error-message">{errorMessage(param, data)}</div>}
                </div>)}
            </div>
          </div>
          <div className="node-modal__footer">
            <div>
              <Button actionType="flat" onClick={reset}>
                Reset
              </Button>
            </div>
            <div className="node-modal__footer-inner">
              <Button actionType="flat" onClick={close}>
                Cancel
              </Button>
              <Button style={{
              backgroundColor: disableButton ? '#d3d3d3' : '#416d9c'
            }} actionType="primary" onClick={apply} disabled={disableButton}>
                Apply
              </Button>
            </div>
          </div>
        </>
      </ModalWrapper>
    </Scoped>, document.body);
};