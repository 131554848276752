import React, { useContext, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import { UserStateContext } from 'context/user-state-context';
import { setDataFieldsFromIntegration } from 'shared/common.api';
import { snackbarService } from 'components/mui/snackbar/snackbar-service';
import { Loader } from 'components/loader/loader.component';
export const DataFieldsModal = ({
  onClose,
  open,
  integration
}) => {
  const {
    asCompany,
    companyFieldsChange
  } = useContext(UserStateContext);
  const [loading, setLoading] = useState(false);
  const onSubmit = type => {
    setLoading(true);
    setDataFieldsFromIntegration(integration.integration.key, asCompany.id, type).then(() => {
      snackbarService.popup({
        type: 'success',
        message: 'Successfully updated company fields.'
      });
      companyFieldsChange();
      onClose();
    }).catch(() => {
      snackbarService.popup({
        type: 'error',
        message: 'Unknown error trying to update company fields.'
      });
      setLoading(false);
    });
  };
  return <Dialog sx={{
    fontFamily: 'Nexa',
    color: '#1D252D'
  }} PaperProps={{
    style: {
      borderRadius: '28px',
      minWidth: '40% '
    }
  }} open={open} onClose={onClose}>
      <DialogTitle className="d-flex w-100 justify-content-between" sx={{
      m: 0,
      px: 0,
      py: 1
    }}>
        <Box sx={{
        pl: '24px',
        display: 'flex',
        alignContent: 'center',
        alignItems: 'center'
      }}>
          <Typography sx={{
          fontSize: 24
        }}>Modify Data Fields</Typography>
        </Box>

        <Box sx={{
        pr: '24px',
        display: 'flex',
        alignContent: 'center',
        alignItems: 'center'
      }}>
          <IconButton aria-label="close" onClick={e => {
          onClose();
        }} sx={{
          color: theme => theme.palette.grey[500]
        }}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>

      <DialogContent dividers>
        <DialogContentText>
          <Box component="span">
            <Stack>
              <Box sx={{
              py: 1
            }}>
                <Typography component="span" sx={{
                color: '#1D252D'
              }}>
                  You can use this dialog to change the data fields of the company
                </Typography>
                <Typography variant="tableHeader">{' '}{asCompany?.name}{' '}</Typography>
                <Typography component="span" sx={{
                color: '#1D252D'
              }}>
                  with the data fields from the integration
                </Typography>
                <Typography variant="tableHeader">{' '}{integration?.integration?.name}{' '}</Typography>
              </Box>

              <Box sx={{
              py: 1
            }}>
                <Typography variant="tableHeader">Replace{' '}</Typography>
                <Typography component="span" sx={{
                color: '#1D252D'
              }}>
                  the data fields if you want to completely remove the existing data fields and replace them with the data fields from the integration.
                </Typography>
              </Box>

              <Box sx={{
              py: 1
            }}>
                <Typography variant="tableHeader">Merge{' '}</Typography>
                <Typography component="span" sx={{
                color: '#1D252D'
              }}>
                  the data fields if you want to combine the existing data fields with the data fields from the integration.  Any fields with conflicting names will be overwritten with the data fields from the integration.
                </Typography>
              </Box>
            </Stack>
          </Box>
        </DialogContentText>

      </DialogContent>

      <DialogActions sx={{
      py: 3
    }}>
        <Button onClick={e => {
        onClose();
      }} sx={{
        borderRadius: '20px',
        color: '#53A6D6',
        width: '125px',
        borderStyle: 'solid',
        borderColor: '#53A6D6',
        borderWidth: '1px',
        mr: 2
      }} disabled={loading}>
          Cancel
        </Button>
        <Button onClick={e => {
        onSubmit('merge');
      }} variant="contained" sx={{
        borderRadius: '20px',
        mr: 2,
        width: '125px'
      }} disabled={loading}>
          {loading ? <Loader size="sm" /> : 'Merge'}
        </Button>
        <Button onClick={e => {
        onSubmit('replace');
      }} variant="contained" sx={{
        borderRadius: '20px',
        mr: 2,
        width: '125px'
      }} disabled={loading}>
          {loading ? <Loader size="sm" /> : 'Replace'}
        </Button>
      </DialogActions>
    </Dialog>;
};