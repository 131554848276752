import React, { useContext, useState, useRef } from 'react';
import { UserStateContext } from 'context/user-state-context';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Paper, Stack, TextField, Tooltip, Typography, Divider, FormControl, Select, MenuItem, InputLabel, Skeleton, Popper, ClickAwayListener, Autocomplete, InputAdornment } from '@mui/material';
import { AddIcon, CloseIcon, EditIcon, HelpIcon } from 'components/mui';
import { withStyles } from '@mui/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import moment from 'moment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { DynamicTypeAheadMui } from 'components';
import { getAccounts, getBrands, getCompanyCancelReasons, getCompanyStatuses, getNAICS, getUsers } from 'shared/common.api';
const CustomTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      borderRadius: `14px`,
      '& fieldset': {}
    }
  }
})(TextField);
export const CompanyAdminInfoCard = props => {
  const {
    asCompany
  } = useContext(UserStateContext);
  const [companyAdminModal, setCompanyAdminModal] = useState(null);
  const [companyAdminModalFocus, setCompanyAdminModalFocus] = useState(null);
  const [contractOriginalSignedDateError, setContractOriginalSignedDateError] = useState(null);
  const [billedFirstDateError, setBilledFirstDateError] = useState(null);
  const [cancelDateError, setCancelDateError] = useState(null);
  const [billedLastDateError, setBilledLastDateError] = useState(null);
  const [customerEndDateError, setCustomerEndDateError] = useState(null);
  const [cohortDateError, setCohortDateError] = useState(null);
  const [commissionEndDateError, setCommissionEndDateError] = useState(null);
  const handleCompanyAdminModalClose = () => {
    setCompanyAdminModalFocus(null);
    setCompanyAdminModal(null);
  };
  const getTimezoneOffset = date => {
    if (!date) return null;
    const d = new Date(date);
    d.setMinutes(d.getMinutes() + d.getTimezoneOffset());
    return d;
  };
  const hasInputErrors = () => {
    if (isNaN(companyAdminModal?.contract_signed_mrr) || Number(companyAdminModal?.contract_signed_mrr) < 0 || isNaN(companyAdminModal?.commission_percent) || Number(companyAdminModal?.commission_percent) < 0 || Number(companyAdminModal?.commission_percent) > 100 || isNaN(companyAdminModal?.mail_limit) || Number(companyAdminModal?.mail_limit) < 0 || isNaN(companyAdminModal?.sms_limit) || Number(companyAdminModal?.sms_limit) < 0 || !!contractOriginalSignedDateError || !!billedFirstDateError || !!cancelDateError || !!billedLastDateError || !!customerEndDateError || !!cohortDateError || !!commissionEndDateError) {
      return true;
    }
    return false;
  };
  const hasBackendRejectionErrors = () => {
    if (props?.errors?.contract_signed_mrr || props?.errors?.contract_original_signed_date || props?.errors?.billed_first_date || props?.errors?.cancel_date || props?.errors?.billed_last_date || props?.errors?.customer_end_date || props?.errors?.cohort_date || props?.errors?.commission_end_date || props?.errors?.status || props?.errors?.account_manager || props?.errors?.account || props?.errors?.brand || props?.errors?.naics || props?.errors?.cancel_reason || props?.errors?.entity_data || props?.errors?.commission_percent || props?.errors?.contract_closer) {
      return true;
    }
    return false;
  };
  return <>
      <Paper sx={{
      width: '65vw',
      maxWidth: '1200px',
      borderRadius: '14px',
      border: hasBackendRejectionErrors() ? '2px solid #EF3C34' : 'none'
    }}>
        <Stack>
          <Stack direction="row" spacing={'10px'}>
            <Box sx={{
            py: '20px',
            pl: '20px',
            display: 'flex',
            alignItems: 'center'
          }}>
              <Typography sx={{
              fontSize: '20px',
              fontWeight: '400',
              lineHeight: '16.8px'
            }}>Admin Settings</Typography>
            </Box>
          </Stack>

          {/* status*/}

          <Box className="cardrow" sx={{
          py: '10px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          '&:hover': {
            backgroundColor: '#F3F3F4',
            cursor: 'pointer'
          }
        }} onClick={() => {
          setCompanyAdminModalFocus('status_id');
          setCompanyAdminModal(props.company);
        }}>
            <Box sx={{
            px: '20px'
          }}>
              <Typography>Status</Typography>
            </Box>

            {props?.errors?.status && <Box sx={{
            display: 'flex',
            alignItems: 'center'
          }}>
                <Typography sx={{
              color: '#EF3C34'
            }}>{props?.errors?.status}</Typography>
              </Box>}

            {props.loading ? <Box sx={{
            px: '20px',
            width: '30%',
            py: '8px'
          }}>
                <Skeleton variant="rectangular" width="100%" height={20} />
              </Box> : <Stack sx={{
            pr: '6px'
          }} direction={'row'} spacing="4px">
                <Box sx={{
              display: 'flex',
              alignItems: 'center'
            }}>
                  {props?.company?.status_name ? <Typography>{props?.company?.status_name}</Typography> : <Typography sx={{
                opacity: 0.5
              }}>None</Typography>}
                </Box>
                <Box className="icons__remove">
                  {props?.company?.status_name ? <Tooltip title={'Edit status'}>
                      <IconButton>
                        <EditIcon />
                      </IconButton>
                    </Tooltip> : <Tooltip title={'Add status'}>
                      <IconButton>
                        <AddIcon />
                      </IconButton>
                    </Tooltip>}
                </Box>
              </Stack>}
          </Box>
          <Box sx={{
          px: '20px'
        }}>
            <Divider />
          </Box>

          {/* account_manager */}

          <Box className="cardrow" sx={{
          py: '10px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          '&:hover': {
            backgroundColor: '#F3F3F4',
            cursor: 'pointer'
          }
        }} onClick={() => {
          setCompanyAdminModalFocus('account_manager');
          setCompanyAdminModal(props.company);
        }}>
            <Box sx={{
            px: '20px'
          }}>
              <Typography>Account Manager</Typography>
            </Box>

            {props?.errors?.account_manager && <Box sx={{
            display: 'flex',
            alignItems: 'center'
          }}>
                <Typography sx={{
              color: '#EF3C34'
            }}>{props?.errors?.account_manager}</Typography>
              </Box>}

            {props.loading ? <Box sx={{
            px: '20px',
            width: '30%',
            py: '8px'
          }}>
                <Skeleton variant="rectangular" width="100%" height={20} />
              </Box> : <Stack sx={{
            pr: '6px'
          }} direction={'row'} spacing="4px">
                <Box sx={{
              display: 'flex',
              alignItems: 'center'
            }}>
                  {props?.company?.account_manager_email ? <Typography>{props?.company?.account_manager_email}</Typography> : <Typography sx={{
                opacity: 0.5
              }}>None</Typography>}
                </Box>
                <Box className="icons__remove">
                  {props?.company?.account_manager_email ? <Tooltip title={'Edit Account manager'}>
                      <IconButton>
                        <EditIcon />
                      </IconButton>
                    </Tooltip> : <Tooltip title={'Add account manager'}>
                      <IconButton>
                        <AddIcon />
                      </IconButton>
                    </Tooltip>}
                </Box>
              </Stack>}
          </Box>
          <Box sx={{
          px: '20px'
        }}>
            <Divider />
          </Box>

          {/* account */}

          <Box className="cardrow" sx={{
          py: '10px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          '&:hover': {
            backgroundColor: '#F3F3F4',
            cursor: 'pointer'
          }
        }} onClick={() => {
          setCompanyAdminModalFocus('account_id');
          setCompanyAdminModal(props.company);
        }}>
            <Box sx={{
            px: '20px'
          }}>
              <Typography>Account</Typography>
            </Box>

            {props?.errors?.account && <Box sx={{
            display: 'flex',
            alignItems: 'center'
          }}>
                <Typography sx={{
              color: '#EF3C34'
            }}>{props?.errors?.account}</Typography>
              </Box>}

            {props.loading ? <Box sx={{
            px: '20px',
            width: '30%',
            py: '8px'
          }}>
                <Skeleton variant="rectangular" width="100%" height={20} />
              </Box> : <Stack sx={{
            pr: '6px'
          }} direction={'row'} spacing="4px">
                <Box sx={{
              display: 'flex',
              alignItems: 'center'
            }}>
                  {props?.company?.account_name ? <Typography>{props?.company?.account_name}</Typography> : <Typography sx={{
                opacity: 0.5
              }}>None</Typography>}
                </Box>
                <Box className="icons__remove">
                  {props?.company?.account_name ? <Tooltip title={'Edit Account'}>
                      <IconButton>
                        <EditIcon />
                      </IconButton>
                    </Tooltip> : <Tooltip title={'Add account'}>
                      <IconButton>
                        <AddIcon />
                      </IconButton>
                    </Tooltip>}
                </Box>
              </Stack>}
          </Box>
          <Box sx={{
          px: '20px'
        }}>
            <Divider />
          </Box>

          {/* brand */}

          <Box className="cardrow" sx={{
          py: '10px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          '&:hover': {
            backgroundColor: '#F3F3F4',
            cursor: 'pointer'
          }
        }} onClick={() => {
          setCompanyAdminModalFocus('brand_id');
          setCompanyAdminModal(props.company);
        }}>
            <Box sx={{
            px: '20px'
          }}>
              <Typography>Brand</Typography>
            </Box>

            {props?.errors?.brand && <Box sx={{
            display: 'flex',
            alignItems: 'center'
          }}>
                <Typography sx={{
              color: '#EF3C34'
            }}>{props?.errors?.brand}</Typography>
              </Box>}

            {props.loading ? <Box sx={{
            px: '20px',
            width: '30%',
            py: '8px'
          }}>
                <Skeleton variant="rectangular" width="100%" height={20} />
              </Box> : <Stack sx={{
            pr: '6px'
          }} direction={'row'} spacing="4px">
                <Box sx={{
              display: 'flex',
              alignItems: 'center'
            }}>
                  {props?.company?.brand_name ? <Typography>{props?.company?.brand_name}</Typography> : <Typography sx={{
                opacity: 0.5
              }}>None</Typography>}
                </Box>
                <Box className="icons__remove">
                  {props?.company?.brand_name ? <Tooltip title={'Edit Brand'}>
                      <IconButton>
                        <EditIcon />
                      </IconButton>
                    </Tooltip> : <Tooltip title={'Add Brand'}>
                      <IconButton>
                        <AddIcon />
                      </IconButton>
                    </Tooltip>}
                </Box>
              </Stack>}
          </Box>
          <Box sx={{
          px: '20px'
        }}>
            <Divider />
          </Box>

          {/* industry/naics */}

          <Box className="cardrow" sx={{
          py: '10px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          '&:hover': {
            backgroundColor: '#F3F3F4',
            cursor: 'pointer'
          }
        }} onClick={() => {
          setCompanyAdminModalFocus('naics_id');
          setCompanyAdminModal(props.company);
        }}>
            <Box sx={{
            px: '20px'
          }}>
              <Typography>Industry</Typography>
            </Box>

            {props?.errors?.naics && <Box sx={{
            display: 'flex',
            alignItems: 'center'
          }}>
                <Typography sx={{
              color: '#EF3C34'
            }}>{props?.errors?.naics}</Typography>
              </Box>}

            {props.loading ? <Box sx={{
            px: '20px',
            width: '30%',
            py: '8px'
          }}>
                <Skeleton variant="rectangular" width="100%" height={20} />
              </Box> : <Stack sx={{
            pr: '6px'
          }} direction={'row'} spacing="4px">
                <Box sx={{
              display: 'flex',
              alignItems: 'center'
            }}>
                  {props?.company?.naics_title ? <Typography>{props?.company?.naics_title}</Typography> : <Typography sx={{
                opacity: 0.5
              }}>None</Typography>}
                </Box>
                <Box className="icons__remove">
                  {props?.company?.naics_title ? <Tooltip title={'Edit Industry'}>
                      <IconButton>
                        <EditIcon />
                      </IconButton>
                    </Tooltip> : <Tooltip title={'Add Industry'}>
                      <IconButton>
                        <AddIcon />
                      </IconButton>
                    </Tooltip>}
                </Box>
              </Stack>}
          </Box>
          <Box sx={{
          px: '20px'
        }}>
            <Divider />
          </Box>

          {/* contract_original_signed_date */}

          <Box className="cardrow" sx={{
          py: '10px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          '&:hover': {
            backgroundColor: '#F3F3F4',
            cursor: 'pointer'
          }
        }} onClick={() => {
          setCompanyAdminModalFocus('contract_original_signed_date');
          setCompanyAdminModal(props.company);
        }}>
            <Box sx={{
            px: '20px'
          }}>
              <Typography>Contract Original Signed Date</Typography>
            </Box>

            {props?.errors?.contract_original_signed_date && <Box sx={{
            display: 'flex',
            alignItems: 'center'
          }}>
                <Typography sx={{
              color: '#EF3C34'
            }}>{props?.errors?.contract_original_signed_date}</Typography>
              </Box>}

            {props.loading ? <Box sx={{
            px: '20px',
            width: '30%',
            py: '8px'
          }}>
                <Skeleton variant="rectangular" width="100%" height={20} />
              </Box> : <Stack sx={{
            pr: '6px'
          }} direction={'row'} spacing="4px">
                <Box sx={{
              display: 'flex',
              alignItems: 'center'
            }}>
                  {props.company?.contract_original_signed_date ? <Typography>{moment(getTimezoneOffset(props?.company?.contract_original_signed_date)).format('MM/DD/YYYY')}</Typography> : <Typography sx={{
                opacity: 0.5
              }}>None</Typography>}
                </Box>
                <Box className="icons__remove">
                  {props?.company?.contract_original_signed_date ? <Tooltip title={'Edit Contract Original Signed Date'}>
                      <IconButton>
                        <EditIcon />
                      </IconButton>
                    </Tooltip> : <Tooltip title={'Add Contract Original Signed Date'}>
                      <IconButton>
                        <AddIcon />
                      </IconButton>
                    </Tooltip>}
                </Box>
              </Stack>}
          </Box>
          <Box sx={{
          px: '20px'
        }}>
            <Divider />
          </Box>

          {/* contract_signed_mrr */}

          <Box className="cardrow" sx={{
          py: '10px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          '&:hover': {
            backgroundColor: '#F3F3F4',
            cursor: 'pointer'
          }
        }} onClick={() => {
          setCompanyAdminModalFocus('contract_signed_mrr');
          setCompanyAdminModal(props.company);
        }}>
            <Box sx={{
            px: '20px'
          }}>
              <Typography>Contract Signed MRR</Typography>
            </Box>

            {props?.errors?.contract_signed_mrr && <Box sx={{
            display: 'flex',
            alignItems: 'center'
          }}>
                <Typography sx={{
              color: '#EF3C34'
            }}>{props?.errors?.contract_signed_mrr}</Typography>
              </Box>}

            {props.loading ? <Box sx={{
            px: '20px',
            width: '30%',
            py: '8px'
          }}>
                <Skeleton variant="rectangular" width="100%" height={20} />
              </Box> : <Stack sx={{
            pr: '6px'
          }} direction={'row'} spacing="4px">
                <Box sx={{
              display: 'flex',
              alignItems: 'center'
            }}>
                  {props.company?.contract_signed_mrr ? <Typography> ${props.company?.contract_signed_mrr}</Typography> : <Typography sx={{
                opacity: 0.5
              }}>None</Typography>}
                </Box>
                <Box className="icons__remove">
                  {props?.company?.contract_signed_mrr ? <Tooltip title={'Edit Contract Signed MRR'}>
                      <IconButton>
                        <EditIcon />
                      </IconButton>
                    </Tooltip> : <Tooltip title={'Add Contract Signed MRR'}>
                      <IconButton>
                        <AddIcon />
                      </IconButton>
                    </Tooltip>}
                </Box>
              </Stack>}
          </Box>
          <Box sx={{
          px: '20px'
        }}>
            <Divider />
          </Box>

          {/* billed first date */}

          <Box className="cardrow" sx={{
          py: '10px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          '&:hover': {
            backgroundColor: '#F3F3F4',
            cursor: 'pointer'
          }
        }} onClick={() => {
          setCompanyAdminModalFocus('billed_first_date');
          setCompanyAdminModal(props.company);
        }}>
            <Box sx={{
            px: '20px'
          }}>
              <Typography>Billed First Date</Typography>
            </Box>

            {props?.errors?.billed_first_date && <Box sx={{
            display: 'flex',
            alignItems: 'center'
          }}>
                <Typography sx={{
              color: '#EF3C34'
            }}>{props?.errors?.billed_first_date}</Typography>
              </Box>}

            {props.loading ? <Box sx={{
            px: '20px',
            width: '30%',
            py: '8px'
          }}>
                <Skeleton variant="rectangular" width="100%" height={20} />
              </Box> : <Stack sx={{
            pr: '6px'
          }} direction={'row'} spacing="4px">
                <Box sx={{
              display: 'flex',
              alignItems: 'center'
            }}>
                  {props.company?.billed_first_date ? <Typography>{moment(getTimezoneOffset(props?.company?.billed_first_date)).format('MM/DD/YYYY')}</Typography> : <Typography sx={{
                opacity: 0.5
              }}>None</Typography>}
                </Box>
                <Box className="icons__remove">
                  {props?.company?.billed_first_date ? <Tooltip title={'Edit Billed First Date'}>
                      <IconButton>
                        <EditIcon />
                      </IconButton>
                    </Tooltip> : <Tooltip title={'Add Billed First Date'}>
                      <IconButton>
                        <AddIcon />
                      </IconButton>
                    </Tooltip>}
                </Box>
              </Stack>}
          </Box>
          <Box sx={{
          px: '20px'
        }}>
            <Divider />
          </Box>

          {/* cancel date */}

          <Box className="cardrow" sx={{
          py: '10px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          '&:hover': {
            backgroundColor: '#F3F3F4',
            cursor: 'pointer'
          }
        }} onClick={() => {
          setCompanyAdminModalFocus('cancel_date');
          setCompanyAdminModal(props.company);
        }}>
            <Box sx={{
            px: '20px'
          }}>
              <Typography>Cancel Date</Typography>
            </Box>

            {props?.errors?.cancel_date && <Box sx={{
            display: 'flex',
            alignItems: 'center'
          }}>
                <Typography sx={{
              color: '#EF3C34'
            }}>{props?.errors?.cancel_date}</Typography>
              </Box>}

            {props.loading ? <Box sx={{
            px: '20px',
            width: '30%',
            py: '8px'
          }}>
                <Skeleton variant="rectangular" width="100%" height={20} />
              </Box> : <Stack sx={{
            pr: '6px'
          }} direction={'row'} spacing="4px">
                <Box sx={{
              display: 'flex',
              alignItems: 'center'
            }}>
                  {props.company?.cancel_date ? <Typography>{moment(getTimezoneOffset(props?.company?.cancel_date)).format('MM/DD/YYYY')}</Typography> : <Typography sx={{
                opacity: 0.5
              }}>None</Typography>}
                </Box>
                <Box className="icons__remove">
                  {props?.company?.cancel_date ? <Tooltip title={'Edit Cancel Date'}>
                      <IconButton>
                        <EditIcon />
                      </IconButton>
                    </Tooltip> : <Tooltip title={'Add Cancel Date'}>
                      <IconButton>
                        <AddIcon />
                      </IconButton>
                    </Tooltip>}
                </Box>
              </Stack>}
          </Box>
          <Box sx={{
          px: '20px'
        }}>
            <Divider />
          </Box>

          {/* cancel reasons */}

          <Box className="cardrow" sx={{
          py: '10px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          '&:hover': {
            backgroundColor: '#F3F3F4',
            cursor: 'pointer'
          }
        }} onClick={() => {
          setCompanyAdminModalFocus('cancel_reason');
          setCompanyAdminModal(props.company);
        }}>
            <Box sx={{
            px: '20px'
          }}>
              <Typography>Cancel Reason</Typography>
            </Box>

            {props?.errors?.cancel_reason && <Box sx={{
            display: 'flex',
            alignItems: 'center'
          }}>
                <Typography sx={{
              color: '#EF3C34'
            }}>{props?.errors?.cancel_reason}</Typography>
              </Box>}

            {props.loading ? <Box sx={{
            px: '20px',
            width: '30%',
            py: '8px'
          }}>
                <Skeleton variant="rectangular" width="100%" height={20} />
              </Box> : <Stack sx={{
            pr: '6px'
          }} direction={'row'} spacing="4px">
                <Box sx={{
              display: 'flex',
              alignItems: 'center'
            }}>
                  {props?.company?.cancel_reason_name ? <Typography>{props?.company?.cancel_reason_name}</Typography> : <Typography sx={{
                opacity: 0.5
              }}>None</Typography>}
                </Box>
                <Box className="icons__remove">
                  {props?.company?.cancel_reason_name ? <Tooltip title={'Edit Cancel Reason'}>
                      <IconButton>
                        <EditIcon />
                      </IconButton>
                    </Tooltip> : <Tooltip title={'Add Cancel Reason'}>
                      <IconButton>
                        <AddIcon />
                      </IconButton>
                    </Tooltip>}
                </Box>
              </Stack>}
          </Box>
          <Box sx={{
          px: '20px'
        }}>
            <Divider />
          </Box>

          {/* billed last date */}

          <Box className="cardrow" sx={{
          py: '10px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          '&:hover': {
            backgroundColor: '#F3F3F4',
            cursor: 'pointer'
          }
        }} onClick={() => {
          setCompanyAdminModalFocus('billed_last_date');
          setCompanyAdminModal(props.company);
        }}>
            <Box sx={{
            px: '20px'
          }}>
              <Typography>Billed Last Date</Typography>
            </Box>

            {props?.errors?.billed_last_date && <Box sx={{
            display: 'flex',
            alignItems: 'center'
          }}>
                <Typography sx={{
              color: '#EF3C34'
            }}>{props?.errors?.billed_last_date}</Typography>
              </Box>}

            {props.loading ? <Box sx={{
            px: '20px',
            width: '30%',
            py: '8px'
          }}>
                <Skeleton variant="rectangular" width="100%" height={20} />
              </Box> : <Stack sx={{
            pr: '6px'
          }} direction={'row'} spacing="4px">
                <Box sx={{
              display: 'flex',
              alignItems: 'center'
            }}>
                  {props.company?.billed_last_date ? <Typography>{moment(getTimezoneOffset(props?.company?.billed_last_date)).format('MM/DD/YYYY')}</Typography> : <Typography sx={{
                opacity: 0.5
              }}>None</Typography>}
                </Box>
                <Box className="icons__remove">
                  {props?.company?.billed_last_date ? <Tooltip title={'Edit Billed Last Date'}>
                      <IconButton>
                        <EditIcon />
                      </IconButton>
                    </Tooltip> : <Tooltip title={'Add Billed Last Date'}>
                      <IconButton>
                        <AddIcon />
                      </IconButton>
                    </Tooltip>}
                </Box>
              </Stack>}
          </Box>
          <Box sx={{
          px: '20px'
        }}>
            <Divider />
          </Box>

          {/* customer end date */}

          <Box className="cardrow" sx={{
          py: '10px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          '&:hover': {
            backgroundColor: '#F3F3F4',
            cursor: 'pointer'
          }
        }} onClick={() => {
          setCompanyAdminModalFocus('customer_end_date');
          setCompanyAdminModal(props.company);
        }}>
            <Box sx={{
            px: '20px'
          }}>
              <Typography>Customer End Date</Typography>
            </Box>

            {props?.errors?.customer_end_date && <Box sx={{
            display: 'flex',
            alignItems: 'center'
          }}>
                <Typography sx={{
              color: '#EF3C34'
            }}>{props?.errors?.customer_end_date}</Typography>
              </Box>}

            {props.loading ? <Box sx={{
            px: '20px',
            width: '30%',
            py: '8px'
          }}>
                <Skeleton variant="rectangular" width="100%" height={20} />
              </Box> : <Stack sx={{
            pr: '6px'
          }} direction={'row'} spacing="4px">
                <Box sx={{
              display: 'flex',
              alignItems: 'center'
            }}>
                  {props.company?.customer_end_date ? <Typography>{moment(getTimezoneOffset(props?.company?.customer_end_date)).format('MM/DD/YYYY')}</Typography> : <Typography sx={{
                opacity: 0.5
              }}>None</Typography>}
                </Box>
                <Box className="icons__remove">
                  {props?.company?.customer_end_date ? <Tooltip title={'Edit Customer End Date'}>
                      <IconButton>
                        <EditIcon />
                      </IconButton>
                    </Tooltip> : <Tooltip title={'Add Customer End Date'}>
                      <IconButton>
                        <AddIcon />
                      </IconButton>
                    </Tooltip>}
                </Box>
              </Stack>}
          </Box>
          <Box sx={{
          px: '20px'
        }}>
            <Divider />
          </Box>

          {/* entity data */}

          <Box className="cardrow" sx={{
          py: '10px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          '&:hover': {
            backgroundColor: '#F3F3F4',
            cursor: 'pointer'
          }
        }} onClick={() => {
          setCompanyAdminModalFocus('entity_data');
          setCompanyAdminModal(props.company);
        }}>
            <Box sx={{
            px: '20px'
          }}>
              <Typography sx={{
              minWidth: '120px'
            }}>Entity Data</Typography>
            </Box>

            {props?.errors?.entity_data && <Box sx={{
            display: 'flex',
            alignItems: 'center'
          }}>
                <Typography sx={{
              color: '#EF3C34'
            }}>{props?.errors?.entity_data}</Typography>
              </Box>}

            {/*  Removing this for now.
              {props.loading ? (
                <Box sx={{ px: '20px', width: '30%', py: '8px' }}>
                  <Skeleton variant="rectangular" width="100%" height={20} />
                </Box>
              ) : (
                <Stack sx={{ pr: '6px' }} direction={'row'} spacing="4px">
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {props.company?.entity_data ? (
                      <Typography> {props.company?.entity_data}</Typography>
                    ) : (
                      <Typography sx={{ opacity: 0.5 }}>None</Typography>
                    )}
                  </Box>
                  <Box className="icons__remove">
                    {props?.company?.entity_data ? (
                      <Tooltip title={'Edit Entity Data'}>
                        <IconButton>
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                    ) : (
                      <Tooltip title={'Add Entity Data'}>
                        <IconButton>
                          <AddIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                  </Box>
                </Stack>
              )}
             */}
          </Box>
          <Box sx={{
          px: '20px'
        }}>
            <Divider />
          </Box>

          {/* cohort date */}

          <Box className="cardrow" sx={{
          py: '10px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          '&:hover': {
            backgroundColor: '#F3F3F4',
            cursor: 'pointer'
          }
        }} onClick={() => {
          setCompanyAdminModalFocus('cohort_date');
          setCompanyAdminModal(props.company);
        }}>
            <Box sx={{
            px: '20px'
          }}>
              <Typography>Cohort Date</Typography>
            </Box>

            {props?.errors?.cohort_date && <Box sx={{
            display: 'flex',
            alignItems: 'center'
          }}>
                <Typography sx={{
              color: '#EF3C34'
            }}>{props?.errors?.cohort_date}</Typography>
              </Box>}

            {props.loading ? <Box sx={{
            px: '20px',
            width: '30%',
            py: '8px'
          }}>
                <Skeleton variant="rectangular" width="100%" height={20} />
              </Box> : <Stack sx={{
            pr: '6px'
          }} direction={'row'} spacing="4px">
                <Box sx={{
              display: 'flex',
              alignItems: 'center'
            }}>
                  {props.company?.cohort_date ? <Typography>{moment(getTimezoneOffset(props?.company?.cohort_date)).format('MM/DD/YYYY')}</Typography> : <Typography sx={{
                opacity: 0.5
              }}>None</Typography>}
                </Box>
                <Box className="icons__remove">
                  {props?.company?.cohort_date ? <Tooltip title={'Edit Cohort Date'}>
                      <IconButton>
                        <EditIcon />
                      </IconButton>
                    </Tooltip> : <Tooltip title={'Add Cohort Date'}>
                      <IconButton>
                        <AddIcon />
                      </IconButton>
                    </Tooltip>}
                </Box>
              </Stack>}
          </Box>
          <Box sx={{
          px: '20px'
        }}>
            <Divider />
          </Box>

          {/* Commission end date */}

          <Box className="cardrow" sx={{
          py: '10px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          '&:hover': {
            backgroundColor: '#F3F3F4',
            cursor: 'pointer'
          }
        }} onClick={() => {
          setCompanyAdminModalFocus('commission_end_date');
          setCompanyAdminModal(props.company);
        }}>
            <Box sx={{
            px: '20px'
          }}>
              <Typography>Commission End Date</Typography>
            </Box>

            {props?.errors?.commission_end_date && <Box sx={{
            display: 'flex',
            alignItems: 'center'
          }}>
                <Typography sx={{
              color: '#EF3C34'
            }}>{props?.errors?.commission_end_date}</Typography>
              </Box>}

            {props.loading ? <Box sx={{
            px: '20px',
            width: '30%',
            py: '8px'
          }}>
                <Skeleton variant="rectangular" width="100%" height={20} />
              </Box> : <Stack sx={{
            pr: '6px'
          }} direction={'row'} spacing="4px">
                <Box sx={{
              display: 'flex',
              alignItems: 'center'
            }}>
                  {props.company?.commission_end_date ? <Typography>{moment(getTimezoneOffset(props?.company?.commission_end_date)).format('MM/DD/YYYY')}</Typography> : <Typography sx={{
                opacity: 0.5
              }}>None</Typography>}
                </Box>
                <Box className="icons__remove">
                  {props?.company?.commission_end_date ? <Tooltip title={'Edit Commission Date'}>
                      <IconButton>
                        <EditIcon />
                      </IconButton>
                    </Tooltip> : <Tooltip title={'Add Commission Date'}>
                      <IconButton>
                        <AddIcon />
                      </IconButton>
                    </Tooltip>}
                </Box>
              </Stack>}
          </Box>
          <Box sx={{
          px: '20px'
        }}>
            <Divider />
          </Box>

          {/* commission_percent */}

          <Box className="cardrow" sx={{
          py: '10px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          '&:hover': {
            backgroundColor: '#F3F3F4',
            cursor: 'pointer'
          }
        }} onClick={() => {
          setCompanyAdminModalFocus('commission_percent');
          setCompanyAdminModal(props.company);
        }}>
            <Box sx={{
            px: '20px'
          }}>
              <Typography>Commission Percent</Typography>
            </Box>

            {props?.errors?.commission_percent && <Box sx={{
            display: 'flex',
            alignItems: 'center'
          }}>
                <Typography sx={{
              color: '#EF3C34'
            }}>{props?.errors?.commission_percent}</Typography>
              </Box>}

            {props.loading ? <Box sx={{
            px: '20px',
            width: '30%',
            py: '8px'
          }}>
                <Skeleton variant="rectangular" width="100%" height={20} />
              </Box> : <Stack sx={{
            pr: '6px'
          }} direction={'row'} spacing="4px">
                <Box sx={{
              display: 'flex',
              alignItems: 'center'
            }}>
                  {props.company?.commission_percent ? <Typography> {props.company?.commission_percent}%</Typography> : <Typography sx={{
                opacity: 0.5
              }}>None</Typography>}
                </Box>
                <Box className="icons__remove">
                  {props?.company?.commission_percent ? <Tooltip title={'Edit Commission Percent'}>
                      <IconButton>
                        <EditIcon />
                      </IconButton>
                    </Tooltip> : <Tooltip title={'Add Commission Percent'}>
                      <IconButton>
                        <AddIcon />
                      </IconButton>
                    </Tooltip>}
                </Box>
              </Stack>}
          </Box>
          <Box sx={{
          px: '20px'
        }}>
            <Divider />
          </Box>

          {/* contract_closer */}

          <Box className="cardrow" sx={{
          py: '10px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          '&:hover': {
            backgroundColor: '#F3F3F4',
            cursor: 'pointer'
          }
        }} onClick={() => {
          setCompanyAdminModalFocus('contract_closer');
          setCompanyAdminModal(props.company);
        }}>
            <Box sx={{
            px: '20px'
          }}>
              <Typography>Contract Closer</Typography>
            </Box>

            {props?.errors?.contract_closer && <Box sx={{
            display: 'flex',
            alignItems: 'center'
          }}>
                <Typography sx={{
              color: '#EF3C34'
            }}>{props?.errors?.contract_closer}</Typography>
              </Box>}

            {props.loading ? <Box sx={{
            px: '20px',
            width: '30%',
            py: '8px'
          }}>
                <Skeleton variant="rectangular" width="100%" height={20} />
              </Box> : <Stack sx={{
            pr: '6px'
          }} direction={'row'} spacing="4px">
                <Box sx={{
              display: 'flex',
              alignItems: 'center'
            }}>
                  {props.company?.contract_closer ? <Typography> {props.company?.contract_closer}</Typography> : <Typography sx={{
                opacity: 0.5
              }}>None</Typography>}
                </Box>
                <Box className="icons__remove">
                  {props?.company?.contract_closer ? <Tooltip title={'Edit Contract Closer'}>
                      <IconButton>
                        <EditIcon />
                      </IconButton>
                    </Tooltip> : <Tooltip title={'Add Contract Closer'}>
                      <IconButton>
                        <AddIcon />
                      </IconButton>
                    </Tooltip>}
                </Box>
              </Stack>}
          </Box>
          <Box sx={{
          px: '20px'
        }}>
            <Divider />
          </Box>

          {/* freshsales ID */}

          <Box className="cardrow" sx={{
          py: '10px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          '&:hover': {
            backgroundColor: '#F3F3F4',
            cursor: 'pointer'
          }
        }} onClick={() => {
          setCompanyAdminModalFocus('freshsales_id');
          setCompanyAdminModal(props.company);
        }}>
            <Box sx={{
            px: '20px'
          }}>
              <Typography>Freshsales ID</Typography>
            </Box>

            {props?.errors?.freshsales_id && <Box sx={{
            display: 'flex',
            alignItems: 'center'
          }}>
                <Typography sx={{
              color: '#EF3C34'
            }}>{props?.errors?.freshsales_id}</Typography>
              </Box>}

            {props.loading ? <Box sx={{
            px: '20px',
            width: '30%',
            py: '8px'
          }}>
                <Skeleton variant="rectangular" width="100%" height={20} />
              </Box> : <Stack sx={{
            pr: '6px'
          }} direction={'row'} spacing="4px">
                <Box sx={{
              display: 'flex',
              alignItems: 'center'
            }}>
                  {props.company?.freshsales_id ? <Typography> {props.company?.freshsales_id}</Typography> : <Typography sx={{
                opacity: 0.5
              }}>None</Typography>}
                </Box>
                <Box className="icons__remove">
                  {props?.company?.freshsales_id ? <Tooltip title={'Edit Freshsales ID'}>
                      <IconButton>
                        <EditIcon />
                      </IconButton>
                    </Tooltip> : <Tooltip title={'Add Freshsales ID'}>
                      <IconButton>
                        <AddIcon />
                      </IconButton>
                    </Tooltip>}
                </Box>
              </Stack>}
          </Box>
          <Box sx={{
          px: '20px'
        }}>
            <Divider />
          </Box>

          {/* lead source */}

          <Box className="cardrow" sx={{
          py: '10px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          '&:hover': {
            backgroundColor: '#F3F3F4',
            cursor: 'pointer'
          }
        }} onClick={() => {
          setCompanyAdminModalFocus('lead_source');
          setCompanyAdminModal(props.company);
        }}>
            <Box sx={{
            px: '20px'
          }}>
              <Typography>Lead Source</Typography>
            </Box>

            {props?.errors?.lead_source && <Box sx={{
            display: 'flex',
            alignItems: 'center'
          }}>
                <Typography sx={{
              color: '#EF3C34'
            }}>{props?.errors?.lead_source}</Typography>
              </Box>}

            {props.loading ? <Box sx={{
            px: '20px',
            width: '30%',
            py: '8px'
          }}>
                <Skeleton variant="rectangular" width="100%" height={20} />
              </Box> : <Stack sx={{
            pr: '6px'
          }} direction={'row'} spacing="4px">
                <Box sx={{
              display: 'flex',
              alignItems: 'center'
            }}>
                  {props.company?.lead_source ? <Typography> {props.company?.lead_source}</Typography> : <Typography sx={{
                opacity: 0.5
              }}>None</Typography>}
                </Box>
                <Box className="icons__remove">
                  {props?.company?.lead_source ? <Tooltip title={'Edit Lead Source'}>
                      <IconButton>
                        <EditIcon />
                      </IconButton>
                    </Tooltip> : <Tooltip title={'Add Lead Source'}>
                      <IconButton>
                        <AddIcon />
                      </IconButton>
                    </Tooltip>}
                </Box>
              </Stack>}
          </Box>
          <Box sx={{
          px: '20px'
        }}>
            <Divider />
          </Box>

          {/* Mail Threshold */}

          <Box className="cardrow" sx={{
          py: '10px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          '&:hover': {
            backgroundColor: '#F3F3F4',
            cursor: 'pointer'
          }
        }} onClick={() => {
          setCompanyAdminModalFocus('mail_limit');
          setCompanyAdminModal(props.company);
        }}>
            <Box sx={{
            px: '20px'
          }}>
              <Typography>Mailer Threshold</Typography>
            </Box>

            {props?.errors?.mail_limit && <Box sx={{
            display: 'flex',
            alignItems: 'center'
          }}>
                <Typography sx={{
              color: '#EF3C34'
            }}>{props?.errors?.mail_limit}</Typography>
              </Box>}

            {props.loading ? <Box sx={{
            px: '20px',
            width: '30%',
            py: '8px'
          }}>
                <Skeleton variant="rectangular" width="100%" height={20} />
              </Box> : <Stack sx={{
            pr: '6px'
          }} direction={'row'} spacing="4px">
                <Box sx={{
              display: 'flex',
              alignItems: 'center'
            }}>
                  {props.company?.mail_limit ? <Typography> {props.company?.mail_limit} mailers</Typography> : <Typography sx={{
                opacity: 0.5
              }}>None</Typography>}
                </Box>
                <Box className="icons__remove">
                  {props?.company?.mail_limit ? <Tooltip title={'Edit Mailer Threshold'}>
                      <IconButton>
                        <EditIcon />
                      </IconButton>
                    </Tooltip> : <Tooltip title={'Add Mailer Threshold'}>
                      <IconButton>
                        <AddIcon />
                      </IconButton>
                    </Tooltip>}
                </Box>
              </Stack>}
          </Box>
          <Box sx={{
          px: '20px'
        }}>
            <Divider />
          </Box>

          {/* SMS Threshold */}

          <Box className="cardrow" sx={{
          py: '10px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          '&:hover': {
            backgroundColor: '#F3F3F4',
            cursor: 'pointer'
          }
        }} onClick={() => {
          setCompanyAdminModalFocus('sms_limit');
          setCompanyAdminModal(props.company);
        }}>
            <Box sx={{
            px: '20px'
          }}>
              <Typography>SMS Threshold</Typography>
            </Box>

            {props?.errors?.sms_limit && <Box sx={{
            display: 'flex',
            alignItems: 'center'
          }}>
                <Typography sx={{
              color: '#EF3C34'
            }}>{props?.errors?.sms_limit}</Typography>
              </Box>}

            {props.loading ? <Box sx={{
            px: '20px',
            width: '30%',
            py: '8px'
          }}>
                <Skeleton variant="rectangular" width="100%" height={20} />
              </Box> : <Stack sx={{
            pr: '6px'
          }} direction={'row'} spacing="4px">
                <Box sx={{
              display: 'flex',
              alignItems: 'center'
            }}>
                  {props.company?.sms_limit ? <Typography> {props.company?.sms_limit} segments</Typography> : <Typography sx={{
                opacity: 0.5
              }}>None</Typography>}
                </Box>
                <Box className="icons__remove">
                  {props?.company?.sms_limit ? <Tooltip title={'Edit SMS Threshold'}>
                      <IconButton>
                        <EditIcon />
                      </IconButton>
                    </Tooltip> : <Tooltip title={'Add SMS Threshold'}>
                      <IconButton>
                        <AddIcon />
                      </IconButton>
                    </Tooltip>}
                </Box>
              </Stack>}
          </Box>
          <Box sx={{
          px: '20px'
        }}>
            <Divider />
          </Box>

          {/* Billing Contact */}

          <Box className="cardrow" sx={{
          py: '10px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          '&:hover': {
            backgroundColor: '#F3F3F4',
            cursor: 'pointer'
          }
        }} onClick={() => {
          setCompanyAdminModalFocus('billing_contact');
          setCompanyAdminModal(props.company);
        }}>
            <Box sx={{
            px: '20px'
          }}>
              <Typography>Billing Contact</Typography>
            </Box>

            {props?.errors?.billing_contact_email && <Box sx={{
            display: 'flex',
            alignItems: 'center'
          }}>
                <Typography sx={{
              color: '#EF3C34'
            }}>{props?.errors?.billing_contact_email}</Typography>
              </Box>}

            {props.loading ? <Box sx={{
            px: '20px',
            width: '30%',
            py: '8px'
          }}>
                <Skeleton variant="rectangular" width="100%" height={20} />
              </Box> : <Stack sx={{
            pr: '6px'
          }} direction={'row'} spacing="4px">
                <Box sx={{
              display: 'flex',
              alignItems: 'center'
            }}>
                  {props?.company?.billing_contact_email ? <Typography>{props?.company?.billing_contact_email}</Typography> : <Typography sx={{
                opacity: 0.5
              }}>None</Typography>}
                </Box>
                <Box className="icons__remove">
                  {props?.company?.billing_contact_email ? <Tooltip title={'Edit Billing Contact'}>
                      <IconButton>
                        <EditIcon />
                      </IconButton>
                    </Tooltip> : <Tooltip title={'Add Billing Contact'}>
                      <IconButton>
                        <AddIcon />
                      </IconButton>
                    </Tooltip>}
                </Box>
              </Stack>}
          </Box>
          <Box sx={{
          px: '20px'
        }}>
            <Divider />
          </Box>

          {/* Primary Contact */}

          <Box className="cardrow" sx={{
          py: '10px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          '&:hover': {
            backgroundColor: '#F3F3F4',
            cursor: 'pointer'
          }
        }} onClick={() => {
          setCompanyAdminModalFocus('primary_contact');
          setCompanyAdminModal(props.company);
        }}>
            <Box sx={{
            px: '20px'
          }}>
              <Typography>Primary Contact</Typography>
            </Box>

            {props?.errors?.primary_contact_email && <Box sx={{
            display: 'flex',
            alignItems: 'center'
          }}>
                <Typography sx={{
              color: '#EF3C34'
            }}>{props?.errors?.primary_contact_email}</Typography>
              </Box>}

            {props.loading ? <Box sx={{
            px: '20px',
            width: '30%',
            py: '8px'
          }}>
                <Skeleton variant="rectangular" width="100%" height={20} />
              </Box> : <Stack sx={{
            pr: '6px'
          }} direction={'row'} spacing="4px">
                <Box sx={{
              display: 'flex',
              alignItems: 'center'
            }}>
                  {props?.company?.primary_contact_email ? <Typography>{props?.company?.primary_contact_email}</Typography> : <Typography sx={{
                opacity: 0.5
              }}>None</Typography>}
                </Box>
                <Box className="icons__remove">
                  {props?.company?.primary_contact_email ? <Tooltip title={'Edit Primary Contact'}>
                      <IconButton>
                        <EditIcon />
                      </IconButton>
                    </Tooltip> : <Tooltip title={'Add Primary Contact'}>
                      <IconButton>
                        <AddIcon />
                      </IconButton>
                    </Tooltip>}
                </Box>
              </Stack>}
          </Box>
          <Box sx={{
          px: '20px'
        }}>
            <Divider />
          </Box>
        </Stack>
      </Paper>

      <Dialog scroll="paper" PaperProps={{
      style: {
        borderRadius: '28px',
        //width: '65%',
        maxWidth: '1080px'
        // height: '75%',
      }
    }} open={!!companyAdminModal} onClose={(e, r) => {
      if (r != 'backdropClick') {
        handleCompanyAdminModalClose();
      }
    }}>
        <DialogTitle sx={{
        m: 0,
        p: 2,
        fontSize: 'medium'
      }}>
          <Stack spacing={'20px'}>
            <Box display="flex" flexDirection="row" justifyContent="space-between">
              <Stack direction="row" spacing={'10px'}>
                <Box sx={{
                display: 'flex',
                alignItems: 'center'
              }}>
                  <Typography sx={{
                  fontWeight: '300',
                  lineHeight: '30px',
                  fontSize: '24px',
                  display: 'flex',
                  alignItems: 'center'
                }} variant="h4" component="span">
                    Admin Settings
                  </Typography>
                </Box>
              </Stack>

              <IconButton onClick={() => {
              handleCompanyAdminModalClose();
            }}>
                <CloseIcon />
              </IconButton>
            </Box>
            <Divider />
          </Stack>
        </DialogTitle>

        <DialogContent sx={{
        //overflowY: 'scroll',

        scrollbarWidth: 'thin',
        '&::-webkit-scrollbar': {
          width: '0.4em'
        },
        '&::-webkit-scrollbar-track': {
          background: '#f1f1f1',
          marginTop: '20px',
          marginBottom: '20px'
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: '#888'
        },
        '&::-webkit-scrollbar-thumb:hover': {
          background: '#555'
        }
      }}>
          <Stack sx={{
          pt: 1
        }} spacing={'24px'}>
            <Stack spacing={'24px'} direction={'row'}>
              <DynamicTypeAheadMui label="Status" disableFreeSolo getItems={getCompanyStatuses} getItemsFilters={{
              company: asCompany.id
            }} autoFocus={companyAdminModalFocus === 'status_id'} placeholder="Select a Status" displayProperty="name" keyProperty="id" value={companyAdminModal?.status ? companyAdminModal?.status : null} onChange={item => {
              setCompanyAdminModal({
                ...companyAdminModal,
                status: item?.id || null
              });
            }} />

              <DynamicTypeAheadMui autoFocus={companyAdminModalFocus === 'account_manager'} getItems={getUsers} disableFreeSolo placeholder="Select a Account manager" displayProperty="email" keyProperty="id" value={companyAdminModal?.account_manager ? companyAdminModal?.account_manager : null} label="Account Manager" onChange={item => {
              setCompanyAdminModal({
                ...companyAdminModal,
                account_manager: item?.id || null
              });
            }} />
            </Stack>
            <Stack spacing={'24px'} direction={'row'}>
              <DynamicTypeAheadMui autoFocus={companyAdminModalFocus === 'account_id'} getItems={getAccounts} getItemsFilters={{
              company: asCompany.id
            }} disableFreeSolo placeholder="Select an Account" displayProperty="name" keyProperty="id" value={companyAdminModal?.account || null} label="Account" onChange={item => {
              setCompanyAdminModal({
                ...companyAdminModal,
                account: item?.id || null
              });
            }} />

              <DynamicTypeAheadMui autoFocus={companyAdminModalFocus === 'brand_id'} getItems={getBrands} getItemsFilters={{
              company: asCompany.id
            }} disableFreeSolo placeholder="Select a Brand" displayProperty="name" keyProperty="id" value={companyAdminModal?.brand || null} label="Brand" onChange={item => {
              setCompanyAdminModal({
                ...companyAdminModal,
                brand: item?.id || null
              });
            }} />
            </Stack>

            <Stack spacing={'24px'} direction={'row'}>
              <DynamicTypeAheadMui autoFocus={companyAdminModalFocus === 'naics_id'} getItems={getNAICS} getItemsFilters={{
              company: asCompany.id
            }} disableFreeSolo placeholder="Select a Industry" displayProperty="title" keyProperty="id" value={companyAdminModal?.naics || null} label="Industry" onChange={item => {
              setCompanyAdminModal({
                ...companyAdminModal,
                naics: item?.id || null
              });
            }} />

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker sx={{
                width: '300px'
              }} label="Contract Original Signed Date" autoFocus={companyAdminModalFocus == 'contract_original_signed_date'} value={companyAdminModal?.contract_original_signed_date ? dayjs(getTimezoneOffset(companyAdminModal?.contract_original_signed_date)) : null} onError={newError => setContractOriginalSignedDateError(newError)} slotProps={{
                textField: {
                  helperText: contractOriginalSignedDateError
                },
                field: {
                  clearable: true
                },
                actionBar: {
                  actions: ['today']
                }
              }} onChange={newDate => {
                setCompanyAdminModal({
                  ...companyAdminModal,
                  contract_original_signed_date: newDate ? newDate.format('YYYY-MM-DD') : null
                });
              }} />
              </LocalizationProvider>
            </Stack>

            <Stack spacing={'24px'} direction={'row'}>
              <CustomTextField variant="outlined" sx={{
              width: '300px'
            }} autoFocus={companyAdminModalFocus === 'contract_signed_mrr'} error={isNaN(companyAdminModal?.contract_signed_mrr) || Number(companyAdminModal?.contract_signed_mrr) < 0} helperText={isNaN(companyAdminModal?.contract_signed_mrr) || Number(companyAdminModal?.contract_signed_mrr) < 0 ? 'Please enter a non-negative number' : ''} label="Contract Signed MRR" autoComplete="new-password" InputProps={{
              startAdornment: <InputAdornment position="start">
                      <Stack direction="row" spacing="5px">
                        <Box sx={{
                    display: 'flex',
                    alignItems: 'center'
                  }}>
                          <Typography>$</Typography>
                        </Box>
                      </Stack>
                    </InputAdornment>
            }} value={companyAdminModal?.contract_signed_mrr || ''} onChange={e => setCompanyAdminModal({
              ...companyAdminModal,
              contract_signed_mrr: e.target.value == '' ? null : e.target.value
            })} />

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker sx={{
                width: '300px'
              }} label="Billed First Date" autoFocus={companyAdminModalFocus == 'billed_first_date'} value={companyAdminModal?.billed_first_date ? dayjs(getTimezoneOffset(companyAdminModal?.billed_first_date)) : null} onError={newError => setBilledFirstDateError(newError)} slotProps={{
                textField: {
                  helperText: billedFirstDateError
                },
                field: {
                  clearable: true
                },
                actionBar: {
                  actions: ['today']
                }
              }} onChange={newDate => {
                setCompanyAdminModal({
                  ...companyAdminModal,
                  billed_first_date: newDate ? newDate.format('YYYY-MM-DD') : null
                });
              }} />
              </LocalizationProvider>
            </Stack>

            <Stack spacing={'24px'} direction={'row'}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker sx={{
                width: '300px'
              }} label="Cancel Date" autoFocus={companyAdminModalFocus == 'cancel_date'} value={companyAdminModal?.cancel_date ? dayjs(getTimezoneOffset(companyAdminModal?.cancel_date)) : null} onError={newError => setCancelDateError(newError)} slotProps={{
                textField: {
                  helperText: cancelDateError
                },
                field: {
                  clearable: true
                },
                actionBar: {
                  actions: ['today']
                }
              }} onChange={newDate => {
                setCompanyAdminModal({
                  ...companyAdminModal,
                  cancel_date: newDate ? newDate.format('YYYY-MM-DD') : null
                });
              }} />
              </LocalizationProvider>

              <DynamicTypeAheadMui autoFocus={companyAdminModalFocus === 'cancel_reason'} getItems={getCompanyCancelReasons} getItemsFilters={{
              company: asCompany.id
            }} disableFreeSolo placeholder="Select a Cancel Reason" displayProperty="name" keyProperty="id" value={companyAdminModal?.cancel_reason || null} label="Cancel Reason" onChange={item => {
              setCompanyAdminModal({
                ...companyAdminModal,
                cancel_reason: item?.id || null
              });
            }} />
            </Stack>

            <Stack spacing={'24px'} direction={'row'}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker sx={{
                width: '300px'
              }} label="Billed last Date" autoFocus={companyAdminModalFocus == 'billed_last_date'} value={companyAdminModal?.billed_last_date ? dayjs(getTimezoneOffset(companyAdminModal?.billed_last_date)) : null} onError={newError => setBilledLastDateError(newError)} slotProps={{
                textField: {
                  helperText: billedLastDateError
                },
                field: {
                  clearable: true
                },
                actionBar: {
                  actions: ['today']
                }
              }} onChange={newDate => {
                setCompanyAdminModal({
                  ...companyAdminModal,
                  billed_last_date: newDate ? newDate.format('YYYY-MM-DD') : null
                });
              }} />
              </LocalizationProvider>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker sx={{
                width: '300px'
              }} label="Customer End Date" autoFocus={companyAdminModalFocus == 'customer_end_date'} value={companyAdminModal?.customer_end_date ? dayjs(getTimezoneOffset(companyAdminModal?.customer_end_date)) : null} onError={newError => setCustomerEndDateError(newError)} slotProps={{
                textField: {
                  helperText: customerEndDateError
                },
                field: {
                  clearable: true
                },
                actionBar: {
                  actions: ['today']
                }
              }} onChange={newDate => {
                setCompanyAdminModal({
                  ...companyAdminModal,
                  customer_end_date: newDate ? newDate.format('YYYY-MM-DD') : null
                });
              }} />
              </LocalizationProvider>
            </Stack>
            {/* Hiding this for now
              <Box>
                <CustomTextField
                  variant="outlined"
                  fullWidth
                  maxRows={5}
                  multiline
                  autoFocus={companyAdminModalFocus === 'entity_data'}
                  label="Entity Data"
                  autoComplete="new-password"
                  value={companyAdminModal?.entity_data || ''}
                  onChange={(e) => setCompanyAdminModal({ ...companyAdminModal, entity_data: e.target.value == '' ? null : e.target.value })}
                />
              </Box>
             */}

            <Stack spacing={'24px'} direction={'row'}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker sx={{
                width: '300px'
              }} label="Cohort Date" autoFocus={companyAdminModalFocus == 'cohort_date'} value={companyAdminModal?.cohort_date ? dayjs(getTimezoneOffset(companyAdminModal?.cohort_date)) : null} onError={newError => setCohortDateError(newError)} slotProps={{
                textField: {
                  helperText: cohortDateError
                },
                field: {
                  clearable: true
                },
                actionBar: {
                  actions: ['today']
                }
              }} onChange={newDate => {
                setCompanyAdminModal({
                  ...companyAdminModal,
                  cohort_date: newDate ? newDate.format('YYYY-MM-DD') : null
                });
              }} />
              </LocalizationProvider>

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker sx={{
                width: '300px'
              }} label="Commission End Date" autoFocus={companyAdminModalFocus == 'commission_end_date'} value={companyAdminModal?.commission_end_date ? dayjs(getTimezoneOffset(companyAdminModal?.commission_end_date)) : null} onError={newError => setCommissionEndDateError(newError)} slotProps={{
                textField: {
                  helperText: commissionEndDateError
                },
                field: {
                  clearable: true
                },
                actionBar: {
                  actions: ['today']
                }
              }} onChange={newDate => {
                setCompanyAdminModal({
                  ...companyAdminModal,
                  commission_end_date: newDate ? newDate.format('YYYY-MM-DD') : null
                });
              }} />
              </LocalizationProvider>
            </Stack>

            <Stack spacing={'24px'} direction={'row'}>
              <CustomTextField variant="outlined" sx={{
              width: '300px'
            }} autoFocus={companyAdminModalFocus === 'commission_percent'} error={isNaN(companyAdminModal?.commission_percent) || Number(companyAdminModal?.commission_percent) < 0 || Number(companyAdminModal?.commission_percent) > 100} helperText={isNaN(companyAdminModal?.commission_percent) || Number(companyAdminModal?.commission_percent) < 0 || Number(companyAdminModal?.commission_percent) > 100 ? 'Please enter a number between 0 and 100' : ''} label="Commission Percent" autoComplete="new-password" InputProps={{
              endAdornment: <InputAdornment position="end">
                      <Stack direction="row" spacing="5px">
                        <Box sx={{
                    display: 'flex',
                    alignItems: 'center'
                  }}>
                          <Typography>%</Typography>
                        </Box>
                      </Stack>
                    </InputAdornment>
            }} value={companyAdminModal?.commission_percent || ''} onChange={e => setCompanyAdminModal({
              ...companyAdminModal,
              commission_percent: e.target.value == '' ? null : e.target.value
            })} />

              <CustomTextField variant="outlined" sx={{
              width: '300px'
            }} autoFocus={companyAdminModalFocus === 'contract_closer'} label="Contract Closer" autoComplete="new-password" value={companyAdminModal?.contract_closer || ''} onChange={e => setCompanyAdminModal({
              ...companyAdminModal,
              contract_closer: e.target.value == '' ? null : e.target.value
            })} />
            </Stack>

            <Stack spacing={'24px'} direction={'row'}>
              <CustomTextField variant="outlined" sx={{
              width: '300px'
            }} autoFocus={companyAdminModalFocus === 'freshsales_id'} label="Freshsales ID" autoComplete="new-password" value={companyAdminModal?.freshsales_id || ''} onChange={e => setCompanyAdminModal({
              ...companyAdminModal,
              freshsales_id: e.target.value == '' ? null : e.target.value
            })} />

              <CustomTextField variant="outlined" sx={{
              width: '300px'
            }} autoFocus={companyAdminModalFocus === 'lead_source'} label="Lead Source" autoComplete="new-password" value={companyAdminModal?.lead_source || ''} onChange={e => setCompanyAdminModal({
              ...companyAdminModal,
              lead_source: e.target.value == '' ? null : e.target.value
            })} />
            </Stack>

            <Stack spacing={'24px'} direction={'row'}>
              <CustomTextField variant="outlined" sx={{
              width: '300px'
            }} autoFocus={companyAdminModalFocus === 'mail_limit'} error={isNaN(companyAdminModal?.mail_limit) || Number(companyAdminModal?.mail_limit) < 0} helperText={isNaN(companyAdminModal?.mail_limit) || Number(companyAdminModal?.mail_limit) < 0 ? 'Please enter a non-negative number' : ''} label="Mailer Threshold" autoComplete="new-password" value={companyAdminModal?.mail_limit || ''} onChange={e => setCompanyAdminModal({
              ...companyAdminModal,
              mail_limit: e.target.value == '' ? null : e.target.value
            })} />

              <CustomTextField variant="outlined" sx={{
              width: '300px'
            }} autoFocus={companyAdminModalFocus === 'sms_limit'} error={isNaN(companyAdminModal?.sms_limit) || Number(companyAdminModal?.sms_limit) < 0} helperText={isNaN(companyAdminModal?.sms_limit) || Number(companyAdminModal?.sms_limit) < 0 ? 'Please enter a non-negative number' : ''} label="SMS Threshold" autoComplete="new-password" value={companyAdminModal?.sms_limit || ''} onChange={e => setCompanyAdminModal({
              ...companyAdminModal,
              sms_limit: e.target.value == '' ? null : e.target.value
            })} />
            </Stack>

            <Stack spacing={'24px'} direction={'row'}>
              <DynamicTypeAheadMui autoFocus={companyAdminModalFocus === 'billing_contact'} getItems={getUsers} getItemsFilters={{
              company: asCompany.id
            }} disableFreeSolo placeholder="Select a Billing Contact" displayProperty="email" keyProperty="id" value={companyAdminModal?.billing_contact || null} label="Billing Contact" onChange={item => {
              setCompanyAdminModal({
                ...companyAdminModal,
                billing_contact: item?.id || null
              });
            }} />

              <DynamicTypeAheadMui autoFocus={companyAdminModalFocus === 'primary_contact'} getItems={getUsers} getItemsFilters={{
              company: asCompany.id
            }} disableFreeSolo placeholder="Select a Primary Contact" displayProperty="email" keyProperty="id" value={companyAdminModal?.primary_contact || null} label="Primary Contact" onChange={item => {
              setCompanyAdminModal({
                ...companyAdminModal,
                primary_contact: item?.id || null
              });
            }} />
            </Stack>
          </Stack>
        </DialogContent>

        <DialogActions sx={{
        py: 1
      }}>
          <Stack sx={{
          pr: '24px'
        }} direction={'row'} spacing={'20px'}>
            <Button onClick={() => {
            handleCompanyAdminModalClose();
          }} variant="contained" sx={{
            borderRadius: '20px',
            textTransform: 'none'
          }}>
              Cancel
            </Button>
            <Button disabled={hasInputErrors()} onClick={() => {
            props.updateCompany(companyAdminModal);
            handleCompanyAdminModalClose();
          }} variant="contained" sx={{
            mr: 1,
            borderRadius: '20px',
            textTransform: 'none',
            px: '20px'
          }}>
              Save
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>
    </>;
};