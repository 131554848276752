import * as React from 'react';
const SvgIcon = props => <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <defs>
      <radialGradient id="radial0" cx="0.4" cy="1" r="1" fx="0.4" fy="1" gradientTransform="scale(24)" gradientUnits="userSpaceOnUse">
        <stop offset="0.1" stopColor="#FD5"></stop>
        <stop offset="0.5" stopColor="#FF543E"></stop>
        <stop offset="1" stopColor="#C837AB"></stop>
      </radialGradient>
      <linearGradient id="linear0" x1="0" x2="0.2" y1="0" y2="1" gradientTransform="scale(24)" gradientUnits="userSpaceOnUse">
        <stop offset="0.1" stopColor="#3771C8"></stop>
        <stop offset="0.5" stopColor="#60F" stopOpacity="0"></stop>
      </linearGradient>
    </defs>
    <g id="surface1">
      <path d="M3.602 0h16.796A3.603 3.603 0 0 1 24 3.602v16.796A3.603 3.603 0 0 1 20.398 24H3.602A3.603 3.603 0 0 1 0 20.398V3.602A3.603 3.603 0 0 1 3.602 0m0 0"></path>
      <path fill="url(#radial0)" d="M3.602 0h16.796A3.603 3.603 0 0 1 24 3.602v16.796A3.603 3.603 0 0 1 20.398 24H3.602A3.603 3.603 0 0 1 0 20.398V3.602A3.603 3.603 0 0 1 3.602 0m0 0"></path>
      <path fill="url(#linear0)" d="M3.602 0h16.796A3.603 3.603 0 0 1 24 3.602v16.796A3.603 3.603 0 0 1 20.398 24H3.602A3.603 3.603 0 0 1 0 20.398V3.602A3.603 3.603 0 0 1 3.602 0m0 0"></path>
      <path fill="none" stroke="#FFF" strokeWidth="1.4063999999999999" d="M8.578 4.781h6.844c2.098 0 3.797 1.7 3.797 3.797v6.844c0 2.098-1.7 3.797-3.797 3.797H8.578a3.796 3.796 0 0 1-3.797-3.797V8.578c0-2.098 1.7-3.797 3.797-3.797Zm0 0"></path>
      <path fill="none" stroke="#FFF" strokeWidth="1.4063999999999999" d="M15.375 12a3.376 3.376 0 1 0-6.752.002A3.376 3.376 0 0 0 15.375 12ZM16.547 7.734a.28.28 0 0 0-.281-.28.28.28 0 0 0-.282.28.28.28 0 0 0 .282.282.28.28 0 0 0 .28-.282Zm0 0"></path>
    </g>
  </svg>;
export default SvgIcon;