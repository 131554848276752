import React, { useContext, useState, useEffect } from 'react';
import { UserStateContext } from 'context/user-state-context';
import { addAdwordsAccount, addGoogleAccount, addGoogleBusinessAccount, addShopifyShop, authorizeShopifyShop, deleteFacebookInstall, deleteInstalls, getFacebookInstalls, getFacebookReviewAccountInstalls, deleteFacebookReviewInstall, getFacebookReviewAccounts, getFacebookTokens, getInstalls, postFacebookToken } from '../integrations.resource';
import { Icon, Modal, PageHeaderMui } from 'components';
import { snackbarService } from 'components/mui/snackbar/snackbar-service';
import { ModalTools } from '../modal-tools.component';
import { history } from '../../../root.component';
import moment from 'moment-timezone';
import { Box, Button, IconButton, Paper, Stack, TextField, Tooltip, Typography, Divider, Skeleton, Dialog, DialogActions, DialogContent, DialogTitle, Popper, ClickAwayListener, FormControl, RadioGroup, FormControlLabel, Radio, FormLabel } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { AddIcon, CancelIcon, CheckCircleIcon, CloseIcon, DefaultIntegrationIcon, HandymanIcon, LandscapeDisabledIcon, MoreVertIcon, ShieldPersonIcon, DataFieldsIcon } from 'components/mui';
import { sortBy } from 'lodash';
import queryString from 'query-string';
import { DateTime } from 'luxon';
import { ModalCompanySelectMui } from 'components/modal/modal-company-select-mui.component';
import { DataFieldsModal } from './data-fields-modal.component';
import { ModalContext } from 'context/modal-context';
export const IntegrationsListMui = props => {
  const {
    user,
    asCompany,
    hasPermission,
    hasMultiAccess,
    flags
  } = useContext(UserStateContext);
  const {
    show
  } = useContext(ModalContext);
  const [showTools, setShowTools] = useState(false);
  const [loading, setLoading] = useState(true);
  const [installedIntegrations, setInstalledIntegrations] = useState([]);
  const [stephenTribute, setStephenTribute] = useState(false);
  const [integrationOptionsMenuEl, setIntegrationOptionsMenuEl] = useState(null);
  const [integrationOptionsMenuData, setIntegrationOptionsMenuData] = useState(null);
  const [renderIteration, setRenderIteration] = useState(0);
  const [openConfirmDeleteModal, setOpenConfirmDeleteModal] = useState(null);
  const [openConfirmDeleteFacebookModal, setOpenConfirmDeleteFacebookModal] = useState(null);
  const [openConfirmDeleteFacebookReviewModal, setOpenConfirmDeleteFacebookReviewModal] = useState();
  const [facebookDeleteModalSelection, setFacebookDeleteModalSelection] = useState(null);
  const [openCompanySelectModal, setOpenCompanySelectModal] = useState(null);

  // This is a tribute to Stephen Monson, a developer at Cinch in 2022. If you type his name while on the integrations list page, his tribute will appear. RIP friend.
  useEffect(() => {
    const code = ['s', 't', 'e', 'p', 'h', 'e', 'n'];
    let buffer = [];
    const callback = e => {
      if (e.key === code[buffer.length]) {
        // next key in sequence added
        buffer.push(e.key);
        if (buffer.length === code.length) {
          // WE have success
          setStephenTribute(true);
          //only happen once
          //buffer = [];
          document.removeEventListener('keydown', callback);
        }
      } else {
        // incorrect keys, clear buffer
        buffer = [];
      }
    };
    document.addEventListener('keydown', callback);
    return () => document.removeEventListener('keydown', callback);
  }, []);

  //Watch for any redirects from 3rd party integrations
  useEffect(() => {
    //facebook redirect
    if (props?.match?.params?.redirect === 'fb-redirect') {
      if (!props?.location?.search) {
        const {
          access_token,
          expires_in
        } = queryString.parse(props?.location?.hash.slice(1));
        if (access_token) {
          const seconds = new Date().getTime() / 1000 + parseInt(expires_in);
          const expires_when = DateTime.fromSeconds(seconds).toUTC().toISO();
          postFacebookToken(access_token, expires_when).then(token => {
            snackbarService.popup({
              type: 'success',
              message: 'Successfully connected facebook account'
            });
            history.push('/integrations/manage/facebook');
          }).catch(err => {
            if (err.response.data && err.response.data) {
              snackbarService.popup({
                type: 'error',
                message: err.response.data.non_field_errors[0]
              });
            }
          });
        } else {
          snackbarService.popup({
            type: 'error',
            message: 'Unable to connect account from facebook'
          });
        }
      } else {
        const {
          error
        } = queryString.parse(props?.location?.search);
        if (error) {
          snackbarService.popup({
            type: 'error',
            message: 'Unable to connect account from facebook'
          });
        }
      }
    }

    //google business redirect
    if (props?.match?.params?.redirect === 'google-business-authorize') {
      const params = new URLSearchParams(props?.location?.search);
      if (params.get('error')) {
        snackbarService.popup({
          type: 'error',
          message: 'Unable to link Google My Business account. Please try again. If the problem persists contact support.'
        });
      } else {
        const installId = params.get('install_id');
        if (hasMultiAccess()) {
          setOpenCompanySelectModal({
            id: installId,
            key: 'google_business',
            friendlyName: 'Google Business'
          });
        } else {
          handleGoogleBusinessRedirect({
            id: installId
          }, asCompany.id);
        }
      }
    }

    //google ads redirect
    if (props?.match?.params?.redirect === 'adwords-authorize') {
      const params = new URLSearchParams(props?.location?.search);
      if (params.get('error')) {
        snackbarService.popup({
          type: 'error',
          message: 'Unable to link Google Ads account. Please try again. If the problem persists contact support.'
        });
      } else {
        const installId = params.get('install_id');
        if (hasMultiAccess()) {
          setOpenCompanySelectModal({
            id: installId,
            key: 'google_adwords',
            friendlyName: 'Google Ads'
          });
        } else {
          handleGoogleAdsRedirect({
            id: installId
          }, asCompany.id);
        }
      }
    }

    //google forms redirect
    if (props?.match?.params?.redirect === 'google-authorize') {
      const params = new URLSearchParams(props?.location?.search);
      if (params.get('error')) {
        snackbarService.popup({
          type: 'error',
          message: 'Unable to link Google Forms account. Please try again. If the problem persists contact support.'
        });
      } else {
        const installId = params.get('install_id');
        if (hasMultiAccess()) {
          setOpenCompanySelectModal({
            id: installId,
            key: 'google',
            friendlyName: 'Google Forms'
          });
        } else {
          handleGoogleFormsRedirect({
            id: installId
          }, asCompany.id);
        }
      }
    }

    //shopify redirect
    if (props?.match?.params?.redirect === 'shopify-redirect') {
      const shopName = new URLSearchParams(props?.location?.search).get('shop').replace('.myshopify.com', '');
      if (params.get('error')) {
        snackbarService.popup({
          type: 'error',
          message: 'Unable to link Shopify account. Please try again. If the problem persists contact support.'
        });
      } else {
        authorizeShopifyShop(shopName).then(({
          url
        }) => window.location.href = url);
      }
    } else if (props?.match?.params?.redirect === 'shopify-authorize') {
      const params = new URLSearchParams(props?.location?.search);
      if (params.get('error')) {
        snackbarService.popup({
          type: 'error',
          message: 'Unable to link Shopify account. Please try again. If the problem persists contact support.'
        });
      } else {
        const shopParams = {
          ...Object.fromEntries(params),
          name: params.get('shop').replace('.myshopify.com', '')
        };
        if (hasMultiAccess()) {
          setOpenCompanySelectModal({
            ...shopParams,
            key: 'shopify',
            friendlyName: 'Shopify'
          });
        } else {
          return handleShopifyRedirect(shopParams, asCompany.id);
        }
      }
    }

    //woocommerce redirect
    if (props?.match?.params?.redirect === 'woocommerce-authorize') {
      snackbarService.popup({
        type: 'success',
        message: 'Successfully added shop. Your shop data will start syncing momentarily'
      });
      handleSucessfulRedirectForward(null, 'woocommerce');
    }

    /* We receive a redirect from the integrations-schema-form component for autoops and instantly
       redirec to the lists page. */
    if (props?.match?.params?.redirect == 'autoops') {
      const payload = history.location.state.sneakyPayload;
      history.push(`/integrations/configure/${payload.installId}?credential=${payload.credentialId}`);
    }
  }, []);
  const handleCompanySelectModalSubmit = (integration, companyId) => {
    const map = {
      google_business: handleGoogleBusinessRedirect,
      google_adwords: handleGoogleAdsRedirect,
      shopify: handleShopifyRedirect,
      google: handleGoogleFormsRedirect
    };
    if (map[integration.key]) {
      map[integration.key](integration, companyId);
    }
    setOpenCompanySelectModal(null);
  };
  const handleGoogleFormsRedirect = (install, companyId) => {
    snackbarService.popup({
      type: 'success',
      message: 'Successfully added account. Forms will start syncing momentarily'
    });
    addGoogleAccount(install?.id, companyId).then(result => {
      handleSucessfulRedirectForward(result, 'google');
    }).catch(err => {
      const key = Object.keys(err.response.data)[0];
      snackbarService.popup({
        type: 'error',
        message: err.response.data[key][0]
      });
    });
  };
  const handleGoogleBusinessRedirect = (install, companyId) => {
    snackbarService.popup({
      type: 'success',
      message: "Adding your account. Please don't leave this page. Locations and reviews will start syncing momentarily. Once done, you will be prompted to exclude locations from the sync."
    });
    addGoogleBusinessAccount(install?.id, companyId).then(result => {
      handleSucessfulRedirectForward(result, 'google_business');
    }).catch(err => {
      const key = Object.keys(err.response.data)[0];
      snackbarService.popup({
        type: 'error',
        message: err.response.data[key][0]
      });
    });
  };
  const handleGoogleAdsRedirect = (install, companyId) => {
    addAdwordsAccount(install.id, companyId).then(() => {
      snackbarService.popup({
        type: 'success',
        message: 'Successfully added account. Audiences will start syncing momentarily'
      });
    }).then(adwordsAccount => {
      handleSucessfulRedirectForward(adwordsAccount, 'adwords');
    }).catch(err => {
      const key = Object.keys(err.response.data)[0];
      snackbarService.popup({
        type: 'error',
        message: err?.response?.data[key][0] || 'Unknown error trying to add account. Please try again.'
      });
    });
  };
  const handleSucessfulRedirectForward = (newCredential, integrationKey, urlPayload) => {
    getInstalls({
      company: asCompany.id,
      limit: 1000,
      integration: integrationKey
    }).then(installs => {
      const install = installs?.results.find(install => install.integration === integrationKey);
      if (install && !!newCredential?.id) {
        history.push(`/integrations/configure/${install?.id}?credential=${newCredential?.id}`, {
          urlPayload: urlPayload
        });
      } else {
        history.push(`/integrations/manage/${integrationKey}`);
      }
    });
  };
  const handleShopifyRedirect = ({
    name,
    code,
    hmac,
    timestamp,
    token
  }, companyId) => {
    addShopifyShop(name, code, hmac, timestamp, companyId, token).then(shop => {
      snackbarService.popup({
        type: 'success',
        message: 'Successfully added shop.  Data import will begin shortly and can take several minutes.'
      });
      handleSucessfulRedirectForward(shop, 'shopify', true);
    }).catch(err => {
      const key = Object.keys(err.response.data)[0];
      snackbarService.popup({
        type: 'error',
        message: err.response.data[key][0]
      });
    });
  };
  useEffect(() => {
    if (!user || !asCompany?.id) {
      return;
    }

    // promise numero uno
    const promises = [getInstalls({
      company: asCompany.id,
      limit: 1000,
      view: 'details'
    })];

    // promise numero dos
    if (user?.is_superuser) {
      promises.push(getFacebookInstalls({
        company: asCompany.id,
        view: 'details'
      }));
    } else {
      promises.push(getFacebookInstalls({
        company: asCompany.id
      }));
    }

    // promise numero tres
    promises.push(getFacebookReviewAccountInstalls({
      company: asCompany.id
    }));
    Promise.all(promises).then(([results, facebookInstalls, facebookReviewInstall]) => {
      const resultsWithFacebook = results?.results.filter(i => i?.integration?.status !== 'dev' && (hasPermission('integration.manage_private_integrations') || i?.integration?.status !== 'private'));

      // Check if facebook integration is configured and apply it here
      if (facebookInstalls?.results.length > 0) {
        resultsWithFacebook.push({
          integration: 'facebook',
          id: 'facebook',
          status: facebookInstalls?.results.some(i => i.status == 'configured') ? 'configured' : 'not_configured',
          tokensCount: user.is_superuser ? facebookInstalls.results.reduce((n, {
            tokens
          }) => n + tokens?.length, 0) : facebookInstalls.results.reduce((n, {
            token_count
          }) => n + token_count, 0),
          dataPayload: facebookInstalls.results,
          integration: {
            name: 'Facebook',
            key: 'facebook',
            icon: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACkAAAAoCAYAAABjPNNTAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAALTSURBVHgBzZkxTBRBFIZ/JtdIg4mWGjqwQuzEBBoKm7v2Kkho1JhoZWy4swMLE2MhatQGghUl0GgiBSQaO8RG6VATGyHQcCXsv5M5dvdm92Z2dpb7ks1ddmdv/33z5s177/pOApCDzV1g5w/wPTi2gu9HLeDw+Oz6yBXgYr/8rN0AJoaQmz4bkRTxagNY+BwXZAIF164DjRoweMnqVjORLuJ0TI/Zie0qcnUbuLdUjLgkzUBoo9p9XKbIxyvSej6hr76bybZqqsi7i8DyV5QCBX58lC5U6E6WKZDs7QO3n8tPHR2WnFsH5tdQCFHLJEOUDoarb086z8dELn8JrLgEJ8aH5Oqtjcqwk4Sxtf4m3WoPJ4Fn9fi5ivrCm+bX4USzKkNLFiNXs6+/DBZqdTQe/Ns+yVWc9nZFCTQl6W6hSIpzCTX0vaIEEm65PBShSPqiCw8mUThRa4Y++cEx3EwMp1+jRThLR5GVbeJWvI/jOEsVZjEuvkgYOnTs/JXxLy9r23KWxNYuvDG3CieUX4rNX/DGYQtOKAMK16n2CXcoHuL3AXoabqfCR55YJHv/I9uiCSqlMmXlvrREFK4B2/ygwiTAxpo29Ql/O5lkDPTDGjFwAaXCLMiGwcuByLRA7AsbkZwFzpywLS9dsQl5yoAVFu4LG+Y36h6S9qK6sdwqTWmLVJ0Gk8XDh16b7Tzfeqsff2fxbNfIA7N7Iihw6iZ6Ds6Oyq7CfJJT3mtEk+hQJOuJcYeGUtHQiizmFO0a5/2Mvro7D5KlSFsk1c8a9GV8MzUWtyKJdTBY857ntNNQTU1B19FmYVJQdoAndLW0fpCwGeyLbs/UNqxUSlbGvm7yLJF1M5tHDY+Lif5vYgyBLtCRfz4tdvo5vWycfjJ0K6PMnD9Eoex0sG+Zdz+mxaZvyTBjE5Ot/n1QMNFg4c5++o8gq/n3Qj+u/lqWtdzRwmMYuTgFmxL6hnUmfpQAAAAASUVORK5CYII='
          }
        });
      }
      if (flags.facebook_reviews) {
        // Check if facebook reviews is configured, unfortunately similar to facebook and matches a lot of the same pattern
        if (facebookReviewInstall.results.length > 0) {
          for (const reviewInstall of facebookReviewInstall.results) {
            resultsWithFacebook.push({
              integration: 'facebook_reviews',
              id: 'facebook_reviews',
              status: facebookReviewInstall?.results.some(i => i.status == 'configured') ? 'configured' : 'not_configured',
              installIdentifier: reviewInstall.id,
              integration: {
                name: 'Facebook Reviews',
                key: 'facebook_reviews',
                icon: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACkAAAAoCAYAAABjPNNTAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAALTSURBVHgBzZkxTBRBFIZ/JtdIg4mWGjqwQuzEBBoKm7v2Kkho1JhoZWy4swMLE2MhatQGghUl0GgiBSQaO8RG6VATGyHQcCXsv5M5dvdm92Z2dpb7ks1ddmdv/33z5s177/pOApCDzV1g5w/wPTi2gu9HLeDw+Oz6yBXgYr/8rN0AJoaQmz4bkRTxagNY+BwXZAIF164DjRoweMnqVjORLuJ0TI/Zie0qcnUbuLdUjLgkzUBoo9p9XKbIxyvSej6hr76bybZqqsi7i8DyV5QCBX58lC5U6E6WKZDs7QO3n8tPHR2WnFsH5tdQCFHLJEOUDoarb086z8dELn8JrLgEJ8aH5Oqtjcqwk4Sxtf4m3WoPJ4Fn9fi5ivrCm+bX4USzKkNLFiNXs6+/DBZqdTQe/Ns+yVWc9nZFCTQl6W6hSIpzCTX0vaIEEm65PBShSPqiCw8mUThRa4Y++cEx3EwMp1+jRThLR5GVbeJWvI/jOEsVZjEuvkgYOnTs/JXxLy9r23KWxNYuvDG3CieUX4rNX/DGYQtOKAMK16n2CXcoHuL3AXoabqfCR55YJHv/I9uiCSqlMmXlvrREFK4B2/ygwiTAxpo29Ql/O5lkDPTDGjFwAaXCLMiGwcuByLRA7AsbkZwFzpywLS9dsQl5yoAVFu4LG+Y36h6S9qK6sdwqTWmLVJ0Gk8XDh16b7Tzfeqsff2fxbNfIA7N7Iihw6iZ6Ds6Oyq7CfJJT3mtEk+hQJOuJcYeGUtHQiizmFO0a5/2Mvro7D5KlSFsk1c8a9GV8MzUWtyKJdTBY857ntNNQTU1B19FmYVJQdoAndLW0fpCwGeyLbs/UNqxUSlbGvm7yLJF1M5tHDY+Lif5vYgyBLtCRfz4tdvo5vWycfjJ0K6PMnD9Eoex0sG+Zdz+mxaZvyTBjE5Ot/n1QMNFg4c5++o8gq/n3Qj+u/lqWtdzRwmMYuTgFmxL6hnUmfpQAAAAASUVORK5CYII='
              }
            });
          }
        }
      }
      resultsWithFacebook.map(result => {
        result.sortBy = result?.integration?.name?.toLowerCase();
      });
      setInstalledIntegrations(sortBy(resultsWithFacebook, 'sortBy', 'desc'));
      setLoading(false);
      if (history?.location?.state?.newInstalls) {
        if (history?.location?.state?.newInstalls.length > 1) {
          snackbarService.popup({
            type: 'success',
            message: `${history?.location?.state?.newInstalls.length} integrations have been installed successfully`
          });
        } else {
          snackbarService.popup({
            type: 'success',
            message: `${history?.location?.state?.newInstalls[0]?.name} has been installed successfully`
          });
        }
        const newState = {
          ...history.location.state
        };
        delete newState.newInstalls;
        history.replace({
          ...history.location,
          state: newState
        });
      }
      if (history?.location?.state?.sucessfulConfiguration) {
        snackbarService.popup({
          type: 'success',
          message: `${history?.location?.state?.sucessfulConfiguration} integration has been configured successfully`
        });
        const newState = {
          ...history.location.state
        };
        delete newState.sucessfulConfiguration;
        history.replace({
          ...history.location,
          state: newState
        });
      }
      if (history?.location?.state?.removedIntegration) {
        snackbarService.popup({
          type: 'success',
          message: `${history?.location?.state?.removedIntegration} integration has been removed successfully`
        });
        const newState = {
          ...history.location.state
        };
        delete newState.removedIntegration;
        history.replace({
          ...history.location,
          state: newState
        });
      }
    });
  }, [asCompany?.id, renderIteration]);
  const handleInstalledIntegrationClick = integration => {
    if (hasPermission('integration.view_credential')) {
      if (integration?.integration?.key === 'facebook') {
        history.push('/integrations/manage/facebook');
      } else if (integration?.integration?.key === 'facebook_reviews') {
        history.push('/integrations/manage/facebook_reviews');
      } else {
        history.push(`/integrations/manage/${integration?.id}`);
      }
    } else {
      snackbarService.popup({
        type: 'error',
        message: 'You do not have permission to view this integration'
      });
    }
  };

  // HERE
  const handleIntegrationOptionsMenuOpen = (event, integration) => {
    if (integration.integration.key == 'facebook') {
      const menu = [];
      if (hasPermission('integration.view_credential')) {
        menu.push({
          key: 'edit',
          label: 'Edit',
          icon: <HandymanIcon />,
          onClick: () => {
            history.push('/integrations/manage/facebook');
          }
        });
      }
      if (hasPermission('integration.delete_credential')) {
        menu.push({
          key: 'uninstall',
          label: 'Uninstall',
          icon: <CancelIcon />,
          onClick: () => {
            setOpenConfirmDeleteFacebookModal(integration);
          }
        });
      }
      setIntegrationOptionsMenuData(menu);
      setIntegrationOptionsMenuEl(event.currentTarget);
    } else if (integration.integration.key == 'facebook_reviews') {
      // similar to facebook, but more or less needs to follow the same flow.
      const menu = [];
      if (hasPermission('integration.view_credential')) {
        menu.push({
          key: 'edit',
          label: 'Edit',
          icon: <HandymanIcon />,
          onClick: () => {
            history.push('/integrations/manage/facebook_reviews');
          }
        });
      }
      if (hasPermission('integration.delete_credential')) {
        menu.push({
          key: 'uninstall',
          label: 'Uninstall',
          icon: <CancelIcon />,
          onClick: () => {
            setOpenConfirmDeleteFacebookReviewModal(integration);
          }
        });
      }
      setIntegrationOptionsMenuData(menu);
      setIntegrationOptionsMenuEl(event.currentTarget);
    } else {
      const menu = [];
      if (hasPermission('integration.view_credential')) {
        menu.push({
          key: 'configure',
          label: hasPermission('integration.add_credential') ? 'Configure' : 'View',
          icon: <HandymanIcon />,
          onClick: () => {
            handleInstalledIntegrationClick(integration);
          }
        });
      }
      if (integration?.integration?.has_fields && hasPermission('organization.change_company') && flags?.integration_fields) {
        menu.push({
          key: 'data-fields',
          label: 'Data Fields',
          icon: <DataFieldsIcon />,
          onClick: () => {
            show(DataFieldsModal, {
              integration
            }).then(() => {}).catch(() => {});
          }
        });
      }
      if (hasPermission('integration.delete_credential')) {
        menu.push({
          key: 'uninstall',
          label: 'Uninstall',
          icon: <CancelIcon />,
          onClick: () => {
            setOpenConfirmDeleteModal(integration);
          }
        });
      }
      setIntegrationOptionsMenuData(menu);
      setIntegrationOptionsMenuEl(event.currentTarget);
    }
  };
  const handleIntegrationOptionsMenuClose = () => {
    setIntegrationOptionsMenuData(null);
    setIntegrationOptionsMenuEl(null);
  };
  const handleDeleteInstall = install => {
    const promises = [];
    if (install?.integration?.key == 'facebook') {
      promises.push(deleteFacebookInstall(facebookDeleteModalSelection || install.dataPayload[0].id));
    } else if (install?.integration?.key === 'facebook_reviews') {
      promises.push(deleteFacebookReviewInstall(install.installIdentifier));
    } else {
      promises.push(deleteInstalls(install.id));
    }
    Promise.all(promises).then(() => {
      setRenderIteration(i => i + 1);
      setFacebookDeleteModalSelection(null);
      snackbarService.popup({
        type: 'success',
        message: `Integration ${install.integration?.name} has been removed successfully!`
      });
    }).catch(error => {
      snackbarService.popup({
        type: 'error',
        message: 'There was an error removing the integration.'
      });
    });
  };
  return <>
      <div className="wrapper" style={{
      height: !!loading.length ? '100vh' : 'auto'
    }}>
        <PageHeaderMui type="Integrations and Apps" icon={<Icon name="custom-call_merge" size={34} />} />
        <Stack sx={{
        p: '20px'
      }} spacing={'20px'}>
          <Stack direction="row" spacing="20px" sx={{
          width: '100%',
          justifyContent: 'right'
        }}>
            <Button sx={{
            backgroundColor: '#F3F3F4',
            '&:hover': {
              backgroundColor: '#F3F3F4'
            }
          }} variant="contained" onClick={() => setShowTools(true)}>
              <Box sx={{
              py: '5px',
              px: '12px'
            }}>
                <Typography sx={{
                color: '#3898D9'
              }}>Tools </Typography>
              </Box>
            </Button>
            {hasPermission('integration.add_credential') && <Button variant="contained" onClick={e => {
            history.push('/integrations/add-new');
          }}>
                <Stack direction="row" spacing="8px">
                  <Box sx={{
                display: 'flex',
                alignItems: 'center'
              }}>
                    <AddIcon fill="#fff" />
                  </Box>
                  <Box sx={{
                display: 'flex',
                alignItems: 'center'
              }}>Install New</Box>
                </Stack>
              </Button>}
          </Stack>

          <Paper sx={{
          borderRadius: '14px',
          minHeight: '75vh'
        }}>
            <Stack sx={{
            pt: '8px'
          }} spacing={'20px'}>
              {loading ? <Grid sx={{
              px: '8px'
            }} container spacing={{
              xs: 2,
              sm: 2,
              md: 3,
              lg: 3
            }} columns={{
              xs: 4,
              sm: 12,
              md: 12,
              lg: 12
            }}>
                  {Array(3).fill(null).map((row, index) => <Grid xs="auto" sm="auto" md="auto" lg="auto" key={index}>
                        <Box sx={{
                  p: '20px',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  border: '1px solid #E8EAED',
                  borderRadius: '8px',
                  width: '315px',
                  backgroundColor: '#fff'
                }}>
                          <Stack spacing="36px">
                            <Stack direction="row" spacing={'16px'}>
                              <Box>
                                <DefaultIntegrationIcon />
                              </Box>
                              <Box sx={{
                        display: 'flex',
                        alignItems: 'center'
                      }}>
                                <Skeleton variant="rounded" width={210} height={20} />
                              </Box>
                            </Stack>
                            <Box sx={{
                      display: 'flex',
                      alignItems: 'center',
                      pb: '12px'
                    }}>
                              <Skeleton variant="rounded" width={270} height={20} />
                            </Box>
                          </Stack>
                        </Box>
                      </Grid>)}
                </Grid> : <Grid sx={{
              maxWidth: '85vw',
              px: '8px'
            }} container spacing={{
              xs: 2,
              sm: 2,
              md: 3,
              lg: 3
            }} columns={{
              xs: 4,
              sm: 12,
              md: 12,
              lg: 12
            }}>
                  {installedIntegrations.length > 0 ? <>
                      {installedIntegrations.map(integration => <Grid xs="auto" sm="auto" md="auto" lg="auto" key={integration?.id}>
                          <Box onClick={() => {
                    handleInstalledIntegrationClick(integration);
                  }} sx={{
                    p: '20px',
                    display: 'flex',
                    alignItems: 'center',
                    border: '1px solid #E8EAED',
                    borderRadius: '8px',
                    width: '315px',
                    cursor: 'pointer',
                    '&:hover': {
                      boxShadow: ' 0px 1px 8px 0px rgba(0, 0, 0, 0.15), 0px 1px 8px 0px rgba(0, 0, 0, 0.30)'
                    }
                  }}>
                            <Stack spacing="36px" sx={{
                      width: '100%'
                    }}>
                              <Box sx={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                      }}>
                                <Stack direction="row" spacing={'16px'}>
                                  <Box>
                                    {integration?.integration?.icon ? <img src={integration?.integration?.icon} height="40" width="40" alt={integration?.integration?.name} /> : <DefaultIntegrationIcon />}
                                  </Box>
                                  <Box sx={{
                            display: 'flex',
                            alignItems: 'center'
                          }}>
                                    <Typography> {integration?.integration?.name}</Typography>
                                  </Box>
                                </Stack>
                                <IconButton onClick={e => {
                          e.stopPropagation();
                          if (!!integrationOptionsMenuData) {
                            handleIntegrationOptionsMenuClose();
                          } else {
                            handleIntegrationOptionsMenuOpen(e, integration);
                          }
                        }}>
                                  <MoreVertIcon />
                                </IconButton>
                              </Box>

                              <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                      }}>
                                <Stack direction={'row'} spacing="16px">
                                  {integration?.status && <>
                                      {integration?.status === 'configured' && <Tooltip title="You have configured this integration">
                                          <Stack direction="row" spacing="8px" sx={{
                                p: '7px',
                                borderRadius: '8px',
                                backgroundColor: '#C5EAD7'
                              }}>
                                            <CheckCircleIcon size="sm" fill="#3EB87B" />
                                            <Box sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  pr: '4px'
                                }}>
                                              <Typography sx={{
                                    color: '#3EB87B'
                                  }}>Configured</Typography>
                                            </Box>
                                          </Stack>
                                        </Tooltip>}
                                      {integration?.status === 'not_configured' && <Tooltip title="You have not yet configured this integration. To use it, please click on it and fill out the required information">
                                          <Stack direction="row" spacing="8px" sx={{
                                display: 'flex',
                                alignItems: 'center',
                                p: '7px',
                                borderRadius: '8px',
                                backgroundColor: '#F3F3F4'
                              }}>
                                            <LandscapeDisabledIcon fill="#616161" />
                                            <Box sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  pr: '4px'
                                }}>
                                              <Typography sx={{
                                    color: '#616161'
                                  }}>Not Configured</Typography>
                                            </Box>
                                          </Stack>
                                        </Tooltip>}

                                      {integration?.status === 'auto' && <Tooltip title="This integration was automatically configured. It may be ready for use or may
                                    require additional configuration elsewhere.">
                                          <Stack direction="row" spacing="8px" sx={{
                                p: '7px',
                                borderRadius: '8px',
                                backgroundColor: '#C5EAD7'
                              }}>
                                            <CheckCircleIcon size="sm" fill="#3EB87B" />
                                            <Box sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  pr: '4px'
                                }}>
                                              <Typography sx={{
                                    color: '#3EB87B'
                                  }}>Auto</Typography>
                                            </Box>
                                          </Stack>
                                        </Tooltip>}
                                    </>}
                                  {integration?.credentials?.length > 1 ? <Tooltip title={'You have ' + integration?.credentials?.length + ' credentials currently added'}>
                                      <Box sx={{
                              py: '7px',
                              px: '10px',
                              borderRadius: '8px',
                              backgroundColor: '#C2E7FF'
                            }}>
                                        {integration?.credentials?.length}
                                      </Box>
                                    </Tooltip> : <>
                                      {user?.is_superuser && integration?.tokensCount > 1 && <Tooltip title={'Super User only: This company has ' + integration?.tokensCount + ' credentials currently added. Click to to view them'}>
                                          <Box sx={{
                                py: '7px',
                                px: '10px',
                                borderRadius: '8px',
                                backgroundColor: '#C2E7FF'
                              }}>
                                            {integration?.tokensCount}
                                          </Box>
                                        </Tooltip>}
                                    </>}
                                </Stack>

                                {user?.is_superuser && integration?.integration?.status == 'private' && <Tooltip title="This integration is only shown for super users only">
                                    <Box sx={{
                            p: '7px',
                            borderRadius: '8px',
                            backgroundColor: '#C2E7FF'
                          }}>
                                      <ShieldPersonIcon size="sm" fill="#3898D9" />
                                    </Box>
                                  </Tooltip>}
                              </Box>
                            </Stack>
                          </Box>
                        </Grid>)}
                    </> : <Box sx={{
                width: '100%',
                display: 'flex',
                alignContent: 'center',
                alignItems: 'center',
                justifyContent: 'center',
                justifyItems: 'center'
              }}>
                      <Stack spacing="26px" sx={{
                  pt: '80px',
                  display: 'flex',
                  alignItems: 'center',
                  pl: '24px',
                  pt: '24px'
                }}>
                        <Typography sx={{
                    fontWeight: '300',
                    lineHeight: '16.8px',
                    fontSize: '20px'
                  }}>
                          No integrations installed
                        </Typography>
                        <Typography sx={{
                    color: 'rgba(29, 37, 45, 0.70)',
                    fontWeight: '400',
                    lineHeight: '16.8px',
                    fontSize: '14px'
                  }}>
                          Click "Install new" to get started
                        </Typography>
                      </Stack>
                    </Box>}
                </Grid>}
            </Stack>
          </Paper>
        </Stack>
      </div>

      <ModalTools open={!!showTools} onClose={() => setShowTools(false)} />

      <Modal open={stephenTribute} onClose={() => setStephenTribute(false)} allowBackdropClick title="Tribute to Stephen">
        <div className="modal__body">
          <div> Dedicated to the Best Integrations Developer Ever </div>
          <div>We will always miss you</div>
          <div>Signed, Your Friends at Cinch</div>
          <div> This modal doesnt have css, just the way Stephen would have liked it</div>
          <div>Easter eggs Stephen hid in the api response of an integation he wrote:</div>
          <div>"You're beautiful" — Your mom, probably... </div>
          <div>"Love the 'stache" — Pringles man, in your dreams</div>
          <div>You're one in 29...</div>
        </div>

        <div className="modal__actions">
          <Button actionType="flat" onClick={() => setStephenTribute(false)}>
            Close
          </Button>
        </div>
      </Modal>

      <Popper anchorEl={integrationOptionsMenuEl} open={!!integrationOptionsMenuData} onClose={handleIntegrationOptionsMenuClose} placement="bottom-end" sx={{
      zIndex: 200
    }}>
        <ClickAwayListener onClickAway={handleIntegrationOptionsMenuClose}>
          <Paper elevation={0} sx={{
          borderRadius: '16px',
          overflow: 'visible',
          filter: 'drop-shadow(0px 0px 4px rgba(0,0,0,0.25))'
        }}>
            <Stack sx={{
            py: '8px'
          }}>
              {integrationOptionsMenuData?.map(item => <Stack spacing="12px" direction={'row'} key={item.key} onClick={item.onClick} sx={{
              width: '100%',
              justifyContent: 'flex-start',
              textTransform: 'none',
              cursor: 'pointer',
              padding: '16px',
              '&:hover': {
                backgroundColor: '#F3F3F4'
              }
            }}>
                  <Box> {item.icon}</Box>

                  <Box sx={{
                display: 'flex',
                alignItems: 'center'
              }}>{item.label}</Box>
                </Stack>)}
            </Stack>
          </Paper>
        </ClickAwayListener>
      </Popper>

      {/* Remove facebook modal*/}
      <Dialog scroll="paper" PaperProps={{
      style: {
        borderRadius: '28px'
      }
    }} open={!!openConfirmDeleteModal} onClose={(e, r) => {
      setOpenConfirmDeleteModal(null);
    }}>
        <DialogTitle sx={{
        m: 0,
        p: 2,
        fontSize: 'medium'
      }}>
          <Stack spacing={'20px'}>
            <Box display="flex" flexDirection="row" justifyContent="space-between">
              <Stack direction="row" spacing={'10px'}>
                <Box sx={{
                display: 'flex',
                alignItems: 'center'
              }}>
                  <Typography sx={{
                  fontWeight: '300',
                  lineHeight: '30px',
                  fontSize: '24px',
                  display: 'flex',
                  alignItems: 'center'
                }} variant="h4" component="span">
                    Confirm Uninstall Integration
                  </Typography>
                </Box>
              </Stack>

              <IconButton onClick={() => {
              setOpenConfirmDeleteModal(null);
            }}>
                <CloseIcon />
              </IconButton>
            </Box>
            <Divider />
          </Stack>
        </DialogTitle>

        <DialogContent sx={{
        scrollbarWidth: 'thin',
        '&::-webkit-scrollbar': {
          width: '0.4em'
        },
        '&::-webkit-scrollbar-track': {
          background: '#f1f1f1',
          marginTop: '20px',
          marginBottom: '20px'
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: '#888'
        },
        '&::-webkit-scrollbar-thumb:hover': {
          background: '#555'
        }
      }}>
          <Stack sx={{
          pt: 1
        }} spacing={'24px'}>
            <Box>{'Are you sure you want to uninstall ' + openConfirmDeleteModal?.integration?.name + '?'} </Box>
            {openConfirmDeleteModal?.credentials?.length > 0 && <Box>
                <div>
                  This will delete the credentials you have added for this integration and stop Cinch from receiving data on this
                  integration for your company
                </div>
              </Box>}
          </Stack>
        </DialogContent>

        <DialogActions sx={{
        pt: 1,
        pb: 2
      }}>
          <Stack sx={{
          pr: '24px'
        }} direction={'row'} spacing={'20px'}>
            <Button onClick={() => {
            setOpenConfirmDeleteModal(false);
          }} variant="contained" sx={{
            backgroundColor: '#F3F3F4',
            '&:hover': {
              backgroundColor: '#F3F3F4'
            }
          }}>
              <Box sx={{
              py: '5px',
              px: '12px'
            }}>
                <Typography sx={{
                color: '#3898D9'
              }}>Cancel </Typography>
              </Box>
            </Button>
            <Button onClick={() => {
            handleDeleteInstall(openConfirmDeleteModal);
            setOpenConfirmDeleteModal(null);
          }} variant="contained" sx={{
            backgroundColor: '#3898D9',
            '&:hover': {
              backgroundColor: '#3898D9'
            }
          }}>
              <Box sx={{
              py: '5px',
              px: '12px'
            }}>
                <Typography sx={{
                color: '#fff'
              }}> Uninstall</Typography>
              </Box>
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>

      <Dialog scroll="paper" PaperProps={{
      style: {
        borderRadius: '28px'
      }
    }} open={!!openConfirmDeleteFacebookModal} onClose={(e, r) => {
      setOpenConfirmDeleteFacebookModal(null);
    }}>
        <DialogTitle sx={{
        m: 0,
        p: 2,
        fontSize: 'medium'
      }}>
          <Stack spacing={'20px'}>
            <Box display="flex" flexDirection="row" justifyContent="space-between">
              <Stack direction="row" spacing={'10px'}>
                <Box sx={{
                display: 'flex',
                alignItems: 'center'
              }}>
                  <Typography sx={{
                  fontWeight: '300',
                  lineHeight: '30px',
                  fontSize: '24px',
                  display: 'flex',
                  alignItems: 'center'
                }} variant="h4" component="span">
                    Confirm Uninstall Integration
                  </Typography>
                </Box>
              </Stack>

              <IconButton onClick={() => {
              setOpenConfirmDeleteFacebookModal(null);
            }}>
                <CloseIcon />
              </IconButton>
            </Box>
            <Divider />
          </Stack>
        </DialogTitle>

        <DialogContent sx={{
        scrollbarWidth: 'thin',
        '&::-webkit-scrollbar': {
          width: '0.4em'
        },
        '&::-webkit-scrollbar-track': {
          background: '#f1f1f1',
          marginTop: '20px',
          marginBottom: '20px'
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: '#888'
        },
        '&::-webkit-scrollbar-thumb:hover': {
          background: '#555'
        }
      }}>
          <Stack sx={{
          pt: 1
        }} spacing={'24px'}>
            <Box>{'Are you sure you want to uninstall Facebook?'} </Box>

            {user?.is_superuser ? <Stack>
                <Box>Super user only uninstall</Box>
                <Box> Choose which facebook install and token to delete</Box>

                <FormControl>
                  <FormLabel id="user-selector-radio-buttons-group">User</FormLabel>
                  <RadioGroup name="user-selector-radio-buttons-group" value={facebookDeleteModalSelection} onChange={e => {
                setFacebookDeleteModalSelection(e.target.value);
              }}>
                    {openConfirmDeleteFacebookModal?.dataPayload?.map((install, index) => <FormControlLabel sx={{
                  py: '8px'
                }} key={index} control={<Radio />} value={install.id} label={<Stack>
                            <Box>
                              <Typography>
                                User: {install.firstname} {install.lastname}
                              </Typography>
                              <Typography>{'Num tokens: ' + install.tokens.length}</Typography>
                            </Box>
                            <Stack spacing="8px">
                              {install.tokens.map((token, index) => <Box>
                                  <Typography> {'Token ' + (index + 1) + ':'} </Typography>
                                  <Typography> {'Token name: ' + token.name} </Typography>
                                  <Typography>
                                    {'Expires When: ' + moment(token.expires_when).local().format('MMM D, YYYY h:mm A')}
                                  </Typography>
                                </Box>)}
                            </Stack>
                          </Stack>} />)}
                  </RadioGroup>
                </FormControl>
              </Stack> : <>
                {openConfirmDeleteFacebookModal?.tokensCount > 0 && <Stack spacing="16px">
                    <Box>
                      This facebook install has existing facebook credentials attached to it. If you uninstall this integration, these
                      tokens will also be deleted
                    </Box>
                    {hasMultiAccess && <Stack spacing="16px">
                        <Box>
                          Also by uninstalling, your facebook credentials will also be removed from all companies you have access to in
                          Cinch
                        </Box>
                        {user.companies.map(company => <Box key={company.id}>{company.name}</Box>)}
                      </Stack>}
                  </Stack>}
              </>}
          </Stack>
        </DialogContent>

        <DialogActions sx={{
        pt: 1,
        pb: 2
      }}>
          <Stack sx={{
          pr: '24px'
        }} direction={'row'} spacing={'20px'}>
            <Button onClick={() => {
            setOpenConfirmDeleteFacebookModal(false);
          }} variant="contained" sx={{
            backgroundColor: '#F3F3F4',
            '&:hover': {
              backgroundColor: '#F3F3F4'
            }
          }}>
              <Box sx={{
              py: '5px',
              px: '12px'
            }}>
                <Typography sx={{
                color: '#3898D9'
              }}>Cancel </Typography>
              </Box>
            </Button>
            <Button onClick={() => {
            handleDeleteInstall(openConfirmDeleteFacebookModal);
            setOpenConfirmDeleteFacebookModal(null);
          }} variant="contained" sx={{
            backgroundColor: '#3898D9',
            '&:hover': {
              backgroundColor: '#3898D9'
            }
          }}>
              <Box sx={{
              py: '5px',
              px: '12px'
            }}>
                <Typography sx={{
                color: '#fff'
              }}> Uninstall</Typography>
              </Box>
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>

      {/* START HERE */}
      {/* Remove facebook reviews modal*/}
      <Dialog scroll="paper" PaperProps={{
      style: {
        borderRadius: '28px'
      }
    }} open={!!openConfirmDeleteFacebookReviewModal} onClose={(e, r) => {
      setOpenConfirmDeleteFacebookReviewModal();
    }}>
        <DialogTitle sx={{
        m: 0,
        p: 2,
        fontSize: 'medium'
      }}>
          <Stack spacing={'20px'}>
            <Box display="flex" flexDirection="row" justifyContent="space-between">
              <Stack direction="row" spacing={'10px'}>
                <Box sx={{
                display: 'flex',
                alignItems: 'center'
              }}>
                  <Typography sx={{
                  fontWeight: '300',
                  lineHeight: '30px',
                  fontSize: '24px',
                  display: 'flex',
                  alignItems: 'center'
                }} variant="h4" component="span">
                    Confirm Uninstall Integration
                  </Typography>
                </Box>
              </Stack>

              <IconButton onClick={() => {
              setOpenConfirmDeleteFacebookReviewModal();
            }}>
                <CloseIcon />
              </IconButton>
            </Box>
            <Divider />
          </Stack>
        </DialogTitle>

        <DialogContent sx={{
        scrollbarWidth: 'thin',
        '&::-webkit-scrollbar': {
          width: '0.4em'
        },
        '&::-webkit-scrollbar-track': {
          background: '#f1f1f1',
          marginTop: '20px',
          marginBottom: '20px'
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: '#888'
        },
        '&::-webkit-scrollbar-thumb:hover': {
          background: '#555'
        }
      }}>
          <Stack sx={{
          pt: 1
        }} spacing={'24px'}>
            <Box>{'Are you sure you want to uninstall Facebook?'} </Box>
          </Stack>
        </DialogContent>

        <DialogActions sx={{
        pt: 1,
        pb: 2
      }}>
          <Stack sx={{
          pr: '24px'
        }} direction={'row'} spacing={'20px'}>
            <Button onClick={() => {
            setOpenConfirmDeleteFacebookReviewModal();
          }} variant="contained" sx={{
            backgroundColor: '#F3F3F4',
            '&:hover': {
              backgroundColor: '#F3F3F4'
            }
          }}>
              <Box sx={{
              py: '5px',
              px: '12px'
            }}>
                <Typography sx={{
                color: '#3898D9'
              }}>Cancel </Typography>
              </Box>
            </Button>
            <Button onClick={() => {
            handleDeleteInstall(openConfirmDeleteFacebookReviewModal);
            setOpenConfirmDeleteFacebookReviewModal();
          }} variant="contained" sx={{
            backgroundColor: '#3898D9',
            '&:hover': {
              backgroundColor: '#3898D9'
            }
          }}>
              <Box sx={{
              py: '5px',
              px: '12px'
            }}>
                <Typography sx={{
                color: '#fff'
              }}> Uninstall</Typography>
              </Box>
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>

      <ModalCompanySelectMui open={!!openCompanySelectModal} onClose={() => {
      setOpenCompanySelectModal(null);
    }} onSubmit={companyId => {
      const integration = openCompanySelectModal;
      handleCompanySelectModalSubmit(integration, companyId);
    }} onCancel={() => snackbarService.popup({
      type: 'error',
      message: `You must select a company to link your ${openCompanySelectModal?.friendlyName} account`
    })}>
        <p>Select which company to link your {openCompanySelectModal?.friendlyName} account.</p>
      </ModalCompanySelectMui>
    </>;
};