import React, { useState, useEffect, useContext, useRef } from 'react';
import { m } from 'kremling';
import moment from 'moment-timezone';
import { UserStateContext } from 'context/user-state-context';
import { Button, Dropdown, DynamicTypeAhead, TimeSelector, Loader, ModalDialog, Icon, Toggle } from 'components';
import { createSmsBroadcast, updateSmsBroadcast, getSegments, getEmailTemplates, getSegmentCount, getPhoneNumbers, deleteSmsBroadcast } from '../../../shared/common.api';
import { Modal } from '../../../components/modal/modal.component';
import { Calendar } from 'components/calendar/calendar.component';
import { StaticTypeAhead } from '../../../components/static-type-ahead/static-type-ahead.component';
import { fileSize } from 'components/file-viewer/utils';
import utils from '../../../shared/utils';
import classnames from 'classnames';
import { Emoji } from '../../../components/emoji/emoji.component';
import { SegmentedMessage, GraphemeSplitter } from 'shared/segment-calculator';
import { SmsTemplatesMenu } from 'components/templates/sms-templates.component';
import { ModalFileViewer2 } from 'components/file-viewer/modal-file-viewer.component-mui';
import { Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Stack, Tooltip, Typography, Button as MuiButton, Checkbox, FormGroup, FormControlLabel, List, ListItemButton, ListItemText } from '@mui/material';
import { CloseIcon, DeleteIcon } from 'components/mui';
/**
 * Get the broadcast send time.
 *
 * @param {str} sendWhen "now" or "later"
 * @param {any} broadcast form data
 * @param {moment} selectedDate date if we're sending "later"
 * @param {zone, label} broadcastTypeTimezoneHandler function to handle which setBroadcast to use based on the zone and label from filteredTimezones
 * @returns
 */

const getStartTime = (sendWhen, broadcast, selectedDate) => {
  let timeToUse;
  if (sendWhen === 'now') {
    timeToUse = moment().tz(broadcast.schedule_timezone).add(5, 'minutes');
  } else {
    const earliestTime = moment().tz(broadcast.schedule_timezone).add(5, 'minutes');
    const scheduledTime = selectedDate ? moment.tz(selectedDate.format('YYYY-MM-DD HH:mm:ss'), broadcast.schedule_timezone) : null;
    timeToUse = !scheduledTime || scheduledTime < earliestTime ? earliestTime : scheduledTime;
  }
  return timeToUse;
};
const style = {
  textareaFooter: {
    color: '#f28837',
    position: 'relative',
    bottom: '0px',
    left: '8px'
  }
};
const SPLITTER = new GraphemeSplitter();
export const BroadcastSmsModal = props => {
  const {
    asCompany,
    hasPermission,
    companyFields
  } = useContext(UserStateContext);
  const [broadcast, setBroadcast] = useState({
    ...props.broadcast,
    drip: props.broadcast.id && props.broadcast.drip_end !== null,
    send_window: props.broadcast.id && props.broadcast.window_start !== null,
    schedule_timezone: props.broadcast && props.broadcast.schedule_timezone !== null ? moment.tz.guess() : null,
    window_timezone: props.broadcast && props.broadcast.window_timezone !== null ? moment.tz.guess() : null
  });
  const [loading, setLoading] = useState(false);
  const [estimatedSends, setEstimatedSends] = useState(null);
  const [estimatedError, setEstimatedError] = useState(false);
  const [sendWhen, setSendWhen] = useState(props.broadcast && props.broadcast.id ? 'later' : 'now');
  const [selectedDate, setSelectedDate] = useState(props.broadcast && props.broadcast.id ? moment(props.broadcast.schedule_when).tz(props.broadcast.schedule_timezone) : null);
  const [targetDate, setTargetDate] = useState(props.broadcast && props.broadcast.id && props.broadcast.drip_end ? moment(props.broadcast.drip_end).tz(props.broadcast.drip_timezone) : null);
  const [numbers, setNumbers] = useState([]);
  const selectedFrom = numbers.find(n => n.id === props.broadcast.from_phone);
  const [optAgreeOpen, setOptAgreeOpen] = useState(false);
  const [statusUnderstood, setStatusUnderstood] = useState(false);
  const [addFileOpen, setAddFileOpen] = useState(false);
  const [messageHeight, setMessageHeight] = useState(50);
  const [templateSelect, setTemplateSelect] = useState();
  const [selectedTemplate, setSelectedTemplate] = useState();
  const statuses = [];
  const [showSegmentModal, setShowSegmentModal] = useState(false);
  const [confirmBroadcast, setConfirmBroadcast] = useState(false);
  const [understoodConfirm, setUnderstoodConfirm] = useState(false);
  const [hideFutureConfirmModal, setHideFutureConfirmModal] = useState(false);
  const [timezoneDropdownActive, setTimezoneDropdownActive] = useState(false);
  const [timezoneSelected, setTimezoneSelected] = useState(props?.broadcast?.schedule_timezone || null);
  const listRef = useRef(null);
  const [filteredTimezones, setFilteredTimezones] = useState([{
    value: 'America/New_York',
    label: 'Eastern Standard Time (EST)'
  }, {
    value: 'America/Chicago',
    label: 'Central Standard Time (CST)'
  }, {
    value: 'America/Denver',
    label: 'Mountain Standard Time (MST)'
  }, {
    value: 'America/Los_Angeles',
    label: 'Pacific Standard Time (PST)'
  }, {
    value: 'America/Anchorage',
    label: 'Alaska Standard Time (AKST)'
  }, {
    value: 'Pacific/Honolulu',
    label: 'Hawaii Standard Time (HST)'
  }, {
    value: 'Etc/GMT+4',
    label: 'Atlantic Standard Time (AST)'
  }, {
    value: 'Etc/GMT+7',
    label: 'Arizona'
  }]);
  if (broadcast.opt_statuses && broadcast.opt_statuses.indexOf('opt_in') > -1) {
    statuses.push('Opt In');
  }
  if (broadcast.opt_statuses && broadcast.opt_statuses.indexOf('pending') > -1) {
    statuses.push('Pending');
  }
  if (broadcast.opt_statuses && broadcast.opt_statuses.indexOf('suppressed') > -1) {
    statuses.push('Suppressed');
  }
  if (broadcast.opt_statuses && broadcast.opt_statuses.indexOf('unknown') > -1) {
    statuses.push('Unknown');
  }
  useEffect(() => {
    loadData();
  }, []);
  const loadData = () => {
    setLoading(true);
    getPhoneNumbers({
      company: asCompany.id,
      limit: 1000
    }).then(numbers => {
      const numbersWithFormat = numbers.data.results.map(number => {
        let numberFormat = {
          ...number
        };
        numberFormat.phone = utils.formatPhoneNumber(numberFormat.phone);
        return numberFormat;
      });
      setNumbers([{
        id: '',
        phone: 'Automatic'
      }, {
        id: 'location',
        phone: 'Last Location Phone Number'
      }].concat(numbersWithFormat));
      setLoading(false);
    });
  };
  useEffect(() => {
    if (!broadcast.drip || !targetDate || !estimatedSends) {
      setEstimatedError(false);
      return;
    }

    // Recalculate the estimated finish time.
    let startTime = getStartTime(sendWhen, broadcast, selectedDate);
    let duration = 0;
    let targetDateTz = moment(targetDate).tz(broadcast.drip_timezone);
    if (!broadcast.send_window) {
      duration = targetDateTz - startTime;
    } else {
      // calculate duration here
      const [startHour, startMinute, startSecond] = broadcast.window_start.split(':');
      const [endHour, endMinute, endSecond] = broadcast.window_end.split(':');
      do {
        const WINDOW_START = moment(startTime).tz(broadcast.window_timezone).set({
          hour: startHour,
          minute: startMinute,
          second: startSecond,
          millisecond: 0
        });
        const WINDOW_END = moment(startTime).tz(broadcast.window_timezone).set({
          hour: endHour,
          minute: endMinute,
          second: endSecond,
          millisecond: 0
        });
        duration += Math.min(targetDateTz, WINDOW_END) - Math.max(startTime, WINDOW_START);
        startTime = startTime.add(1, 'day').set({
          hour: startHour,
          minute: startMinute,
          second: startSecond,
          millisecond: 0
        });
      } while (startTime < targetDateTz);
    }
    const numBatches = Math.ceil(estimatedSends / 100) - 1;
    setEstimatedError(duration / 1000 / numBatches < 1);
  }, [estimatedSends, sendWhen, broadcast.schedule_timezone, selectedDate, broadcast.drip, targetDate, selectedDate, broadcast.drip_timezone, broadcast.send_window, broadcast.window_start, broadcast.window_end, broadcast.window_days, broadcast.window_timezone]);
  useEffect(() => {
    // Get the broadcast send count
    if (broadcast.segment) {
      setEstimatedSends(null);
      const promise = getSegmentCount(broadcast.segment, {
        distinct: !!broadcast.distinct_messages
      });
      promise.then(({
        data
      }) => {
        setEstimatedSends(data.count);
        if (data.count >= 10000) {
          setBroadcast(broadcast => ({
            ...broadcast,
            drip: true
          }));
        }
      }).catch();
      return () => {
        promise.controller.abort();
      };
    }
  }, [broadcast.segment, broadcast.distinct_messages]);
  useEffect(() => {
    timezoneDropdownHandler();
  }, [broadcast.send_window, broadcast.drip, sendWhen]);
  const updateTime = (updatedTime, setFunc) => {
    setFunc(selectedDate => {
      const [hour, minute, second] = updatedTime.split(':');
      const newDate = moment(selectedDate) || moment();
      newDate.set({
        hour,
        minute,
        second
      });
      return newDate;
    });
  };
  const saveBroadcast = () => {
    setLoading(true);
    const timeToUse = getStartTime(sendWhen, broadcast, selectedDate);
    if (!broadcast.send_window) {
      broadcast.window_start = null;
      broadcast.window_end = null;
      broadcast.window_days = null;
      broadcast.window_timezone = null;
    }
    if (!broadcast.drip) {
      broadcast.drip_end = null;
      broadcast.drip_timezone = null;
    } else {
      broadcast.drip_end = moment.tz(targetDate.format('YYYY-MM-DD HH:mm:ss'), broadcast.drip_timezone);
    }
    delete broadcast.send_window;
    delete broadcast.drip;
    let promise;
    const newBroadcast = {
      ...broadcast,
      company: asCompany.id,
      schedule_when: timeToUse.toDate()
    };
    if (newBroadcast.id) {
      promise = updateSmsBroadcast(newBroadcast.id, newBroadcast);
    } else {
      promise = createSmsBroadcast(newBroadcast);
    }
    promise.then(() => {
      props.onSave();
    }).catch(() => setLoading(false));
  };
  const canSave = () => {
    const sendNow = sendWhen === 'now' || !!selectedDate && !!broadcast.schedule_timezone;
    const dripEmails = !broadcast.drip || !!targetDate && !!broadcast.drip_timezone;
    const sendWindow = !broadcast.send_window || !!broadcast.window_start && !!broadcast.window_end && !!broadcast.window_days && broadcast.window_days.length > 0 && broadcast.window_start < broadcast.window_end;
    return !loading && !!sendNow && !!broadcast.message && !!broadcast.segment && dripEmails && sendWindow && !!broadcast.opt_statuses && broadcast.opt_statuses?.length > 0 && (timezoneSelected !== null || sendWhen !== 'later') && (sendWhen === 'now' ? !broadcast.drip && !broadcast.send_window || timezoneSelected !== null : true);
  };
  const toggleDripDay = day => {
    const window_days = broadcast.window_days || [];
    const index = window_days.indexOf(day);
    if (index === -1) {
      window_days.push(day);
    } else {
      window_days.splice(index, 1);
    }
    setBroadcast(broadcast => ({
      ...broadcast,
      window_days: [...window_days]
    }));
  };
  const toggleStatus = status => {
    const optStat = broadcast.opt_statuses ? [...broadcast.opt_statuses] : [];
    const index = optStat.indexOf(status);
    if (index === -1) {
      optStat.push(status);
    } else {
      optStat.splice(index, 1);
    }

    // If any status other than opt_in is there we need to get approval.
    if (!statusUnderstood && (optStat.indexOf('not_opt_in') > -1 || optStat.indexOf('pending') > -1 || optStat.indexOf('unknown') > -1 || optStat.indexOf('suppressed') > -1)) {
      setOptAgreeOpen(true);
    }
    setBroadcast(broadcast => ({
      ...broadcast,
      opt_statuses: optStat ? optStat : null
    }));
  };
  const addFile = file => {
    if (broadcast.media.find(m => m.Key === file.Key)) {
      // File already added...
      return;
    }
    setBroadcast(broadcast => ({
      ...broadcast,
      media: [...broadcast.media, file]
    }));
  };
  const insertEmoji = e => {
    setBroadcast(broadcast => ({
      ...broadcast,
      message: broadcast.message ? broadcast.message + e : e
    }));
  };
  const onMessageChange = e => {
    setMessageHeight(e.target.scrollHeight);
    setBroadcast(broadcast => ({
      ...broadcast,
      message: e.target.value
    }));
  };
  const insertData = e => {
    setBroadcast(broadcast => ({
      ...broadcast,
      message: `${broadcast.message || ''}{{{${e.target.value}}}}`
    }));
  };
  const getId = (groupId, fieldId) => {
    return `${groupId}.${fieldId.replace(/__/g, '.')}`;
  };
  const deleteBroadcast = broadcast => {
    deleteSmsBroadcast(broadcast.id).then(() => {
      props.onDelete();
    });
  };
  const getMessageCount = message => {
    const fullMessage = message + '\nReply STOP to optout';
    const segmentedMessage = new SegmentedMessage(fullMessage, SPLITTER);
    const charactersValue = `${SPLITTER.countGraphemes(fullMessage)}* characters = `;
    let segmentsValues = `${segmentedMessage.segments.length}`;
    if (message.indexOf('{') > -1) {
      segmentsValues += '**';
    }
    segmentsValues += ' segment';
    if (segmentedMessage.segments.length != 1) {
      segmentsValues += 's';
    }
    return <>
        {charactersValue}
        <span style={segmentedMessage.segments.length > 1 ? {
        color: '#f28837'
      } : {
        color: '#404040'
      }}>{segmentsValues}</span>
      </>;
  };
  const handleTemplateChoice = (template, revision) => {
    setBroadcast(broadcast => ({
      ...broadcast,
      message: broadcast.message ? broadcast.message + revision.message : revision.message,
      media: [...broadcast.media, ...revision.media]
    }));
  };
  const broadcastTypeTimezoneHandler = (zone, label) => {
    if (sendWhen === 'now') {
      setBroadcast(broadcast => ({
        ...broadcast,
        schedule_timezone: zone
      }));
    }
    if (sendWhen === 'later') {
      setBroadcast(broadcast => ({
        ...broadcast,
        schedule_timezone: zone
      }));
    }
    if (broadcast.drip) {
      setBroadcast(broadcast => ({
        ...broadcast,
        drip_timezone: zone
      }));
    }
    if (broadcast.send_window) {
      setBroadcast(broadcast => ({
        ...broadcast,
        window_timezone: zone
      }));
    }
    setTimezoneSelected(label);
  };
  const timezoneDropdownHandler = () => {
    if (broadcast.send_window && broadcast.drip || sendWhen === 'later') {
      setTimezoneDropdownActive(true);
    } else if (!broadcast.send_window && !broadcast.drip && sendWhen === 'now') {
      setTimezoneDropdownActive(false);
    } else {
      setTimezoneDropdownActive(true);
    }
  };
  return <div>
      <Modal size="lg" open={!!broadcast} onClose={props.onClose} type="scroll" title={broadcast.id ? props.disabled ? 'View SMS Broadcast' : 'Edit SMS Broadcast' : 'Create SMS Broadcast'}>
        <div className="modal__body">
          <div className="form-group">
            <label>
              Segment <span className="form-error">*</span>
            </label>
            <DynamicTypeAhead disabled={props.disabled} getItems={getSegments} getItemsFilters={{
            company: asCompany.id
          }} placeholder={broadcast.segment_name ? broadcast.segment_name : 'Choose Customer Segment'} displayProperty="name" keyProperty="id" value={broadcast.segment ? broadcast.segment : null} onChange={val => {
            setBroadcast(broadcast => ({
              ...broadcast,
              segment: val ? val.id : null,
              segment_name: val ? val.name : null
            }));
          }} />
          </div>
          <div className="form-group">
            <label>From Phone Number</label>
            <StaticTypeAhead disabled={props.disabled} items={numbers} placeholder={selectedFrom ? selectedFrom.phone : 'Automatic'} displayProperty="phone" keyProperty="id" value={selectedFrom ? selectedFrom.id : ''} onChange={from => {
            setBroadcast(broadcast => ({
              ...broadcast,
              from_phone: from ? from.id : null
            }));
          }} />
          </div>
          <div className="form-group">
            <label>To Opt-In Status of</label>
            <Dropdown disabled={props.disabled} size="block" contentHeight={250} trigger={() => <Button dropdown block disabled={props.disabled}>
                  {statuses.length ? statuses.join(', ') : 'Choose Statuses'}
                </Button>} content={() => <ul className="select-list">
                  <li>
                    <a onClick={() => toggleStatus('opt_in')}>
                      <div className="select-list__content">Opt In</div>
                      {broadcast.opt_statuses && broadcast.opt_statuses.indexOf('opt_in') > -1 && <Icon className="select-list__selected" size={14} name="fa-regular-check" />}
                    </a>
                  </li>
                  <li>
                    <a onClick={() => toggleStatus('pending')}>
                      <div className="select-list__content">Pending</div>
                      {broadcast.opt_statuses && broadcast.opt_statuses.indexOf('pending') > -1 && <Icon className="select-list__selected" size={14} name="fa-regular-check" />}
                    </a>
                  </li>
                  <li>
                    <a onClick={() => toggleStatus('suppressed')}>
                      <div className="select-list__content">Suppressed</div>
                      {broadcast.opt_statuses && broadcast.opt_statuses.indexOf('suppressed') > -1 && <Icon className="select-list__selected" size={14} name="fa-regular-check" />}
                    </a>
                  </li>
                  <li>
                    <a onClick={() => toggleStatus('unknown')}>
                      <div className="select-list__content">Unknown</div>
                      {broadcast.opt_statuses && broadcast.opt_statuses.indexOf('unknown') > -1 && <Icon className="select-list__selected" size={14} name="fa-regular-check" />}
                    </a>
                  </li>
                </ul>} />
          </div>
          <div className="form-group">
            <div className="d-flex justify-content-between">
              <label>Media</label>
              {broadcast.media.length < 10 && !props.disabled && <Button icon="fa-regular-plus" actionType="flat" small onClick={() => setAddFileOpen(true)} />}
            </div>
            {broadcast.media.map(file => <div className="d-flex" key={file.Key}>
                <div className="flex-grow truncate">{file.name}</div>
                <div className="ml-2">{fileSize(file.Size)}</div>
                <div className="ml-2">
                  <Button icon="fa-regular-times" actionType="flat" small onClick={() => setBroadcast(broadcast => ({
                ...broadcast,
                media: broadcast.media.filter(m => m.Key !== file.Key)
              }))} />
                </div>
              </div>)}
            <div className="d-flex justify-content-between mt-2">
              <p className={classnames('text-sm', {
              'text-danger': broadcast.media.length > 10
            })}>
                {broadcast.media.length} of 10 files
              </p>
              <p className={classnames('text-sm', {
              'text-danger': broadcast.media.reduce((x, y) => x + y.Size, 0) > 5200880
            })}>
                {fileSize(broadcast.media.reduce((x, y) => x + y.Size, 0))} of 5 MB
              </p>
            </div>
          </div>
          <div className="form-group">
            <div className="d-flex justify-content-between align-items-center">
              <label className="my-0">Message</label>
              {!props.disabled && <Stack direction="row">
                  {false && <Button icon="fa-solid-mail-bulk" actionType="flat" onClick={() => {
                setTemplateSelect(true);
              }} />}

                  <SmsTemplatesMenu handleTemplateChoice={handleTemplateChoice} offset={{
                x: 60,
                y: 30
              }} />

                  <Emoji openDirection="farRight" onEmojiClick={e => insertEmoji(e)} />
                </Stack>}
            </div>
            <div className="form-control" style={{
            height: 'auto',
            padding: '0px'
          }}>
              <textarea disabled={props.disabled} cols="3" className="form-control form-message" style={{
              border: 'none',
              boxShadow: 'none',
              overflowY: 'scroll',
              resize: 'none',
              height: `${messageHeight}px`,
              maxHeight: '80px',
              minHeight: '24px',
              ':focus': {
                outline: 'none'
              }
            }} onChange={onMessageChange} value={broadcast.message || ''} maxLength={broadcast.media?.length == 0 ? null : 1579} />
              <span style={style.textareaFooter}>Reply STOP to optout</span>
            </div>
          </div>
          <div className="d-flex mb-3 justify-content-between">
            {broadcast.media?.length == 0 && <span className="text-sm">
                {getMessageCount(broadcast.message || '')}
                <span onClick={() => setShowSegmentModal(true)}>
                  <Icon className="select-list__selected ml-2" size={14} name="fa-solid-question-circle" fill={'#f28837'} />
                </span>
              </span> || <span className="text-sm">{(broadcast.message || '').length + 21}* out of 1,600 characters</span>}
            <select className="form-control" disabled={props.disabled} style={{
            height: '2rem',
            width: '166px'
          }} onChange={insertData} value={''}>
              <option>Insert Dynamic Data</option>
              {companyFields.map(field => <optgroup key={field.id} label={field.name}>
                  {field.fields.map(subfield => <option key={subfield.id} value={getId(field.id, subfield.id)}>
                      {subfield.name}
                    </option>)}
                </optgroup>)}
            </select>
          </div>
          {asCompany.sms_hide_optout_message && <div className="pt-2 mb-2 form-group">
              <Toggle disabled={props.disabled} checked={!!broadcast.sms_hide_optout_message} onToggle={() => {
            setBroadcast(broadcast => ({
              ...broadcast,
              sms_hide_optout_message: !broadcast.sms_hide_optout_message
            }));
          }} />
              <label className="pl-2 text-centered">Hide opt out message</label>
              <div className="text-sm text-muted">If checked, the message "Reply STOP to optout" will be removed from any customer fully opted in.</div>
            </div>}
          <div className="pt-2 mb-2 form-group">
            <Toggle disabled={props.disabled} checked={!!broadcast.create_unique_urls} onToggle={() => {
            setBroadcast(broadcast => ({
              ...broadcast,
              create_unique_urls: !broadcast.create_unique_urls
            }));
          }} />
            <label className="pl-2 text-centered">Create Unique URLs</label>
            {broadcast.create_unique_urls && <i className="pl-3 text-sm form-error ">Follow the format: https://www.yoursite.com</i>}
          </div>
          {!props.disabled && <div className="form-group space-between">
              <label>When to send broadcast?</label>
              <div className="toggler">
                <Button onClick={() => {
              setSendWhen('now');
              if (!broadcast.drip && !broadcast.send_window) {
                setTimezoneSelected(null);
              }
            }} className={m('active', sendWhen === 'now')}>
                  Now
                </Button>
                <Button onClick={() => setSendWhen('later')} className={m('active', sendWhen === 'later')}>
                  Later
                </Button>
              </div>
            </div>}
          {sendWhen === 'now' && broadcast.drip || sendWhen === 'now' && broadcast.send_window || sendWhen === 'later' ? <div className="row">
              <div className="col-timezoneDropdown">
                <div className="form-group">
                  <label>
                    Select Timezone{' '}
                    <span className="form-error">{sendWhen === 'later' || broadcast?.drip || broadcast?.send_window ? '*' : ''}</span>
                  </label>
                  <Dropdown ref={listRef} disabled={sendWhen === 'now' && !timezoneDropdownActive} size="md" contentHeight={280} trigger={() => <Button dropdown block disabled={props.disabled}>
                        {timezoneSelected || 'Select Timezone'}
                      </Button>} content={() => <List>
                        {filteredTimezones.map(zone => <ListItemButton sx={{
                  height: '100%',
                  width: '100%'
                }} onClick={() => {
                  broadcastTypeTimezoneHandler(zone.value, zone.label);
                  listRef.current.close();
                }} key={zone.value}>
                            <ListItemText primary={zone.label} />
                          </ListItemButton>)}
                      </List>} />
                </div>
              </div>
            </div> : null}

          {sendWhen === 'later' && <div className="row">
              <div className="col-4">
                <div className="form-group">
                  <label>Select Date</label>
                  <Dropdown disabled={props.disabled} size="md" allowContentClicks trigger={() => <Button dropdown block disabled={props.disabled}>
                        {selectedDate ? selectedDate.format('LL') : 'Select Date'}
                      </Button>} content={({
                close
              }) => <div className="p-sm">
                        <Calendar value={selectedDate ? selectedDate.toDate() : null} onChange={date => {
                  setSelectedDate(moment(date));
                  close();
                }} />
                      </div>} />
                </div>
              </div>
              <div className="col-5">
                <div className="form-group">
                  <label>Select Time</label>

                  <TimeSelector disabled={props.disabled} value={selectedDate} onChange={updatedTime => {
                if (!selectedDate) setSelectedDate(moment(new Date()));
                updateTime(updatedTime, setSelectedDate);
              }} />
                </div>
              </div>
            </div>}
          <div className="form-group col-broadcastDateToSend">
            <Stack direction="row" spacing={2}>
              <Box>
                <Checkbox sx={{
                p: 0,
                margin: 0,
                color: '#1D252D',
                '&.Mui-checked': {
                  color: '#53A6D6'
                },
                '& .MuiSvgIcon-root': {
                  fontSize: 22
                }
              }} disabled={props.disabled} checked={!!broadcast.drip} onChange={event => {
                setBroadcast(broadcast => ({
                  ...broadcast,
                  drip: event.target.checked
                }));
                if (!event.target.checked && sendWhen === 'now') {
                  setTimezoneSelected(null);
                }
              }} />
              </Box>
              <label paddingLeft="10px" style={{
              display: 'flex',
              alignItems: 'center'
            }}>
                Drip SMS over time
              </label>
            </Stack>
          </div>

          {!!broadcast.drip && <div className="row">
              <div className="col-4">
                <div className="form-group">
                  <label>
                    Target End Date <span className="form-error">*</span>
                  </label>
                  <Dropdown disabled={props.disabled} size="md" allowContentClicks trigger={() => <Button dropdown block disabled={props.disabled}>
                        {targetDate ? targetDate.format('LL') : 'Select Date'}
                      </Button>} content={({
                close
              }) => <div className="p-sm">
                        <Calendar disabled={props.disabled} value={targetDate ? targetDate.toDate() : null} onChange={date => {
                  setTargetDate(moment(date));
                  close();
                }} />
                      </div>} />
                </div>
              </div>
              <div className="col-5">
                <div className="form-group">
                  <label>
                    Select Time <span className="form-error">*</span>
                  </label>

                  <TimeSelector disabled={!targetDate || props.disabled} value={targetDate} onChange={updatedTime => updateTime(updatedTime, setTargetDate)} />
                </div>
              </div>
            </div>}
          <div className="form-group">
            <Stack direction="row" spacing={2}>
              <Box>
                <Checkbox sx={{
                p: 0,
                margin: 0,
                color: '#1D252D',
                '&.Mui-checked': {
                  color: '#53A6D6'
                },
                '& .MuiSvgIcon-root': {
                  fontSize: 22
                }
              }} disabled={props.disabled} checked={!!broadcast.send_window} onChange={event => {
                setBroadcast(broadcast => ({
                  ...broadcast,
                  send_window: event.target.checked
                }));
                if (!event.target.checked && sendWhen === 'now') {
                  setTimezoneSelected(null);
                }
              }} />
              </Box>
              <label paddingLeft="10px" style={{
              display: 'flex',
              alignItems: 'center'
            }}>
                Use send window
              </label>
            </Stack>
          </div>
          {broadcast.send_window && <>
              <div className="row">
                <div className="form-group col-6">
                  <label>
                    Start Time <span className="form-error">*</span>
                  </label>

                  <TimeSelector disabled={props.disabled} value={broadcast.window_start} onChange={window_start => setBroadcast(broadcast => ({
                ...broadcast,
                window_start
              }))} />
                </div>
                <div className="form-group col-6">
                  <label>
                    End Time <span className="form-error">*</span>
                  </label>

                  <TimeSelector disabled={props.disabled} value={broadcast.window_end} onChange={window_end => setBroadcast(broadcast => ({
                ...broadcast,
                window_end
              }))} />
                </div>
              </div>
              <div className="form-group col-broadcastDateToSend">
                <div>
                  <label>
                    Dates to Send <span className="form-error">*</span>
                  </label>
                </div>
                <div className=" d-flex space-between" style={{
              justifyContent: 'space-between'
            }}>
                  <div>
                    <label alignContent="center">Sun</label>
                    <br />
                    <Checkbox disabled={props.disabled} type="checkbox" value={!!broadcast.window_days && broadcast.window_days.indexOf(0) > -1} checked={!!broadcast.window_days && broadcast.window_days.indexOf(0) > -1} onChange={() => toggleDripDay(0)} sx={{
                  p: 0,
                  margin: 0,
                  color: '#1D252D',
                  '&.Mui-checked': {
                    color: '#53A6D6'
                  },
                  '& .MuiSvgIcon-root': {
                    fontSize: 22
                  }
                }}></Checkbox>
                  </div>
                  <div>
                    <label>Mon</label>
                    <br />
                    <Checkbox
                // default checked
                disabled={props.disabled} type="checkbox" value={!!broadcast.window_days && broadcast.window_days.indexOf(1) > -1} checked={!!broadcast.window_days && broadcast.window_days.indexOf(1) > -1} onChange={() => toggleDripDay(1)} sx={{
                  p: 0,
                  margin: 0,
                  color: '#1D252D',
                  '&.Mui-checked': {
                    color: '#53A6D6'
                  },
                  '& .MuiSvgIcon-root': {
                    fontSize: 22
                  }
                }}>
                      {/*
                       <input
                        disabled={props.disabled}
                        type="checkbox"
                        value={!!broadcast.window_days && broadcast.window_days.indexOf(1) > -1}
                        checked={!!broadcast.window_days && broadcast.window_days.indexOf(1) > -1}
                        onChange={() => toggleDripDay(1)}
                       />
                       */}
                    </Checkbox>
                  </div>
                  <div>
                    <label>Tue</label>
                    <br />
                    <Checkbox disabled={props.disabled} type="checkbox" value={!!broadcast.window_days && broadcast.window_days.indexOf(2) > -1} checked={!!broadcast.window_days && broadcast.window_days.indexOf(2) > -1} onChange={() => toggleDripDay(2)} sx={{
                  p: 0,
                  margin: 0,
                  color: '#1D252D',
                  '&.Mui-checked': {
                    color: '#53A6D6'
                  },
                  '& .MuiSvgIcon-root': {
                    fontSize: 22
                  }
                }}></Checkbox>
                  </div>
                  <div>
                    <label>Wed</label>
                    <br />
                    <Checkbox disabled={props.disabled} type="checkbox" value={!!broadcast.window_days && broadcast.window_days.indexOf(3) > -1} checked={!!broadcast.window_days && broadcast.window_days.indexOf(3) > -1} onChange={() => toggleDripDay(3)} sx={{
                  p: 0,
                  margin: 0,
                  color: '#1D252D',
                  '&.Mui-checked': {
                    color: '#53A6D6'
                  },
                  '& .MuiSvgIcon-root': {
                    fontSize: 22
                  }
                }}></Checkbox>
                  </div>
                  <div>
                    <label>Thu</label>
                    <br />
                    <Checkbox disabled={props.disabled} type="checkbox" value={!!broadcast.window_days && broadcast.window_days.indexOf(4) > -1} checked={!!broadcast.window_days && broadcast.window_days.indexOf(4) > -1} onChange={() => toggleDripDay(4)} sx={{
                  p: 0,
                  margin: 0,
                  color: '#1D252D',
                  '&.Mui-checked': {
                    color: '#53A6D6'
                  },
                  '& .MuiSvgIcon-root': {
                    fontSize: 22
                  }
                }}></Checkbox>
                  </div>
                  <div>
                    <label>Fri</label>
                    <br />
                    <Checkbox disabled={props.disabled} type="checkbox" value={!!broadcast.window_days && broadcast.window_days.indexOf(5) > -1} checked={!!broadcast.window_days && broadcast.window_days.indexOf(5) > -1} onChange={() => toggleDripDay(5)} sx={{
                  p: 0,
                  margin: 0,
                  color: '#1D252D',
                  '&.Mui-checked': {
                    color: '#53A6D6'
                  },
                  '& .MuiSvgIcon-root': {
                    fontSize: 22
                  }
                }}></Checkbox>
                  </div>
                  <div>
                    <label>Sat</label>
                    <br />
                    <Checkbox disabled={props.disabled} type="checkbox" value={!!broadcast.window_days && broadcast.window_days.indexOf(6) > -1} checked={!!broadcast.window_days && broadcast.window_days.indexOf(6) > -1} onChange={() => toggleDripDay(6)} sx={{
                  p: 0,
                  margin: 0,
                  color: '#1D252D',
                  '&.Mui-checked': {
                    color: '#53A6D6'
                  },
                  '& .MuiSvgIcon-root': {
                    fontSize: 22
                  }
                }}></Checkbox>
                  </div>
                </div>
              </div>
            </>}
          <div className="form-group">
            <Stack direction={'row'} spacing={2}>
              <Box>
                <Checkbox sx={{
                p: 0,
                margin: 0,
                color: '#1D252D',
                '&.Mui-checked': {
                  color: '#53A6D6'
                },
                '& .MuiSvgIcon-root': {
                  fontSize: 22
                }
              }} disabled={props.disabled} checked={!!broadcast.distinct_messages} onChange={event => {
                setBroadcast(broadcast => ({
                  ...broadcast,
                  distinct_messages: event.target.checked
                }));
              }} />
              </Box>
              <label paddingLeft="10px" style={{
              display: 'flex',
              alignItems: 'center'
            }}>
                Send only one SMS message per phone number
              </label>
            </Stack>
          </div>

          {broadcast.drip && broadcast.segment && estimatedSends === null && <p>
              <em>Loading estimates...</em>
            </p>}
          {!!estimatedError && <p className="text-danger">
              Warning. Due to current settings the broadcast won't finish before the target end date and will continue to send afterward.
            </p>}
          <div className="form-error text-sm">* Required</div>
        </div>
        <div className="modal__actions">
          <div className="d-flex justify-content-between">
            {hasPermission('communication.delete_smsbroadcast') && broadcast.id && !props.disabled && <Button actionType="danger" onClick={() => {
            deleteBroadcast(broadcast);
          }}>
                Delete
              </Button> || <div></div>}
            <div>
              {!props.disabled && <Button onClick={() => props.onClose()}>Cancel</Button>}
              <Button actionType="primary" onClick={() => broadcast.id ? props.disabled ? props.onClose() : saveBroadcast() : localStorage.getItem('confirmSmsBroadcast') != 'true' ? setConfirmBroadcast(true) : saveBroadcast()} className="float-right" disabled={!canSave()}>
                {loading ? <Loader size="sm" /> : broadcast.id ? props.disabled ? 'Close' : 'Edit' : 'Create'}
              </Button>
            </div>{' '}
          </div>
        </div>
      </Modal>
      <ModalDialog open={optAgreeOpen} title="SMS Liability Warning" cancelText="Nevermind" submitText="I Agree" onSubmit={() => setStatusUnderstood(true)} onClose={() => setOptAgreeOpen(false)} onCancel={() => setBroadcast(broadcast => ({
      ...broadcast,
      opt_statuses: ['opt_in']
    }))}>
        <p>
          Sending SMS messages to numbers that have not expressly opted to receive such messages may expose you to civil liability under
          applicable law.
        </p>
        <p>
          By using this system to send SMS messages, you affirm that you have received express permission from the recipient to send the
          messages or that such messages are otherwise permitted under applicable law. Further, you agree to assume all such liability and
          to defend, indemnify, and hold harmless Cinch against all claims and damages whatsoever, including cost of defense, caused by your
          use of this system.
        </p>
      </ModalDialog>
      <ModalFileViewer2 open={addFileOpen} onSubmit={addFile} onClose={() => setAddFileOpen(false)} />
      <Modal open={!!templateSelect} onClose={() => setTemplateSelect(false)} allowBackdropClick title="SMS Templates">
        <div className="modal__body">
          <p>Select a template to insert</p>
          <div className="form-group">
            <DynamicTypeAhead disabled={props.disabled} getItems={getEmailTemplates} getItemsFilters={{
            company: asCompany.id,
            archived: false,
            published: true
          }} placeholder="Choose a Published SMS Template" displayProperty="name" keyProperty="id" value={broadcast.template} onChange={val => {
            setSelectedTemplate(val ? val : null);
          }} />
          </div>
        </div>
        <div className="modal__actions">
          <Button actionType="flat" onClick={() => setTemplateSelect(false)}>
            Cancel
          </Button>
          <Button actionType="primary" onClick={() => {
          setBroadcast(broadcast => ({
            ...broadcast,
            message: selectedTemplate ? broadcast.message ? broadcast.message + ' ' + selectedTemplate.name : selectedTemplate.name : null
          }));
          setTemplateSelect(false);
        }} disabled={!selectedTemplate}>
            Add
          </Button>
        </div>
      </Modal>
      <ModalDialog open={showSegmentModal} title="SMS Segments" submitText="Okay" allowBackdropClick={true} onClose={() => setShowSegmentModal(false)} cancelBtn={false} onSubmit={() => setShowSegmentModal(false)}>
        <p>
          SMS (aka text) messages are billed per segment. A single segment is typically 160 characters, but if you're using special
          characters, like emoji it may be less. You can send multiple segments (really as many as you like) but for billing purposes, each
          segment counts as a message sent.
        </p>
      </ModalDialog>

      <Dialog sx={{
      fontFamily: 'Nexa',
      color: '#1D252D'
    }} PaperProps={{
      style: {
        borderRadius: '28px',
        minWidth: '30% '
      }
    }} open={!!confirmBroadcast} onClose={(event, reason) => {
      setConfirmBroadcast(false);
    }}>
        <DialogTitle className="d-flex w-100 justify-content-between" sx={{
        m: 0,
        px: 0,
        py: 1
      }}>
          <Box sx={{
          pl: '24px',
          display: 'flex',
          alignContent: 'center',
          alignItems: 'center'
        }}>
            <Typography sx={{
            fontSize: 24
          }}>Confirm Broadcast</Typography>
          </Box>

          <Box sx={{
          pr: '24px',
          display: 'flex',
          alignContent: 'center',
          alignItems: 'center'
        }}>
            <IconButton aria-label="close" onClick={e => {
            setConfirmBroadcast(false);
          }} sx={{
            color: theme => theme.palette.grey[500]
          }}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>

        <DialogContent dividers>
          <DialogContentText>
            <Stack component="span"></Stack>
          </DialogContentText>
          <Stack spacing="10px">
            <Typography>
              A text message broadcast <strong>can create additional costs</strong> to your monthly bill. If you do not understand your text
              message costs or have any questions, please contact <a href="mailto: support@cinch.io">support@cinch.io</a>
            </Typography>

            <FormGroup>
              <FormControlLabel control={<Checkbox checked={understoodConfirm} onClick={e => {
              setUnderstoodConfirm(!understoodConfirm);
            }} sx={{
              '& .MuiSvgIcon-root': {
                fontSize: 24
              }
            }} />} label=" I understand that sending this text broadcast may create additional charges on this month’s Cinch bill" />
            </FormGroup>
          </Stack>
        </DialogContent>

        <DialogActions sx={{
        py: 3
      }}>
          <Box sx={{
          px: 2
        }} className="d-flex w-100 justify-content-between">
            <FormGroup>
              <FormControlLabel control={<Checkbox checked={hideFutureConfirmModal} onClick={e => {
              setHideFutureConfirmModal(!hideFutureConfirmModal);
            }} sx={{
              '& .MuiSvgIcon-root': {
                fontSize: 24
              }
            }} />} label="Don't show me this message again" />
            </FormGroup>
            <Stack direction="row" spacing="10px">
              <MuiButton onClick={e => {
              setConfirmBroadcast(false);
            }} sx={{
              borderRadius: '20px',
              color: '#53A6D6',
              width: '125px',
              borderStyle: 'solid',
              borderColor: '#53A6D6',
              borderWidth: '1px'
            }}>
                Cancel
              </MuiButton>
              <MuiButton disabled={!understoodConfirm} onClick={e => {
              setConfirmBroadcast(false);
              if (hideFutureConfirmModal) {
                localStorage.setItem('confirmSmsBroadcast', true);
              }
              saveBroadcast();
            }} variant="contained" sx={{
              borderRadius: '20px',
              mr: 2,
              width: '125px'
            }}>
                Confirm
              </MuiButton>
            </Stack>
          </Box>
        </DialogActions>
      </Dialog>
    </div>;
};